.roles {
	padding-top: $padding-default * 2;
	margin-bottom: $margin-default * 2;

	&-head {
		position: relative;
		padding: $padding-default * 1.5 0;
		border-top: 1px solid $color04;
		border-bottom: 1px solid $color04;
		cursor: pointer;

		.head {
			line-height: 0.9;
		}

		&:after {
			@include kruitbosch-font();
			content: '\e801';
			position: absolute;
			right: 0;
			top: 50%;
			margin: 0 0 0 $margin-default * 2;
			width: 2.5rem;
			height: 2.5rem;
			font-size: 2rem;
			line-height: 2.5rem;
			color: $color03;
			transform: translateY(-50%);
		}

		&.open {
			border-bottom: none;

			&:after {
				content: '\e803';
			}

			+ .roles-wrap {
				display: block;

				> .roles-item {
					&:last-child {
						border-bottom: 1px solid $color04;
					}
				}
			}
		}
	}
	&-wrap {
		overflow: hidden;
		display: none;
		// transform: translateY(-100%);
	}

	&-item {
		padding: $padding-default;

		&:nth-of-type(odd) {
			background: $color05;
		}
	}

	&-descr {
		display: inline-block;
		padding-top: $padding-default;
		font-style: italic;
		line-height: 1;
	}
}
