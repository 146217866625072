.widget {
	&-contact {
		&-top {
			padding: $padding-default * 2 $padding-default;
			border-bottom: 1px solid $color04;

			.social ul {
				justify-content: space-around;

				> * {
					flex: 0 1 auto;
				}
			}
		}

		&-bottom {
			padding: $padding-default;

			[class^='icon-'] {
				color: $color04;
				font-size: 7.2rem;
				line-height: 0.9;

				&:before {
					margin: 0;
				}
			}
		}
	}
}

@media only screen and (min-width: $b6) {
	.widget {
		&-contact {
			&-bottom {
				padding-top: $padding-default * 2;

				[class^='icon-'] {
					font-size: 8.5rem;
				}
			}
		}
	}
}
