.side {
	.sidebar {
		width: 100%;
	}
}

@media only screen and (min-width: $b5) {
	.side {
		.sidebar {
			height: 100%;
		}
	}
}
