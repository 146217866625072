.widget {
	&-half-half {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border: 0;
		border-radius: 0;


		&-item {
			position: relative;
			overflow: hidden;
			border: 1px solid $color04;
			border-radius: $radius;

			&:first-child {
				margin-bottom: 2.5rem;
			}

			img {
				display: block;
				width: 100%;
			}
		}
	}
}
