%scrollhide {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 25px;
	z-index: 99;
}

.detail-bundles {
	padding: 1.5rem;
    margin-bottom: $margin-default*2;
	background: $warning;

	&-popup {
		img {
			max-width: 7.9rem;
		}

		.form-input {
			flex: 1 0 auto;
		}

		.no-margin {
			margin: 0;
		}
	}

	&-article {
		position: relative;
		padding: $padding-default .6rem 0;

		> * {
			overflow-y: scroll; //necessary for equal grid layout
		}

		&:after {
			@extend %scrollhide;
			background: $color01;
		}
	}


	&-footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		background-color: $color05;

		.form {
			position: relative;

			> .detail-bundles-form {
				overflow-y: scroll; //necessary for equal grid layout
			}

			&:after {
				@extend %scrollhide;
				background: $color05;
			}
		}

		&-articles {
			position: absolute;
			left: 0;
			top: -20px;
			padding: .2rem 1rem;
			min-height: unset;
			background: $color05;
			border-top-right-radius: 1rem;
			font-size: 1.2rem;
			overflow-y: none;
		}


		.btn {
			padding: 0 1.2rem;
			font-size: 1.8rem;
			outline: none;
		}

		.form {
			padding: .6rem;

			> * {
				min-height: 5rem;
			}
		}
	}

	&-messages {
		overflow: hidden;
		padding: $padding-default;
		background: $color01;

		> * {
			transform: translateY(-150%);
			animation: slideDown 0.3s forwards 1;
		}

		@keyframes slideDown {
			from {
				transform: translateY(100%);
			}
			to {
				transform: translateY(0%);
			}
		}

		&:empty {
			display: none;
		}
	}

	&-list {
		height: 100vh;
		overflow-y: scroll;

		.list-head {
			padding: $padding-default;
		}

		&.withfooter {
			> div:last-child > article:last-child {
				margin-bottom: 50rem; //extra whitespace for scrolling
			}
		}
		&.nofooter {
			> div:last-child > article:last-child {
				margin-bottom: 50rem; //extra whitespace for scrolling
			}
		}
	}
}

@media only screen and ( min-width: $b4) {

	.detail-bundles {


		&-popup {
			img {
				max-width: 6.5rem;
			}
		}

		&-article {
			padding: $padding-default .1rem 0;

			img {
				margin-left: $padding-default/2;
			}
		}

		&-messages {
			padding: $padding-default*2;
		}

		&-footer {
			.form {
				padding: .6rem .1rem;
			}
		}
	}
}

@media only screen and ( min-width: $b5) {

	.detail-bundles {

		&-list {

			.list-head {
				padding: 0 2.4rem;
			}
		}

		&-article {

			img {
				margin-left: $padding-default*2;
			}
		}
	}
}