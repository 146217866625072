.review {
	&-item {
		color: $color06;

		&:hover {
			cursor: default;

			&:not(.basket-item-alternative) {
				background: $color01;
			}
		}
	}
}

@media only screen and (min-width: $b3) {
	.review {
		&-item {
			img {
				max-height: 18.6rem;
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.review {
		&-item {
			.img-wrap {
				padding-left: 1.8rem;
			}
		}
	}
}
