.faq-container {
	position: relative;
	padding: 0 $padding-default * 2;

	.copyable {
		[data-module-copy-icon] {
			margin-left: 1rem;
			font-size: 1.3rem;
			cursor: pointer;
			opacity: 0;
			transition: opacity 0.4s ease-out, transform 0.1s linear;

			&:hover {
				transform: scale(1.2);
			}

			&.copied {
				display: inline-block;
				margin-top: -4px;
				color: $color01;
				background: $theme04a;
				padding: .3rem;
				border-radius: 50%;
				font-size: 10px;
				vertical-align: middle;
			}
		}

		&:hover {
			[data-module-copy-icon] {
				opacity: 0.6;
			}
		}
	}

	.definition-list {
		border: 1px solid $color04;

		> li:last-child .definition-list-title {
			border: none;
		}
	}
}

@media only screen and (min-width: $b3) {
	.faq-container {
		max-width: 100%;
		@include centered-block(0 $padding-default * 3, 70rem);
	}
}

@media only screen and (min-width: $b5) {
	.faq-container {
		@include centered-block(0 10rem, 90rem);
	}
}

@media only screen and (min-width: $b6) {
	.faq-container {
		@include centered-block(0 18rem, 116.4rem);
	}
}
