/*========== LISTER LISTOPTIONS ==========*/
.listOptions {
	position: relative;
	z-index: 2;
	margin-bottom: $margin-default * 2;

	.head {
		font-size: 1.8rem;
		margin-bottom: $margin-default;

		> * {
			display: inline;
			font-size: inherit;
			font-weight: inherit;
		}
	}
}
