/*========== SERVICE DEFINITIONLIST ==========*/
.definition-list {
	margin: 0;
	&-title {
		position: relative;
		padding: 2.4rem 5.4rem 2.4rem 3rem;
		margin: 0;
		background: $color01;
		color: $color03;
		cursor: pointer;
		border-bottom: 1px solid $color04;
		transition: 0.2s ease-out;
		user-select: none;
		[class^='icon-down'] {
			position: absolute;
			top: 50%;
			right: 0;
			width: 4.8rem;
			font-size: 3rem;
			transform: translateY(-50%);
		}
		&.active {
			border-bottom-color: transparent;
			[class^='icon-down']:before {
				content: '\e803';
			}
		}
	}
	&-descr {
		display: none;
		padding: 3rem;
		margin: -30px 0 0 0;
		border-bottom: 1px $color04 solid;
		border-top: none;
		&.active {
			display: block;
		}
		&.extra-product {
			padding: 3rem 0;
			border-bottom: none;
		}
	}
}
/*==========/SERVICE DEFINITIONLIST ========== */
