/*========== DETAIL IMAGES ==========*/
%video-icon {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	background: url(../images/play-button.svg) no-repeat center center;
	background-size: 34%;
}

.composition-img,
.product-img,
.product-thumbs {
	a,
	img {
		display: block;
		width: 100%;
	}
}

.composition-img,
.product-img,
.setImg {
	position: relative;
	margin-bottom: $margin-default;
	cursor: pointer;

	a,
	img {
		display: block;
		width: 100%;
	}

	.video {
		position: relative;
		display: block;
		overflow: hidden;
		margin-right: $margin-default;

		&:after {
			@extend %video-icon;
		}

		img {
			height: 28.8rem;
			max-width: none;
			width: auto;
		}
	}

	.zoom {
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 32px;
		height: 33px;
		border: 1px solid $color04;
		background-color: $color05;
		border-radius: 50%;
		text-indent: -9999px;
		transition: all 0.2s ease-out;

		&:after {
			@include kruitbosch-font();
			content: '\e816';
			position: absolute;
			left: 1px;
			top: 8px;
			text-align: right;
			color: $color03;
			font-size: 18px;
		}

		&:hover {
			border-color: lighten($color03, 50%);
		}
	}
}

.product-thumbs {
	padding: $padding-default;
	border-top: 1px solid $color04;
	border-bottom: 1px solid $color04;

	li {
		a {
			border: 1px solid transparent;
			outline: none;

			&:hover {
				border-color: $color04;
			}
		}

		&.video {
			display: flex;

			.img {
				position: relative;

				&:after {
					@extend %video-icon;
				}
			}

			> a {
				flex: 1;

				img {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		&.active > a {
			border-color: $color04;
		}
	}

	// .video {

	// 	a {
	// 		position: relative;
	// 		text-decoration: none;

	// 		&:before {
	// 			content: "\e827";
	// 			position: absolute;
	// 			top: 50%;
	// 			width: 100%;
	// 			font-size:30px;
	// 			color: $color01;
	// 			line-height:0;
	// 			text-align: center;
	// 		}
	// 		&:after {
	// 			content: "\e81f";
	// 			position: absolute;
	// 			top:50%;
	// 			width: 100%;
	// 			color:$theme01;
	// 			text-align: center;
	// 			font-size:40px;
	// 			line-height:0;
	// 		}

	// 		&:hover {

	// 			&:after {
	// 				color:$theme02;
	// 			}
	// 		}
	// 	}
	// }
}

@media only screen and (min-width: $b4) {
	.thumbs {
		// .video {

		// 	a {

		// 		&:before {
		// 			font-size:50px;
		// 		}
		// 		&:after {
		// 			font-size:60px;
		// 		}
		// 	}
		// }
	}
}
