$scrollernav-image-width: 6rem;
$scrollernav-item-width: 3.7rem;
$scrollernav-item-height: 10.5rem;

.image-scroller {
	padding: 0 0 2rem 0;
	width: 100%;
	font-size: 0;

	&-nav {
		position: relative;
		display: inline-block;
		width: $scrollernav-item-width;
		height: $scrollernav-item-height;
		font-size: $font-size-default;
		line-height: $scrollernav-item-height;
		text-decoration: none;
		vertical-align: top;
		text-align: center;
		color: $color06;
		cursor: pointer;

		&.disabled {
			opacity: 0.6;
			pointer-events: none;
		}

		&.active,
		&:hover {
			color: $theme01;
		}

		[class^='icon-'] {
			position: absolute;
			left: -0.4rem;
			font-size: 2.8rem;

			+ [class^='icon-'] {
				left: 0.4rem;
			}
		}
	}

	&-container {
		position: relative;
		display: inline-block;
		width: calc(100% - #{$scrollernav-item-width * 2});
		white-space: nowrap;
		overflow: hidden;
		text-align: left;
		-webkit-overflow-scrolling: touch;

		> * {
			display: block;
			transition: ease 0.3s all;
		}
	}

	&-item,
	&-item-shadow {
		background: white;
		display: inline-block;
		margin-right: 0.3rem;
		width: 13.3rem;
		height: 10.5rem;
		-webkit-backface-visibility: hidden;
		white-space: normal;
		vertical-align: top;
		text-align: center;

		> span,
		a {
			display: block;
			padding-top: 0.4rem;
			height: 100%;
			text-decoration: none;
			border: 1px solid $color04;
			border-radius: 4px;
			outline: 0;
			transition: ease 0.3s all;

			&.selected,
			&:focus,
			&:hover {
				color: $theme01;
				border: 1px solid $theme01;
			}
		}

		img {
			max-width: 6rem;
			margin: 0 auto;
		}
	}

	&-item-shadow {
		> span,
		a {
			border-color: transparent;
			&.selected,
			&:focus,
			&:hover {
				border-color: transparent;
			}
		}
	}

	&-descr {
		display: flex;
		justify-content: center;
		align-items: center;
		height: calc(100% - #{$scrollernav-image-width});
		overflow: hidden;

		span {
			padding: 0 0.3rem;
			margin-top: -0.6rem;
			font-size: 1.3rem;
			line-height: 1.1;
		}
	}

	&-header {
		padding-left: $padding-default;
	}
}
