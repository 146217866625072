/* SEARCH RESULTS */

.tabs {
	text-align: justify;
	font-size: 0;
	margin-bottom: 0;
	padding: 12px 0 0 0;

	.tab {
		position: relative;
		display: inline-block;
		padding: 0 0 0 1px;
		margin-left: .8rem;
		width: 33.333%;
		font-size: $font-size-default;

		&:first-child {
			margin-left: 0;
		}

		a {
			display: block;
			padding: $padding-default;
			font-weight: bold;
			text-decoration: none;
			text-align: center;
			background: $color05;
			color: $color03;
			outline: none;

			em,
			span {
				display: block;
				font-style: normal;
			}

			span {
				@include ellipsis();
			}
		}

		&:hover {
			a {
				color: $theme03;
			}
		}

		&.active {

			a {
				background: $color01;
				color: $theme01;
			}

			&:after {
				content: '';
				position: absolute;
				left: 50%;
				z-index: 5;
				display: block;
				margin-left: -15px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 14px 15px 0 15px;
				border-color: $theme03 transparent transparent transparent;
			}
		}

		&:first-child {
			padding-left: 0;
		}
	}
}

@media only screen and (min-width: $b3) {
	.tabs {
		.tab {
			width: auto;

			&.active {
				&:after {
					display: none;
				}
			}

			a {
				em,
				span {
					display: inline;
				}
			}
		}
	}
}
