.favorite {
	position: relative;

	&-item {
		position: absolute;
		right: 1.2rem;
		top: 1.2rem;
		z-index: 2;
		padding: $padding-default/2 $padding-default/2 4px $padding-default/2;
		background: rgba($color01, 0.8);
		border-radius: $radius;
		border: 1px solid $color04;
		font-size: 2rem;
		cursor: pointer;
	}
}
