.logon-info {
	&-wrap {
		position: relative;
		padding: $padding-default * 2;
		background: $theme01c;
	}

	&-descr {
		display: inline-block;
		vertical-align: top;
		padding-left: 3rem;
		padding-bottom: 1.7rem;
		line-height: 0.8;
		color: $color01;
	}

	&-switchmessage {
		padding: $padding-default/2 0;
		margin-left: -$margin-default * 2;
		max-width: 25rem;
		font-size: $font-size-default;
		line-height: 1;
	}

	&-icon {
		position: absolute;
		top: 1.6rem;
		left: 2.3rem;
		font-size: 3.2rem;
		line-height: 1.9;
		color: $color01;

		i {
			&:before {
				margin-left: 0;
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.logon-info {
		position: fixed;
		left: 0;
		z-index: -1;
		min-width: 25.5rem;
		padding-top: 12px;
		margin-top: -12px;
		overflow: hidden;
		pointer-events: none;
		opacity: 0;

		&-icon {
			top: 1.6rem;
			left: 2.2rem;
		}

		&.open {
			opacity: 1;
			pointer-events: inherit;
			z-index: 999;
		}

		&-wrap {
			padding: $padding-default * 2;
			border-top: 1px solid $color01;
			background: $theme01d;

			&:before {
				content: '';

				position: absolute;
				top: -10px;
				left: 67px;
				z-index: 1;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 13px 10px 13px;
				border-color: transparent transparent $color04 transparent;
				transition: ease 0.2s all;
				transition-delay: 0.3s;
			}

			&:after {
				content: '';

				position: absolute;
				top: -9px;
				left: 67px;
				z-index: 2;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 13px 10px 13px;
				border-color: transparent transparent $theme01d transparent;
				transition: ease 0.2s all;
				transition-delay: 0.3s;
			}
		}
	}
}

@media only screen and (min-width: $b6) {
	.logon-info {
		min-width: 28.3rem;

		&-icon {
			top: 0.3rem;
			left: 1.1rem;
		}

		&-wrap {
			padding: $padding-default;
		}
	}
}

@media only screen and (min-width: $b7) {
	.logon-info {
		min-width: 32.2rem;
	}
}
