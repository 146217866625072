/*========== FILTERS ==========*/
.filter {
	border-bottom: 1px solid $color04;

	&.no-border {
		border-bottom: none;
	}

	em {
		font-size: 1.2rem;
		font-style: normal;
		color: $color06;
	}

	&-container {
		display: none;

		&.open {
			display: block;
		}
	}

	&-content {
		padding: 0 $padding-default;
		overflow: hidden;
		max-height: 13.2rem;
		transition: ease 0.3s all;

		.closed & {
			display: none;
		}

		&.selected {
			max-height: initial;
			max-height: auto;
		}
	}

	&-title {
		position: relative;
		display: block;
		padding: $padding-default;
		user-select: none;
		cursor: pointer;

		[class^='icon-'] {
			position: absolute;
			top: 50%;
			right: $padding-default/2;
			width: 2.5rem;
			height: 2.5rem;
			line-height: 2.5rem;
			font-size: 2.3rem;
			transform: translateY(-50%);

			&:before {
				.closed & {
					content: '\e801';
				}
			}
		}
	}

	&-more {
		.closed & {
			display: none;
		}
	}
}

.filters {
	position: relative;

	&-title {
		padding: $padding-default 0 $padding-default/2 $padding-default;
		border-bottom: 1px solid $color04;
	}
}

.selected-filters {
	clear: both;

	a {
		position: relative;
		float: left;
		padding: 0.3rem 3rem 0.3rem 0.6rem;
		margin: 0 $margin-default $margin-default 0;
		background: $color05;
		color: $theme01;
		border: 1px solid transparent;
		text-decoration: none;
		font-weight: bold;
		border-radius: $radius;

		&:after {
			content: '\2716';
			display: block;
			position: absolute;
			top: 3px;
			right: 6px;
			font-weight: normal;
		}

		&:hover {
			background: $theme01;
			color: $color01;
		}
	}
}

@media only screen and (min-width: $b4) {
	.filters {
		&-title {
			padding-left: $padding-default * 2;
		}
	}

	.filter {
		&-container {
			display: block;
			margin-bottom: $margin-default * 2;
		}

		&-content {
			padding: 0 $padding-default * 2;
			max-height: 11.7rem;

			.input-control {
				margin-bottom: $margin-default/2;
			}
		}

		&-title {
			padding: $padding-default $padding-default * 2;
		}
	}
}
/*========== /FILTERS ==========*/
