.wishlist {
	width: 100%;
	height: 100%;

	.tab.active:after {
		display: none;
	}

	&-wrap {
		position: relative;
		height: 100%;
	}

	&-container {
		border-right: 1px solid $color04;
	}

	&-message {
		background: $color01;
		padding: $padding-default * 1.5 $padding-default;
	}

	&-count {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		background: $color01;
		padding: $padding-default * 1.5 $padding-default;

		&.filter {
			padding: $padding-default;

			> span {
				margin-bottom: $margin-default;
			}
		}

		+ .list-item {
			margin-top: $margin-default;
		}
	}

	&-separator {
		padding: $padding-default * 1.5 $padding-default;
		margin-top: $margin-default;
		background: $color01;
		border-bottom: 1px solid $color04;
	}

	&-actions {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		> form {
			width: 15rem;

			+ div {
				width: 2.5rem;
			}
		}

		.btn-link {
			//flex: 0 0 30%;
			margin-left: 18%;
			color: $color03;
			font-size: 2.2rem;
		}
	}

	.list-item {
		&:first-child {
			border-top: none;
		}
	}
}

@media only screen and (min-width: $b4) {
	.wishlist {
		&-count {
			&.filter {
				> span {
					margin-bottom: 0;
				}
			}
		}

		&-actions {
			> form {
				flex: 0 0 50%;
				width: auto;

				+ div {
					flex: 0 0 20%;
					width: auto;
				}
			}

			.btn-link {
				flex: 0 0 30%;
			}
		}
	}
}
