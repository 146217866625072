%plus {
	position: relative;

	&:after {
		content: '+';
		position: absolute;
		top: 0;
		right: 0;
		width: 8px;
		height: 8px;
		line-height: 8px;
		font-size: 1rem;
	}
}

.alert {
	position: relative;
	margin-bottom: $margin-default * 2;
	color: $warning-text-color;
	border-color: transparent;
	font-weight: $font-weight-extrabold;

	a {
		color: $theme01;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
			color: $theme01b;
		}
	}

	&.has-icon {
		padding-left: 4.5rem;
	}

	[class^='icon-'] {
		position: absolute;
		top: 0;
		left: -6px;
		transform: translateY(-14%);
		font-size: 4rem;

		&.close {
			position: absolute;
			right: 10px;
			top: 50%;
			left: auto;
			font-size: 2.4rem;
			transform: translateY(-50%);
			color: $theme01;
		}
	}

	&-warning {
		color: $warning-text-color;

		&.plus {
			@extend %plus;
		}
	}

	&-positive {
		color: $positive-text-color;

		&.plus {
			@extend %plus;
		}
	}

	&-negative {
		color: $negative-text-color;

		&.plus {
			@extend %plus;
		}
	}

	&-disabled {
		@extend .alert;
		background-color: $disabled;
		color: $disabled-text-color;

		&.plus {
			@extend %plus;
		}
	}

	&-present {
		@extend .alert;
		color: $theme02;
		background-color: $color04;

		&.plus {
			@extend %plus;
		}
	}

	&-border {
		@extend .alert;
		color: $color03;
		background-color: $color01;
		border: 1px solid $color04;
		border-radius: $radius;

		&.plus {
			@extend %plus;
		}
	}

	&.small {
		display: inline-block;
		padding: 0 $padding-default/2;
		height: 2rem;
		font-size: 1.2rem;
		line-height: 1.9rem;
		letter-spacing: $default-letterspacing;
	}

	&-label {
		cursor: pointer;
	}
}

.list {
	&-item {
		&-notification {
			border-top: 3px solid $color04; // 3px because list item has 1px bottom border
			border-bottom: 4px solid $color04;

			.container-children & {
				border: none;
				margin-bottom: $margin-default;
			}

			> span {
				padding: 2px 0 2px 0;
			}
		}
	}
}

.notify {
	color: $warning-text-color;
	font-weight: $font-weight-extrabold;

	&-warning {
		color: $warning-text-color;
		font-weight: $font-weight-extrabold;
	}

	&-positive {
		color: $positive-text-color;
		font-weight: $font-weight-extrabold;
	}

	&-negative {
		color: $negative-text-color;
		font-weight: $font-weight-extrabold;

		&-label {
			@extend .notify-negative;
			cursor: pointer;
		}
	}

	&-present {
		color: $theme02;
		font-weight: $font-weight-extrabold;
	}

	&-disabled {
		color: $disabled-text-color-with-opacity;
		font-weight: $font-weight-extrabold;
	}
}

@media only screen and (min-width: $b4) {
	%check {
		position: relative;

		&:after {
			content: '\e81c';
			@include kruitbosch-font();
		}
	}

	.alert {
		&.has-icon {
			padding-left: 7rem;
		}

		[class^='icon-'] {
			top: 50%;
			font-size: 6.2rem;
			transform: translateY(-50%);
		}

		&-warning {
			&.check {
				@extend %check;
			}
		}

		&-positive {
			&.check {
				@extend %check;
			}
		}

		&-negative {
			&.check {
				@extend %check;
			}
		}

		&-disabled {
			&.check {
				@extend %check;
			}
		}

		&-present {
			&.check {
				@extend %check;
			}
		}

		&-border {
			&.check {
				@extend %check;
			}
		}
	}
}
