/*========== VARIABLES ==========*/

// Colors
// Color 01 and 02 reservered for white and black
$color01: white;
$color02: black;
$color03: #3d3c3c; //Default font color
$color04: #e6e6e6; //body bg-color & form border
$color05: #f5f5f5; //lighter bg color
$color06: #7f7f7f; //lighter font color
$color07: #6da2a7; //parts bg color
$color08: #b7b7b7; //icon color
$color09: #555555; /* main menu items color */

// Theme colors
$theme01: #bf5426;
$theme01a: lighten($theme01, 10%);
$theme01b: #6a2711;
$theme01c: #89341c;
$theme01d: #a23c1b;
$theme01f: #ff0000;

$theme02: #1d3a58;
$theme02a: lighten($theme02, 10%);
$theme02b: darken($theme02, 10%);
$theme02c: #2368b0;

$theme03: #3d3c3c;
$theme03a: lighten($theme03, 50%);
// $theme03b:	darken( $theme03, 10%);

$theme04: #08784c;
$theme04a: #1db077;

$alert: #fdedc9;
$warning: #fdedc9;
$warning-text-color: #dd9e08;
$negative: #ffe8e1;
$negative-text-color: $theme01;
$positive: #d9efe6;
$positive-text-color: #1db077;
$disabled: #cdcdc1;
$disabled-text-color: #3d3c3c;
$disabled-text-color-with-opacity: #b2b2b2;

$omnidesk-agent: #fdedc9;

/*
	Breakpoints
	You can edit or add breakpoints
*/
$b1: 320px;
$b2: 480px;
$b3: 768px;
$b4: 1024px;
$b5: 1280px;
$b6: 1680px;
$b7: 1920px;

/*
	Mapping for breakpoint loops
	Must be the same amount as breakpoints above
*/
$breakpoint-map: (
	1: $b1,
	2: $b2,
	3: $b3,
	4: $b4,
	5: $b5,
	6: $b6,
	7: $b7,
);
$amount-breakpoints: length($breakpoint-map);

/*
	Container sizes
	Must be the same amount of breakpoints and $breakpoint-map as above
*/
$container: 100%;
$container-b1: 100%;
$container-b2: 100%;
$container-b3: 752px;
$container-b4: 992px;
$container-b5: 1232px;
$container-b6: 1592px;
$container-b7: 1832px;

/*
	Container map
	Must be the same amount of breakpoints and $breakpoint-map as above
*/
$container-map: (
	1: $container-b1,
	2: $container-b2,
	3: $container-b3,
	4: $container-b4,
	5: $container-b5,
	6: $container-b6,
	7: $container-b7,
);

/*
	Flexbox grid, set to true or false for flexbox
*/
$flexbox-grid: true;

/*
	Grid gutters
	Must be the same amount of breakpoints $breakpoint-map as above
*/
$grid-gutter: 12px;
$grid-gutter-b1: 12px;
$grid-gutter-b2: 12px;
$grid-gutter-b3: 8px;
$grid-gutter-b4: 8px;
$grid-gutter-b5: 24px;
$grid-gutter-b6: 24px;
$grid-gutter-b7: 24px;

$gutter-map: (
	1: $grid-gutter-b1,
	2: $grid-gutter-b2,
	3: $grid-gutter-b3,
	4: $grid-gutter-b4,
	5: $grid-gutter-b5,
	6: $grid-gutter-b6,
	7: $grid-gutter-b7,
);

/*
	Default margin and padding
*/
$padding-default: 12px;
$margin-default: 12px;
/*
	Standard border-radius
*/
$radius: 3px;

$default-letterspacing: 0.1rem;

// Fonts
$font-pri: 'Nunito', sans-serif;
$font-barcode: 'Barcode', sans-serif;
//$font-sec: 'Montserrat', Arial, Helvetica, sans-serif;
$font-size-default: 1.5rem;
$line-height-default: 1.6;
$font-weight-normal: 400;
$font-weight-bold: bold; // i.o.m. willem gekozen voor noraal bold font en niet voor de 700 variant i.v.m. snelheid
$font-weight-extrabold: 800;

/* Form specific variables */

/* Standard Inputs */
$input-height: 4rem;
$input-bg-color: $color01;
$input-bg-color-focus: $color01;
$input-color: $color03;
$input-color-focus: $color03;
$input-border-color: $color04;
$input-border-color-focus: lighten($color03, 50%); //Same as checkbox and radio
$input-border: 1px solid $input-border-color;

/* Custom select */
$select-arrow: '\203A';

/* Labels */
$input-label-color: #7f7f7f;
$input-label-color-focus: #7f7f7f;

/* Radios and Checkboxes*/
$input-control-color: #eee;
$input-control-color-focus: $color01;

/* Form transitions */
$input-transition: 0.1s all ease-in-out;

/* Icons */
$input-icon-color: #eee;
$input-icon-bg-color: #797979;

/* header */
$header-height: 5rem;
$header-icon-width: 5.6rem;
/*Content */
$max-content-width: 100rem;
