%container {
	overflow: scroll;
	overflow-x: hidden;
	background: $color01;
	max-height: calc(90vh - 80px);
}

%close {
	position: absolute;
	top: 50%;
	right: 2.4rem;
	padding: 0;
	transform: translateY(-50%);
	background: transparent;
	border: none;
	color: $color01;

	&:hover {
		color: $color05;
	}
}

.modal {
	&-base {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 99999;
		background: rgba(0, 0, 0, 0.7);
		transition: opacity 400ms ease-in;

		.head {
			margin-bottom: 0;
		}
	}

	&-rightsided {
		position: absolute;
		right: 0;
		height: 100%;
		width: 100%;
		transform: translateX(100%);
		will-change: transform;
		background: $color01;
		overflow: hidden;
		animation: slide 0.3s forwards 1;
	}

	@keyframes slide {
		from {
			transform: translateX(100%);
		}
		to {
			transform: translateX(0%);
		}
	}

	&-wrapper {
		position: relative;
		top: 25px;
		margin: 0 auto;
		max-width: 100%;
		max-height: calc(100vh - 37px);
		pointer-events: auto;
		padding: $padding-default;
	}

	&-container {
		height: 100%;
		pointer-events: none;
	}

	&-heading {
		position: relative;
		padding: $padding-default $padding-default * 2 $padding-default $padding-default;
		background-color: $theme01;
		text-transform: uppercase;
		color: $color01;

		&-close {
			@extend %close;
		}
	}

	&-heading-alt {
		position: relative;
		padding: 1.8rem $padding-default*2;
		background-color: $color05;
		text-transform: uppercase;
		color: $color03;

		&-close {
			@extend %close;
			font-size: 1.9rem;
			color: $theme03;

			&:hover {
				color: $color02;
			}
		}
	}

	&-content {
		position: relative;

		&-container {
			@extend %container;
			padding: $padding-default * 2;

			&-nopadding {
				@extend %container;
			}
		}

		&-actions {
			text-align: right;

			> * {
				display: inline-block;
				margin-left: 1.2rem;
			}
		}

		&-left {
			text-align: left;

			> * {
				display: inline-block;
				margin-left: 1.2rem;
			}
		}
	}
}

@media only screen and (min-width: $b3) {
	.modal {
		&-wrapper {
			top: 15%;
			max-width: 600px;
			transform: translateY(-15%);
			padding: 0;

			.mac & {
				top: 15rem;
			}
		}

		&-heading {
			padding: $padding-default $padding-default * 2;
		}

		&-content {
			&-container {
				overflow: auto;

				&-nopadding {
					overflow: auto;
				}
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.modal {
		&-rightsided {
			width: 50%;
		}
	}
}

@media only screen and (min-width: $b6) {
	.modal {
		&-rightsided {
			width: 40%;
		}
	}
}