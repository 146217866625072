.list.packingslip {

	.list-item {
		padding: $padding-default;

	}

	.list-items-details {
		border-top: 1px solid $color01;
		background-color: $color04;
	}

	.do-item {
		[class^='icon-'] {
			font-size: 32px;
		}

		.packingslip-detail-label {
			color: $color06;
		}

		.txt-barcode {
			font-family: $font-barcode;
			font-size: 3.7rem;
			letter-spacing: 0.5rem;
			line-height: 0.5;
		    padding-top: 14px;
		}

		&-detail {
			p {
				padding-right: .6rem;
				margin-bottom: .6rem;
				line-height: 1.2;
			}
		}

		&.open {
			background-color: $color01;

			+ .do-item-wrap {
				cursor: default;
				border-bottom: 1px solid $color04;
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.list.packingslip {

		.list-items-details {
			border-top: 0;
			background-color: transparent;

			> * {
				padding: $padding-default/2 0;
			}
		}

		.do-item {

			&-articlecode,
			&-ordernumber {
				padding-left: $padding-default;
			}
		}
	}
}

@media only screen and (min-width: $b6) {
	.list.packingslip {

		.do-item {

			&-wrap  {
				padding-right: 20%;
			}

			&.open {
				> div {
					min-height: 6.2rem;
				}
			}
		}
	}
}
