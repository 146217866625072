.widget {
	&-navigate {
		&-lines {
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				border-bottom: 1px solid $color04;

				a {
					position: relative;
					display: flex;
					align-items: center;
					padding: 0.8rem $padding-default * 2;
					justify-content: space-between;
					text-decoration: none;
					outline: none;

					&:hover {
						color: lighten($color03, 20%);
					}

					img {
						height: 4rem;
					}

					> span {
						flex: 1 1 auto;
						padding-left: $padding-default;
						padding-top: 0.3rem;
						line-height: 1.3;
						overflow: hidden;
					}
				}

				&:nth-of-type(1n + 4) {
					border-bottom: none;
				}

				&:nth-of-type(1n + 5) {
					display: none;
				}
			}
		}

		&-title {
			display: block;
			text-align: left;
			font-weight: $font-weight-bold;
			font-size: 1.3rem;
		}

		&-subtitle {
			display: block;
			font-weight: $font-weight-normal;
			font-size: 1.3rem;
			@include ellipsis();
		}

		&-arrow {
			position: absolute;
			right: 0;
			top: 0;
			padding: 0 $padding-default/2;
			height: 100%;
			font-size: 4.8rem;
			font-style: normal;
			line-height: 1;
		}
	}
}

@media only screen and (min-width: $b3) {
	.widget {
		&-navigate {
			&-lines {
				li {
					a {
						padding-right: $padding-default * 4;
					}
				}
			}

			&-arrow {
				padding: 0 $padding-default;
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.widget {
		&-navigate {
			&-lines {
				li {
					a {
						padding: 0.6rem $padding-default * 4 0.7rem $padding-default * 2;
					}
				}
			}

			&-arrow {
				padding: 0 $padding-default;
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.widget {
		&-navigate {
			&-lines {
				li {
					a {
						padding: 0.4rem $padding-default * 4 0.4rem $padding-default * 2;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $b6) {
	.widget {
		&-navigate {
			&-lines {
				li {
					a {
						padding: $padding-default $padding-default * 4 $padding-default $padding-default * 2;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $b7) {
	.widget {
		&-navigate {
			&-lines {
				li {
					a {
						padding: 0.8rem $padding-default * 4 0.8rem $padding-default * 2;
					}
				}
			}
		}
	}
}
