/*========== prices ==========*/

.price,
del,
.pace-list {
	font-weight: $font-weight-extrabold;
	// optional styling for price after the comma
	.sup {
	}
}

ins.price {
	//font-weight: $font-weight-bold;
}

.prices {
	.price {
		text-decoration: none;
	}

	&.piece {
		.price {
			font-weight: $font-weight-normal;
		}

		del {
			display: block;
			color: $color06;
			font-size: 1.2rem;
			line-height: 0.5;

			+ .price {
				color: $theme01;
				font-weight: $font-weight-extrabold;
			}
		}
	}
}

del,
.price {
	display: inline-block;
}

del {
	color: $color03;
	font-weight: $font-weight-normal;
	margin: 0 6px 0 0;
}
