// Default labels
label {
	&[class^='alert-'],
	&[class*='alert-'] {
		cursor: pointer;
	}
}
// Default input fields
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
select,
textarea {
	border-radius: $radius;

	&:disabled,
	&[readonly] {
		background: $color05;
		color: $color06;
		cursor: not-allowed;
	}
}

select {
	padding: 0 3.4rem 0 $padding-default;
}

.label-bottom,
.label-above,
.label-top {
	.no-label.has-value {
		input,
		textarea {
			padding-top: 0;
		}
	}
}

// input indicators
.input-indicator {
	top: 0;
	border-radius: $radius;
	background: $color01;
	border: 1px solid $color04;
}

.input-control {
	&:hover,
	&:focus {
		.input-indicator {
			border: 1px solid lighten($color03, 50%);
		}
	}
}

.input-checkbox {
	.input-indicator {
		&:after {
			border-width: 0 2px 2px 0;
			border-color: $color03;
		}
	}
}

.input-radio {
	.input-indicator {
		&:after {
			top: 5px;
			left: 5px;
			width: 8px;
			height: 8px;
			background: $color03;
		}
	}
}

.input-color {
	padding-left: 53px;

	.color-indicator {
		> * {
			overflow: hidden;
			position: absolute;
			top: 1px;
			left: 27px;
			display: block;
			width: 1.8rem;
			height: 1.8rem;
			border: 1px solid $color04;
			border-radius: 50%;
		}
	}
}

.form-fields {
	&.label-top {
		.has-value,
		.has-focus {
			input[type='number'] {
				padding: 0;
			}
		}
	}
}

.form-field {
	.input-combined {
		input {
			border-radius: $radius 0 0 $radius;
			min-width: 0;
		}

		.btn-submit {
			border-radius: 0 $radius $radius 0;
		}
	}

	.input-wide {
		.btn-submit {
			width: 100%;
		}
	}

	&.date {
		&.has-focus {
			label {
				color: transparent;
			}
		}

		&.has-value {
			input[type='date'] {
				color: $color03;
			}
		}
	}

	input[type='date'] {
		color: transparent;

		&:focus {
			color: $color03;
		}
	}

	// TODO: can be removed when castlecss-forms is updated
	&.has-value {
		label[for] {
			pointer-events: none;
		}
	}

	&.field-invalid {
		border: 2px solid $negative-text-color;
	}
	&.field-valid {
		border: 2px solid transparent;
	}
}

.input-combined-search {
	position: relative;

	input[type='search'] {
		border: transparent;
		background: $theme01b;
		height: $header-height;
		color: $color01;
	}

	.btn-cancel,
	.btn-submit {
		position: absolute;
		top: 1rem;
		right: 0.4rem;
		padding: 0 1.2rem 0 0.4rem;
		min-height: 3.1rem;
		height: 3.1rem;
		background: transparent;
	}

	.btn-cancel {
		right: 4.4rem;
		padding: 0 0.4rem;
	}
}

@media only screen and (min-width: $b4) {
	.input-combined-search {
		display: flex;
		justify-content: center;
		align-items: center;
		height: $header-height;

		input[type='search'] {
			height: 3.6rem;
		}
	}
}

@media only screen and (min-width: $b5) {
	.input-combined-search {
		.btn-submit {
			padding: 0 0.6rem;
			min-height: 3rem;
			height: 3rem;
			background: $theme01;
			border-radius: $radius;

			&:hover {
				background: $theme01;
			}
		}
	}
}

@media only screen and (min-width: $b6) {
	.input-combined-search {
		.btn-cancel {
			right: 9rem;
		}
	}
}

// Validated fields
.field-invalid {
	margin-bottom: 0;

	input[type='email'],
	input[type='number'],
	input[type='password'],
	input[type='search'],
	input[type='tel'],
	input[type='text'],
	input[type='url'],
	input[type='date'],
	select,
	textarea {
		border-radius: $radius $radius 0 0;
	}

	+ [class^='notify-'] {
		display: block;
		margin: 0.2rem 0 $margin-default $margin-default;
	}

	+ [class^='alert-'],
	+ [class*='alert-'] {
		border-radius: 0 0 $radius $radius;
	}
}
