.widget {
	&-image {

		&-with-text {
			display: flex;
    		flex-direction: column;
			position: relative;
			font-size: 0;

			img {
				width: 100%;
			}

			.widget-descr {
				flex: 1 1 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: $padding-default;
				font-size: $font-size-default;
				color: $color03;

				strong {
					display: block;
					margin-bottom: $margin-default;
					@include ellipsis();
				}

				p {
					@include multiLineEllipsis(7.2rem, 3);
					margin-bottom: 0;
				}
			}
		}
	}
}

@media only screen and ( min-width: $b5) and ( max-width: ($b5 -1px))  {
	.widget {
		&-image {

			&-with-text {


				.widget-descr {

					p {
						@include multiLineEllipsis(4.8rem, 2);
					}
				}
			}
		}
	}
}
