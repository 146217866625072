/*========== HEADER SEARCH ==========*/

.search {
	position: relative;
	z-index: 2;
	background: transparent;

	.form-field.search {
		margin-bottom: 0;

		label[for] {
			color: $color01;
			font-size: 1.3rem;
		}

		input[type='search'] {
			border-radius: 0;
			padding-right: 5rem;
		}
	}

	.btn {
		display: block;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		transition: opacity 300ms ease-out;
	}

	.btn-cancel {
		display: inline-block;
		visibility: hidden;
		opacity: 0;
	}

	.has-text {
		.form-field.search {
			input[type='search'] {
				padding-right: 7.6rem;
			}
		}

		.btn-cancel {
			visibility: visible;
			opacity: 1;
		}
	}
}
@media only screen and (max-width: $b4 - 1) {
	.search {
		display: flex;
		background: $theme01b;

		.form-autocomplete {
			flex: 1;
		}
		.input-combined-search {
			width: 100%;
		}
	}
}
@media only screen and (min-width: $b4) {
	.search {
		padding: 0;
		height: auto;
		min-height: initial; //used by all other browsers
		min-height: auto; //used by IE11 on windows 7
		background: none;

		.form-field.search {
			input[type='search'] {
				border-radius: $radius;
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.search {
		.btn-submit {
			span {
				display: inline-block;
			}
		}

		.has-text {
			.btn-submit {
				span {
					display: none;
				}
			}
		}
	}
}

@media only screen and (min-width: $b6) {
	.search {
		.has-text {
			input[type='search'] {
				padding-right: 12rem;
			}

			.btn-submit {
				span {
					display: inline-block;
				}
			}
		}
	}
}
