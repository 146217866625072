.error {
	&-entrance {
		&-item {
			a {
				display: block;
				border: 1px solid $color04;
				padding: $padding-default;
				margin-bottom: $margin-default;
				text-decoration: none;

				&:hover {
					border: 1px solid $theme01;
					color: $theme01;
				}
			}
		}
	}
}

@media only screen and (min-width: $b3) {
	.error {
		&-entrance {
			&-item {
				display: flex;
				flex-direction: column;

				a {
					display: flex;
					flex: 1 0 auto;
					align-items: center;
					justify-content: center;
					text-align: center;
				}
			}
		}
	}
}
