.users {
	.block-head {
		position: relative;

		a {
			position: absolute;
			right: 1.2rem;
			top: 50%;
			width: auto;
			transform: translateY(-50%);
			//z-index: 1;
		}

		.head {
			display: inline-block;
			width: auto;
			margin-right: $margin-default;
		}
	}

	.list-item {
		background: $color01;
		cursor: default;
	}
}
@media only screen and (min-width: $b4) {
	.users {
		.block-head {
			a {
				right: 2.4rem;
			}
		}
	}
}
