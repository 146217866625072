.sticker-amount {
	display: inline-block;
	min-width: 2.4rem;
	padding: 0 4px 0 5px;
	color: $color01;
	font-weight: $font-weight-extrabold;
	font-size: 1.25rem;
	font-style: normal;
	text-align: center;
	background: $theme01;
	border-radius: 999px;
	line-height: 1.4;

	> * {
		display: inline-block;
		line-height: 1.4;
	}
}

.sticker-amount-theme02 {
	@extend .sticker-amount;
	background-color: $theme02;
}

.sticker-amount-color05 {
	@extend .sticker-amount;
	background-color: $color05;
	color: $theme02;
}

@media only screen and (min-width: $b4) {
	.sticker-amount {
		min-width: 3.1rem;
		padding: 1px 6px 0 7px;
		line-height: $line-height-default;

		> * {
			line-height: 1;
		}
	}
}
