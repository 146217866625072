.modal-base.basket-config {
	.form-field {
		margin-bottom: $margin-default;
	}

	.config-icon {
		font-size: 7rem;
	}
}

@media only screen and (min-width: $b3) {
	.modal-base.basket-config {
		.form-field {
			margin-bottom: 0;
		}

		.modal {
			&-content {
				&-picture {
					display: block;
					padding-left: $padding-default * 2;

					img {
						width: 14.5rem;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.modal-base.basket-config {
		.modal {
			&-wrapper {
				max-width: 665px;
			}
		}
	}
}
