/*========== PRINT ==========*/
* {
	background: transparent !important;
	color: #606060 !important;
	box-shadow: none !important;
	text-shadow: none !important;
	filter: none !important;
	-ms-filter: none !important;
}
a,
a:visited {
	text-decoration: underline;
}
thead {
	display: table-header-group;
}
tr,
pre,
img,
blockquote {
	page-break-inside: avoid;
}
img {
	max-width: 100% !important;
}
p,
h2,
h3,
.head {
	orphans: 3;
	widows: 3;
}
h2,
h3,
.head {
	page-break-after: avoid;
}

body {
	font-family: Georgia, 'Times New Roman', serif !important;
}
.head {
	font-family: Georgia, 'Times New Roman', serif !important;
}
.content {
	font-size: 14px !important;
	font-size: 1.4rem !important;
}

/* hide common elements for print */
header.header,
.crumbs,
footer.footer,
.btn,
input,
select,
.share {
	display: none !important;
}

/* hide plugins */
.acResults,
#fb-root,
#fancybox-tmp,
#fancybox-loading,
#fancybox-overlay,
#fancybox-wrap,
.addthis_toolbox {
	display: none !important;
}

/* detail */
.articleNav,
.zoom,
.thumbs,
#imageZoom,
.rating,
.shortInfo,
.product-option *:before,
.product-option *:after,
.detail-actions-main .input,
.detail-actions-main .submit,
.detail-actions-main .toWishList,
.extraOptions,
.productDownloads,
.reviews,
.combi,
.related,
.lastseen {
	display: none !important;
}

.details-pri {
	max-width: 55% !important;
}
.details-sec {
	max-width: 40% !important;
}

.detail-actions-main,
.terDetails,
.size-list,
.product-option ul,
.product-option li,
.product-option label,
.product-option span,
.product-option a {
	display: block !important;
	position: static !important;
	clear: both !important;
	padding: 0 !important;
	margin: 0 !important;
	float: none !important;
	width: auto !important;
	height: auto !important;
	border: none !important;
	text-align: left !important;
	text-indent: 0 !important;
	text-decoration: none;
	min-width: 0 !important;
}
.detail .stock {
	margin: 0 !important;
}

.product-option {
	clear: both !important;
	padding-top: $padding-default;
	margin-bottom: 0 !important;
}
.product-option * {
	font-size: 14px !important;
	font-size: 1.4rem !important;
}
.product-option li {
	float: left !important;
	clear: none !important;
	margin-right: $margin-default !important;
}
.prices {
	clear: both !important;
	padding-top: $padding-default;
}
.product-option .active a,
.product-option .active span {
	text-decoration: underline;
}
.product-option .disabled * {
	color: #ccc !important;
}
.detail-actions-main .g > * {
	width: 100%;
}
.terDetails p {
	margin: 0 0 $padding-default;
}

/* confirmation */
.block.confirmationSection.payment,
.miniBasket,
.vcard {
	border: 0;
	padding: 0;
}

.miniBasket {
	img {
		width: 48px;
	}
	.item {
		&.combination .combinationBundle {
			width: 75%;
			img {
				width: 56px;
			}
		}

		&.totalPriceIncl {
			border-color: $color02;
		}
	}
	.itemInfo .itemContent {
		margin-left: 15px;
		padding-left: 0;
	}
	.promo {
		border: 1px solid $color02;

		&.first {
			margin-bottom: $margin-default;
		}

		&.first > div:before {
			display: none;
		}
	}

	.subTotal {
		padding: 0;
		margin: 0;
	}
}

.color-list .color > * {
	width: auto;
	height: auto;
	border: 0;
	text-indent: 0;
}

.customerService .imgContainer {
	border: 0;
	img {
		display: none;
	}
}

/* news */
.commentForm,
.commentList {
	display: none;
}
