.action-menu {
	position: absolute;
	right: 0;
	top: 0;
	outline: none;
	cursor: pointer;

	&-container {
		position: absolute;
		background-color: $color01;
		top: 4px;
		right: 4px;
		z-index: 3;
		border: 1px solid $color04;
		border-radius: $radius;
		display: none;
		text-align: left;
		box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);

		&-active {
			display: block;
		}
	}

	&-item {
		display: block;
		white-space: nowrap;
		text-decoration: none;
		padding: 1.2rem;

		&-delete {
			border-top: 1px solid $color04;
		}
	}

	&-close {
		position: absolute;
		right: 0;
		top: 0;
		background: transparent;
		width: 32px;
		border: none;
		padding-right: 3px;
		padding-left: 0;

		&:hover {
			color: $theme01;
		}
	}

	&-icon {
		position: relative;
		padding-left: 3.4rem;

		> span[class^='icon-heart'] {
			position: absolute;
			left: 13px;
			top: 50%;
			right: auto;
			height: auto;
			width: auto;
			line-height: 1;
			font-size: 100%;
			transform: translateY(-50%);

			&:before {
				position: relative;
				left: auto;
				top: auto;
				margin: 0;
				height: auto;
				width: auto;
				font-size: 1.5rem;
				line-height: 1;
			}
		}
	}

	[class^='icon-'] {
		display: inline-block;
		position: relative;
		top: auto;
		left: auto;
		font-size: 2.5rem;
		line-height: 35px;
		height: 3.5rem;
		width: 3.5rem;
	}
	.icon-menu {
		top: 0.6rem;

		&:before {
			font-style: normal;
			content: '\2807';
		}
	}
	.icon-cancel {
		top: -6px;
		right: -4px;

		&:before {
			font-size: 14px;
		}
	}
}

@media only screen and (min-width: $b4) {
	.action-menu {
		&-container {
			top: 10px;
		}

		.icon-menu {
			top: 1.3rem;
		}
	}
}
