.consumerview {
	display: none;
	position: relative;
	z-index: 2;
	margin-left: auto;
	min-width: 15rem;
	text-align: center;
	background: $color04;

	&.with-switch {
		min-width: 17rem;

		&.bar-active {
			min-width: 18.6rem;
		}

		.consumerview-bar {
			margin-top: 0.4rem;
		}
	}

	&-switch {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 3.2rem;
		height: 3.2rem;
		color: $color03;
		background: $color01;
		border-radius: $radius;
		font-size: 2rem;
		line-height: 1;
		text-decoration: none;

		&:focus {
			outline: none;
		}
	}

	&-bar {
		opacity: 0;
		position: fixed;
		right: -26px;
		transform: translateX(100%) translateX(-29px);
		transition: ease 0.3s all;
		transition-delay: 0.3s;

		[class^='icon-'] {
			display: inline-block;
			margin-right: 0.6rem;
		}

		&.visible {
			opacity: 1;
			right: 0;
			padding: 0 0.5rem;
			background: $positive-text-color;
			font-size: 1.5rem;
			font-weight: $font-weight-extrabold;
			letter-spacing: 3px;
			color: $color01;
			border-top-left-radius: 99px;
			border-bottom-left-radius: 99px;
		}

		&:hover,
		&.open {
			cursor: pointer;
			transform: translateX(0);
		}
	}

	&-link {
		margin-right: 0.4rem;
		text-decoration: none;
		vertical-align: text-bottom;

		[class^='icon-'] {
			display: inline-block;
			width: 1.5rem;
			font-size: 1.5rem;
		}
	}

	&.mobile {
		display: block;
		text-align: right;
		padding-right: $padding-default;
		background: transparent;

		.consumerview-link {
			position: relative;
			display: inline-block;
			padding: 0.8rem 0 0.8rem 2rem;
			color: $color01;
			outline: none;

			[class^='icon-'] {
				position: absolute;
				top: 8px;
				left: 0;
				font-size: 1.5rem;
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.consumerview {
		display: block;

		&.mobile {
			display: none;
		}
	}
}
