.visual-with-text {
	position: relative;
	padding: 0 $padding-default * 2;

	&-img {
		img {
			display: block;
			max-width: 100%;
			width: 100%;
		}
	}

	&-text {
		padding: $padding-default * 2;

		&.border {
			border: 1px solid $color04;
		}

		> * {
			max-width: 100%;
		}
	}

	[class*='bgcolor-theme01'] {
		color: $color01;

		a {
			color: $color01;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		.btn-theme01 {
			background: $theme01c;
			text-decoration: none;

			&:hover {
				background: $theme01b;
			}
		}

		&.border {
			border-color: $theme01c;
		}
	}
}

@media only screen and (min-width: $b3) {
	.visual-with-text {
		&-text {
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
			align-items: flex-start;
			padding: $padding-default * 4;
			flex: 1;
		}

		.g > * {
			display: -webkit-flex;
			-webkit-flex-direction: column;
		}
	}
}

@media only screen and (min-width: $b3) {
	.visual-with-text {
		max-width: 100%;
		@include centered-block(0, 70rem);
	}
}

@media only screen and (min-width: $b5) {
	.visual-with-text {
		@include centered-block(0, 90rem);

		&-text {
			padding: $padding-default * 4;
		}
	}
}

@media only screen and (min-width: $b6) {
	.visual-with-text {
		@include centered-block(0, 116.4rem);
	}
}
