.category {
	&-small {
		background: $color04;
		padding: $margin-default 0;

		.category-html-holder.first & {
			padding: 0 0 $margin-default 0;
		}
	}

	&-item {
		display: flex;
		text-decoration: none;
		align-items: center;
		width: 100%;
		min-height: 73px;

		picture {
			display: flex;
		}

		&-img {
			img {
				width: 73px;
				height: 73px;
			}
		}

		&-body {
			padding-left: $padding-default * 2;
			flex: 1;
			font-size: 1.6rem;
			font-weight: 800;

			small {
				font-size: 1.5rem;
				font-weight: normal;
				color: $color06;
				display: flex;
			}

			.bgcolor-color01 & {
				color: $theme01;

				small {
					color: $color03;
				}
			}

			.bgcolor-color05 & {
				color: $color03;

				small {
					color: $color03;
				}
			}

			.bgcolor-theme01 & {
				color: $color01;

				small {
					color: $color01;
				}
			}
		}

		&-icon {
			font-size: 3rem;
			margin-right: 22px;
			width: 45px;

			.bgcolor-color01 & {
				color: $theme01;
			}

			.bgcolor-color05 & {
				color: $color03;
			}

			.bgcolor-theme01 & {
				color: $color01;
			}
		}
	}
}
