/*========== LISTER LOADING ==========*/
.more-items {
	padding-top: 48px;
	padding-bottom: 48px;
	text-align: center;

	small {
		display: block;
		padding: 6px 0 0;
		text-align: center;
		color: $theme01;
	}
}
/*==========/LISTER LOADING ==========*/
