.label {
	&-star {
		position: absolute;
		right: -14px;
		top: -16px;
		color: $theme04;
		font-size: 3.9rem;

		[class^='icon-'] {
			&:before {
				position: relative;
				z-index: 2;
			}

			&:after {
				content: '';
				position: absolute;
				z-index: 1;
				left: 22px;
				top: 19px;
				width: 1.3rem;
				height: 1.5rem;
				background: $color01;
			}
		}
	}
}

@media only screen and (min-width: $b2) {
	.label {
		&-star {
			right: -16px;
			top: -14px;
		}
	}
}

@media only screen and (min-width: $b3) {
	.label {
		&-star {
			right: -14px;
		}
	}
}

@media only screen and (min-width: $b4) {
	.label {
		&-star {
			right: auto;
			left: -10px;
			top: -7px;

			[class^='icon-'] {
				&:after {
					top: 13px;
				}
			}
		}
	}
}
@media only screen and (min-width: $b6) {
	.label {
		&-star {
			right: auto;
			left: -3px;
		}
	}
}
