/* 	CastleCSS core variable files */
@import '../../node_modules/castlecss-core/sass/variables';

/* 	Your own variables */
@import 'base/variables';

/* 	Rest of CastleCSS Core files */
@import '../../node_modules/castlecss-core/sass/main';

/* Other CastleCSS modules */
@import '../../node_modules/castlecss-buttons/sass/main';
@import '../../node_modules/castlecss-icons/sass/main';
@import '../../node_modules/castlecss-notifications/sass/main';
@import '../../node_modules/castlecss-forms/sass/main';

/* 	Include your own files below this line
	----------------------------------------------------------------------------
*/

/* Base */
@import 'base/mixins';
@import 'base/fonts';
@import 'base/defaults';
@import 'base/headings';
@import 'base/static-content';
@import 'base/icon-fonts';
@import 'base/animate';

//------ L A Y O U T ------//

//	-- Header
@import 'layout/header/header';
@import 'layout/header/header-search';
@import 'layout/header/header-basket';
@import 'layout/header/header-notifications';
@import 'layout/header/header-notifications-submenu';
@import 'layout/header/header-basket-notifications';
@import 'layout/header/mainnav';
@import 'layout/header/navbar';
// @import 'layout/header/langpicker';
@import 'layout/side/side';
@import 'layout/side/side-menu';
@import 'layout/side/logon-info';

//	-- Footer
@import 'layout/footer/footer';

// //-- C O M P O N E N T S --//
@import 'components/formfields';
@import 'components/labels';
@import 'components/swipable';
@import 'components/image-scroller';
@import 'components/buttons';
@import 'components/social';
// @import 'components/vcard';
@import 'components/prices';
@import 'components/notifications';
// @import 'components/banners';
// @import 'components/entrances';

// @import 'components/siteaction';
@import 'components/stickers';
@import 'components/fullpage-bg';
@import 'components/centered-view';
// @import 'components/comparebar';
@import 'components/external_content';
@import 'components/crumbs';
@import 'components/paging';
@import 'components/widget';
@import 'components/widget-contact';
@import 'components/widget-entrance';
@import 'components/widget-thankyou';
@import 'components/widget-image';
@import 'components/widget-half-half';
@import 'components/widget-image-withtext';
@import 'components/widget-order';
@import 'components/widget-notification';
@import 'components/widget-navigate';
@import 'components/widget-slider';
@import 'components/langpicker';
@import 'components/toggle-menu';
// @import 'components/rating';
@import 'components/tabs';
@import 'components/visual-with-text';
@import 'components/header-with-text';
@import 'components/block_products';
// @import 'components/combination';
// @import 'components/combi_list_item';
// @import 'components/visual_full_width';
// @import 'components/video';
// @import 'components/googlemaps';
@import 'components/faq';
@import 'components/block_rte';
@import 'components/featherlight-custom';
@import 'components/modal';
@import 'components/modal-quantity';
@import 'components/modal-basketconfig';
@import 'components/consumerview';
@import 'components/toggleswitch';
@import 'components/product-specs';
@import 'components/definition-list';
@import 'components/browser';
@import 'components/quickorder';
@import 'components/contact';
@import 'components/product-zoom';
@import 'components/shadow-element';
@import 'components/user-info';
@import 'components/action-menu';
@import 'components/favorite';
@import 'components/wishlist';
@import 'components/info';
@import 'components/__hacks';
@import 'components/salelabels';
@import 'components/linkblocks';

//------- P A G E S -------//

// 	//	-- Lister
@import 'pages/lister/filters';
@import 'pages/lister/lister-listoptions';
@import 'pages/lister/list';
@import 'pages/lister/list-tiles-view';
@import 'pages/lister/list-list-view';
@import 'pages/lister/category';
@import 'pages/lister/list-empty';
@import 'pages/lister/lister-loading';
@import 'pages/lister/lister-parent-item';
@import 'pages/lister/lister-bundle-item';
@import 'pages/lister/colorlist';
@import 'pages/lister/related-list';
@import 'pages/lister/tweakwise-list';
// 	@import 'pages/lister/locationlist';
// 	@import 'pages/lister/customerService';

// 	//	-- Productdetail
@import 'pages/productdetail/detail';

// 	//	-- Direct orders
@import 'directorders/list-directorders';
@import 'directorders/list-remarks';
@import 'directorders/directorders-popup';

// 	//	-- Packingslip
@import 'directorders/list-packingslip';

// 	//	-- Detail
// 	@import 'pages/detail/sizelist';
@import 'pages/detail/detail';
@import 'pages/detail/detail_images';
@import 'pages/detail/detail_zoom';
@import 'pages/detail/detail_bundles';
@import 'pages/detail/detail-tiles';
// 	@import 'pages/detail/detail_share';
// 	@import 'pages/detail/detail_specs';
// 	@import 'pages/detail/detail_reviews';

// 	// 	-- Service
// 	@import 'pages/service/service_sitemap';

// 	//	-- Basket
@import 'pages/basket/basket';
@import 'pages/basket/review';
@import 'pages/basket/basket-totals';
@import 'pages/basket/quickorder';
@import 'pages/basket/basket-alternatives';
// 	@import 'pages/basket/basket_progress';
// 	@import 'pages/basket/basket_coupon';
// 	@import 'pages/basket/basket_mini';
// 	@import 'pages/basket/productlist';

// 	-- Account
@import 'pages/account/account_login';
// 	@import 'pages/account/account_address';
@import 'pages/account/account_overview';
@import 'pages/account/account_history-heading';
@import 'pages/account/account_orderhistory';
@import 'pages/account/account_historydetail';
@import 'pages/account/account_users';
@import 'pages/account/account_user';
@import 'pages/account/account_roles';
@import 'pages/account/account-invoice';

// 	-- Spareparts
@import 'pages/spareparts/spareparts-form';

//  -- Redirect
@import 'pages/redirect';

// 	// 	-- News
// 	@import 'pages/news/newslist';
// 	@import 'pages/news/comment_list';

// 	// 	-- Vacancies
// 	@import 'pages/vacancies/vacancies';

// 	// 	-- Compare
// 	@import 'pages/compare/compare_table';

// //----- P L U G I N S -----//
// 	@import 'plugins/plugin_fancybox';
// 	@import 'plugins/plugin_flexslider';
// 	@import 'plugins/plugin_flexslider_custom';
@import 'plugins/plugin_jquery_ui';
// 	@import 'plugins/datepicker';
// 	@import 'plugins/slider';

//autocomplete styling van retouren is nu ook in de header van toepassing
@import 'plugins/autocomplete';

@import 'plugins/slick';
@import 'plugins/slick-theme';
// 	@import 'plugins/tooltip';
// 	@import 'plugins/featherlight';
// 	@import 'plugins/dnz_featherlight';

// -- S T Y L E G U I D E -- //
// @import 'plugins/shCoreDefault';
// @import 'base/styleguide';

/* Utility styles at the end to overwrite all other styles */
@import 'base/utility';

/*
----------------------------------------------------------------------------
Include your own files above this line
*/
@import '../../node_modules/castlecss-core/sass/base/utility';
@import '../../node_modules/castlecss-core/sass/base/utility_spacers';

// -- P R I N T -- //
@media print {
	@import 'base/print';
}

#launchbar {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: black;
	padding: 12px 0;
	z-index: 9999;

	& > div > div > span {
		color: #ffd400;
		font-size: 14px;
		font-weight: 600;
	}

	& > div > div > span#secondline {
		color: #fcfcfc;
	}
	& > div > div > a {
		color: #fcfcfc;
	}
	& > div > div > button {
		color: #c5c5c5;
	}
	& > div > div > button {
		background-color: transparent;
		border: transparent;
	}
	& > div > div > button > svg {
		width: 16px;
	}
	& > div > div {
		flex: 1;
	}
	& > div {
		display: flex;
		flex-direction: row;
		width: 100%;
	}
	& > div > #launchbarCloseContainer {
		justify-content: flex-end;
		display: flex;
		padding-right: 12px;
	}
	& > div > #LaunchbarTextContainer {
		padding-left: 68px;
	}
	@media (max-width: 767px) {
		display: none;
	}
}
