.rte {
	max-width: $max-content-width;

	&-header {
		margin-bottom: $margin-default;
	}

	ul,
	ol {
		padding-left: 1.7rem;

		> ol,
		> ul {
			margin-bottom: 0;
		}
	}
}
