.contact {
	&-wrap {
		max-width: $max-content-width;
	}

	.actions {
		> * {
			vertical-align: top;
		}
	}
}
