.product-detail {
	position: fixed;
	z-index: 560;
	top: $header-height*2;
	right: 0;
	padding: $header-height 0 0;
	background: $color01;
	width: 100%;
	height: 100%;
	transform: translateX(100%);
	transition: ease-in 0.2s transform;
	overflow-x: auto;


	&-container {

		&.active {
			content: '';
			position: fixed;
			top: $header-height*2;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 559;
			background: rgba($color02, 0.7);
			transition: opacity 400ms ease-in;

			&.full {
				top: 0;

				.product-detail {
					top: 0;
				}

				.list-items-config,
				.add-to-basket-block {
					display: none;
				}

			}

			&.half {
				top: $header-height;

				.product-detail {
					top: $header-height;
				}
			}


			.product-detail {
				transform: translateX(0);
			}
		}
	}

	> * {
		width: 100%;
	}




	.detail-close {
		cursor: pointer;
	}

	.product-img {
		padding: $padding-default;
	}

	.product-thumbs {
		.img {
			cursor: pointer;
		}
	}

	@media only screen and (min-width: $b4) {
		width: 75%;
	}

	@media only screen and (min-width: $b5) {
		width: 84%;
	}
	@media only screen and (min-width: $b5) {
		width: 42%;
	}
}
