/*========== full width visual ==========*/
.linkblocks {
	position: relative;
	width: 100%;
	padding: 0 $padding-default * 2 0;

	&-item {
		margin-bottom: $margin-default;

		&-wrap {
			display: flex;
    		flex-direction: column;
			padding: $padding-default;
			height: 100%;
			border: 1px solid $color04;
			border-radius: $radius;

			.head {
				display: flex;
				align-items: center;
				margin-bottom: $margin-default;

				img {
					margin-right: $margin-default;
					align-self: flex-start;
				}
			}

			.btn {
				margin-top: auto;
				align-self: flex-start;
			}
		}
	}
}

@media only screen and (min-width: $b3) {
	.linkblocks {
		@include centered-block(0 $padding-default * 3 0 $padding-default * 3, 70rem);

		&-item {
			margin-bottom: .8rem;
		}
	}
}

@media only screen and ( min-width: $b4) {
	.linkblocks {

		&-item {

			&-wrap {
				padding: $padding-default * 2;
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.linkblocks {
		@include centered-block(0 10rem 0 10rem, 90rem);

		&-item {
			margin-bottom: $margin-default * 2;
		}
	}
}

@media only screen and (min-width: $b6) {
	.linkblocks {
		@include centered-block(0 18rem 0 18rem, 116.4rem);
	}
}
