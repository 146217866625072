/*========== CRUMBS ==========*/

.crumbs {
	position: relative;
	z-index: 5;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: $padding-default;
	font-size: 1.3rem;
	height: $header-height;
	background: $color04;

	ol {
		@include ulreset();
		white-space: nowrap;
		overflow: hidden;

		a {
			display: inline-block;
			padding: $padding-default 0 $padding-default 0;
			color: $theme01;
			text-decoration: none;
		}
	}

	@include prevButton('.prev');

	li {
		position: relative;
		display: inline;

		&:hover {
			color: $theme03;
		}

		& + li {
			padding-left: 3.2rem;
		}

		&:last-child {
			a {
				text-decoration: none;
				cursor: text;
				pointer-events: none;
				color: $color03;
			}
		}

		&:before {
			content: '\203A';
			position: relative;
			top: 0;
			left: -16px;
			color: $theme01;
		}

		&:first-child:before {
			display: none;
			padding-right: 0;
		}
	}
}

@media only screen and (min-width: $b4) {
	.crumbs {
		z-index: 3;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: $padding-default * 2;
		width: 100%;
		height: $header-height;
		font-size: $font-size-default;

		ol {
			margin-right: $margin-default;
		}

		a {
			span:hover {
				text-decoration: underline;
			}
		}

		.prev {
			display: none;
			// position: relative;
			// color: $color06;
			// margin-right: $margin-default*2;

			// > * {
			// 	line-height: 1.5;

			// }

			// &:before {
			// 	top: 0;
			// }

			// &:after {
			// 	top: 0;
			// }
		}
	}
}

@media only screen and (max-width: $b4 - 1) {
	.is-home .crumbs {
		display: none;
	}

	ol {
		display: none;
	}
}
