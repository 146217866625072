.swipable {
}

.swipable-desktop {
	@extend .swipable;
	display: block;
	white-space: nowrap;
	font-size: 0;
	overflow-x: scroll;
	overflow-y: hidden;
	padding: 0 1.2rem;
	-webkit-overflow-scrolling: touch;

	> * {
		display: inline-block;
		float: none;
		-webkit-backface-visibility: hidden;
		white-space: normal;
		text-align: left;
		vertical-align: top;
		font-size: $font-size-default;
	}
}

@media only screen and (max-width: $b4 - 1) {
	.swipable-desktop {
	}
}

@media only screen and (max-width: $b4 - 1) {
	.swipable {
		display: block;
		white-space: nowrap;
		font-size: 0;
		overflow-x: scroll;
		overflow-y: hidden;
		padding: 0 1.2rem;
		text-align: center;
		-webkit-overflow-scrolling: touch;

		> * {
			display: inline-block;
			float: none;
			-webkit-backface-visibility: hidden;
			white-space: normal;
			text-align: left;
			vertical-align: top;
			font-size: $font-size-default;

			&:first-child {
				margin-left: -12px;
			}
		}
	}
}

// use max-with media query because horizontal scroll on mobile
@media only screen and (max-width: $b2 - 1) {
	.swipable {
		> * {
			margin-bottom: 0;
		}
	}
}
@media only screen and (max-width: $b3 - 1) {
	.swipable {
		> * {
			&:first-child {
				margin-left: 0;
				padding-left: $grid-gutter;
			}
			&:last-child {
				margin-right: $grid-gutter;
			}

			a,
			a img {
				display: block;
			}

			> a {
				padding: 15%;
			}
		}
	}
}
