.switch {
	position: relative;
	display: inline-block;
	width: 11.2rem;
	height: 4rem;

	&.small {
		width: 6rem;
		height: 2.5rem;

		.switch-slider {
			background-color: $negative-text-color;

			&:before {
				width: 2.8rem;
				height: 1.9rem;
				bottom: 0.29rem;
			}
		}

		.switch-slider {
			&:before {
				right: 0.3rem;
				font-size: 12px;
				line-height: 19px;
			}
		}
		input {
			&:checked + .switch-slider {
				background-color: $positive-text-color;

				&:before {
					transform: translateX(-2.6rem);
				}
			}
		}
	}

	input {
		display: none;

		&:checked + .switch-slider {
			background-color: $positive;

			&:before {
				content: attr(data-slider-positive);
				color: $positive-text-color;
			}
		}

		&:focus + .switch-slider {
			box-shadow: 0 0 1px $positive;
		}

		&:checked + .switch-slider:before {
			transform: translateX(-5rem);
		}
	}

	&-slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $negative;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: $radius;

		&:before {
			content: attr(data-slider-negative);
			position: absolute;
			right: 0.6rem;
			bottom: 0.6rem;
			height: 2.8rem;
			width: 4.8rem;
			border-radius: $radius;
			background-color: white;
			text-align: center;
			font-weight: $font-weight-bold;
			line-height: 28px; //do not use rem, breaks ie11 on windows 7
			transition: 0.4s;
			color: $negative-text-color;
		}
	}
}

.switch {
	&.disabled {
		opacity: 0.6;

		.switch-slider {
			cursor: not-allowed;
		}
	}
}