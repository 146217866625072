.detail-tiles {
	width: 100%;

	&.detail {
		position: fixed;
		top: $header-height * 2;
		right: 0;
		z-index: 550;
		padding-left: 0;
		padding-top: $header-height;
		width: 100%;
		height: 100%;
		transform: translateX(100vw);
		overflow-x: hidden;
		overflow-y: auto;
		transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
		will-change: transform;
		overflow: visible;
		overflow-x: hidden;

		.has-app & {
			top: $header-height;
		}

		> * {
			border-top: 1px solid $color04;
		}
	}

	.detail-open & {
		transform: translateX(0);
	}

	.detail-close {
		left: 0;
		margin-left: 12px;

		&:hover {
			cursor: pointer;
		}
	}

	&#side-right {
		.details-sec {
			&:before {
				content: '';
				position: absolute;
				top: 0;
				width: 100%;
				height: $header-height;
				margin: 0 auto;
				background: $color01;
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.detail-tiles {
		&.detail {
			width: 70%;
		}
	}
}
@media only screen and (min-width: $b5) {
	.detail-tiles {
		&.detail {
			width: 50%;
		}
	}
}
