%label {
	padding: 3px 12px;
	margin: 0px 5px 5px 0px;
	font-size: 1rem;
	font-weight: bold;
	border-radius: 20px;
	color: $color01;
	background-color: $theme01;
	text-transform: uppercase;
	text-decoration: none;
	white-space: nowrap;
	cursor: default;

	&.theme02 {
		background-color: $theme02;
	}

	&.theme04 {
		background-color: $theme04;
	}

	&.drgfocus {
		background-color: #6594aa;
	}

	&.groen {
		background-color: #08784c;
	}

	&.kbkleur {
		background-color: #bf5426;
	}

	&.donkerblauw {
		background-color: #1d3a58;
	}

	&:hover {
		color: $color01;
	}

	&.link {
		cursor: pointer;
	}
}

.salelabels {
	display: flex;
	flex-wrap: wrap;

	&:empty {
		display: none;
	}

	&-label {
		@extend %label;
	}

	// &-popup {
	// 	&-label {
	// 		@extend %label;
	// 	}
	// }
}

@media only screen and (min-width: $b5) {
	.salelabels {
		&:empty {
			display: flex; //extra margin bottom on b5
		}
	}
}
