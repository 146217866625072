.history-block {
	&-head {
		height: auto;

		.form-fields {
			label {
				padding-bottom: 0.6rem;
			}

			&.backorder {
				.select {
					max-width: 25rem;
				}
			}
		}
	}

	&-filters {
		padding: 0;
		height: auto;
		border: 1px solid $color01;
		border-top: none;

		.btn {
			min-height: 3.8rem;
			height: 3.8rem;
		}

		&-cancel {
			padding: $padding-default;
			min-height: 3.1rem;
			background: transparent;
			border: none;
			outline: none;
			opacity: 0;

			&:hover {
				color: $theme03;
			}

			&.active {
				opacity: 1;
			}
		}

		&-toggle {
			padding: $padding-default;
			position: relative;
			cursor: pointer;

			&:after {
				content: '\e801';
				@include kruitbosch-font();
				position: absolute;
				right: 0;
				top: 4px;
				width: 4rem;
				font-size: 2.5rem;
				line-height: 1.5;
			}

			&.open {
				+ .filters-wrap {
					display: block;
				}
			}

			+ .filters-wrap {
				padding: 0 $padding-default $padding-default $padding-default;
			}
		}

		.form-field {
			margin-bottom: $margin-default/2;
		}

		.has-value input[type='date'] {
			color: $color03;
		}
	}
}

.history-heading {
	&-wrap {
		padding: $padding-default/2 $padding-default;
		border-bottom: 1px solid $color04;
		background: $color05;
		color: $color06;
		line-height: 1;

		.orderby {
			position: relative;
			color: $color06;
			text-decoration: none;
			padding-right: 1.8rem;
			outline: none;

			&:before,
			&:after {
				position: absolute;
				right: -2px;
				bottom: 3px;
				@include kruitbosch-font();
				opacity: 0.6;
				margin: 0;
				font-size: 1.2rem;
			}

			&:before {
				content: '\f176';
				right: 4px;
				bottom: 4px;
			}

			&:after {
				content: '\f175';
			}

			&.desc {
				&:after {
					color: $theme02;
					opacity: 1;
				}
			}

			&.asc {
				&:before {
					color: $theme02;
					opacity: 1;
				}
			}
		}

		> * {
			word-wrap: break-word;
		}
	}
}

@-moz-document url-prefix() {
	.history-heading-wrap .orderby {
		display: table-caption;

		&:after {
			top: 2px;
		}
		&:before {
			top: 1px;
		}
	}
}

@media only screen and (min-width: $b3) {
	.history-block {
		&-filters {
			&-toggle {
				padding-left: $padding-default * 2;

				+ .filters-wrap {
					padding: $padding-default/2 $padding-default * 2 $padding-default $padding-default * 2;
				}
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.history-block-head {
		position: relative;
		height: $header-height;

		.form-fields {
			position: absolute;
			right: 12px;
			top: 50%;
			transform: translateY(-50%);
			width: 23rem;

			&.backorder {
				width: 48rem;
			}

			label {
				float: right;
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.history-block {
		&-filters {
			height: $header-height;

			&-cancel {
				padding: $padding-default 0.4rem;
			}

			&-toggle {
				+ .filters-wrap {
					padding: $padding-default;
				}
			}

			.actions {
				white-space: nowrap;
			}

			.form-field {
				margin-bottom: 0;

				label[for] {
					font-size: 1.1rem;
				}
			}
		}
	}

	.history-heading {
		position: relative;
		font-size: 1.3rem;

		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			width: 2.5rem;
			height: calc(100% - 1px);
			background: $color05;
			border-bottom: 1px solid $color04;
		}

		&-wrap {
			overflow-y: scroll;

			.invoice & {
				padding-left: $padding-default;
			}
		}

		&-artdescr {
			margin-left: -14.1666666667%;
		}
	}
}

@media only screen and (min-width: $b5) {
	.history-heading {
		font-size: $font-size-default;

		.icon-wrap {
			padding-left: 0.6rem;
		}
	}
}

@media only screen and (min-width: $b6) {
	.history-block {
		&-filters {
			&-cancel {
				padding: $padding-default;
			}

			.form-field {
				label[for] {
					font-size: $font-size-default;
				}
			}
		}
	}
}
