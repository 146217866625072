.widget {

	&-notification {

        display: flex;
		text-decoration: none;
		align-items: center;
        width: 100%;
        padding: 0.8rem $padding-default * 2;

        img {
            height: 4rem;
        }

		&-img {
            padding-right: $padding-default * 2;
            line-height: 10px;
            img {
                height: 4rem;
            }
		}

		&-descr {
			flex: 1;
            min-height: 4rem;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
        }
        
		.link-more {
			display: inline-block;
			margin-left: 2.4rem;

			&:before {
				top: 1px;
            }
		}

        &.bgcolor-color01 {
            .widget-notification-descr {
                color: $color03;
            }
            .link-more {
                color: $theme01;
                &:hover {
                    color: lighten($color03, 20%);
                }
            }
        }
        &.bgcolor-color05 {
            .widget-notification-descr {
                color: $color03;
            }
            .link-more {
                color: $theme01;
                &:hover {
                    color: lighten($color03, 20%);
                }
            }
        }
        &.bgcolor-theme01 {
            .widget-notification-descr, .link-more {
                color: $color01;
            }
            .link-more {
                &:hover {
                    color: $color07;
                }
            }
        }
        &.bgcolor-theme02 {
            .widget-notification-descr, .link-more {
                color: $color01;
            }
            .link-more {
                &:hover {
                    color: $color07;
                }
            }
        }
    }
}