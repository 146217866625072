%descr {
	position: absolute;
	bottom: 0;
	z-index: 3;
	padding: $padding-default * 2 $padding-default * 2 $padding-default * 2;
	font-size: 2rem;
	text-align: left;
	border-top-right-radius: $radius * 1.5;

	[class^='btn-'] {
		@include ellipsis();
		max-width: 100%;
	}
}

.widget {
	&-image {
		position: relative;
		overflow: hidden;
		font-size: 0;

		&-gradient {
			position: absolute;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			opacity: 0.72;
			background: transparent;
		}

		&.image-dark {
			.widget-image-gradient {
				background: linear-gradient(to top, rgba($theme02, 1), rgba($theme02, 0) 60%);
			}
		}

		&.image-light {
			.widget-image-gradient {
				background: linear-gradient(to top, rgba($color01, 1), rgba($color01, 0) 60%);
			}
		}
		img {
			position: relative;
			z-index: 0;
			width: 100%;
		}

		&-descr {
			@extend %descr;
			color: $color01;

			.large & {
				color: $color01;
				width: 100%;

				strong,
				p {
					display: block;
					margin-bottom: 0;
				}
			}
			.image-light & {
				color: $theme01;
			}
		}
	}
	&-btn {
		display: flex;
	}
}
