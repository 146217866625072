/*========== Mobile subnav menu ==========*/
@media only screen and (max-width: $b4 - 1) {
	.content-wrap {
		position: relative;
	}

	.toggle {
		&-selected {
			&-item {
				position: relative;
				z-index: 5;

				.has-app:not(.is-home) & {
					display: none;
				}

				&:after {
					content: '\e801';
					@include kruitbosch-font();
					position: absolute;
					right: 0;
					top: 4px;
					height: 4rem;
					width: 4rem;
					font-size: 2.5rem;
					line-height: 1.5;
				}

				&.open {
					&:after {
						content: '\e803';
					}

					~ div .toggle-menu {
						z-index: 4;
						transform: translateY(0);
						box-shadow: 0 19px 25px -26px rgba(0, 0, 0, 0.75);

						.has-iframe & {
							overflow-y: scroll;
							max-height: 32rem;
						}
					}
				}
			}
		}

		&-menu {
			position: absolute;
			top: $header-height;
			left: 0;
			z-index: 0;
			overflow: hidden;
			padding: 0;
			width: 100%;
			background: $color01;
			transform: translateY(-100%);
			transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
			will-change: transform;

			li {
				> strong,
				> span,
				a {
					display: block;
					margin: 0;
					padding: $padding-default;
					border-bottom: 1px solid $color04;

					&::before {
						display: none;
					}
				}

				ul {
					padding: 0;

					li {
						a {
							padding-left: $padding-default * 2;
						}

						ul a {
							padding-left: $padding-default * 3;
						}

						ul ul a {
							padding-left: $padding-default * 4;
						}
					}
				}
			}
		}
	}
}
