.history-detail {
	overflow: hidden;

	&-item {
		padding: $padding-default * 2 $padding-default;
		background: $color01;
		transition: all 0.2s ease-out;

		&:hover {
			background: $color01;
			color: $color03;
		}

		&-specs {
			display: none;
			padding: $padding-default 0;
		}
		.reference {
			padding: $padding-default;
		}

		&.open {
			.icon:before {
				content: '\e803';
			}
		}

		.icon {
			height: 2rem;
			font-size: 2rem;
		}
		label:after {
			content: ':';
			display: inline;
		}
		label + div {
			display: inline;
		}
	}

	&-artnr {
		word-wrap: break-word;
	}

	&-specswrap {
		padding: $padding-default 0 $padding-default $padding-default * 2;
		background: $color04;
	}

	&-label {
		color: $color06;
	}

	&-specs {
		padding: $padding-default * 2 $padding-default;
		border-bottom: 1px solid $color04;
		line-height: 2;
	}

	&-longdescr {
		padding-top: 0.7rem;
		line-height: 1.3;
	}

	&-total {
		font-weight: $font-weight-bold;
	}

	&-type {
		font-size: 2.3rem;
	}

	&-icon > * {
		color: $color08;
		font-size: 2rem;
		line-height: 1.5;
	}

	&-actions {
		margin-left: -4px;
	}

	&-planorder {
		position: relative;
		color: $color03;
		text-decoration: none;

		&:hover {
			color: $color03;
		}

		[class^='icon-'] {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&:before {
				color: $theme02;
				font-size: 2rem;
			}
		}

		.history-list & {
			font-weight: normal;
		}
	}
}

@media only screen and (min-width: $b3) {
	.history-detail {
		&-item {
			padding: $padding-default;
		}

		&-label {
			padding-left: $padding-default * 2;
		}

		&-longdescr {
			&.ref {
				padding-left: $padding-default * 2;
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.history-detail {
		&-longdescr {
			&.ref {
				padding-left: $grid-gutter-b4;
			}
		}

		&-planorder {
			[class^='icon-'] {
				top: calc(50% - 2px);
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.history-detail {
		&.scroll-b5 {
			overflow-y: scroll;
			height: calc(100% - 244px);
		}

		&-head {
			padding: $padding-default;
			border: 1px solid $color04;
			border-left: none;
			border-right: none;
			font-weight: $font-weight-extrabold;
			overflow-y: scroll;
			font-size: 1.4rem;
		}

		&-layer {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				width: 2.5rem;
				height: calc(100% - 2px);
				background: $color01;
				border: 1px solid $color04;
				border-left: none;
				border-right: none;
			}
		}

		&-total {
			font-size: 2rem;
		}

		&-descr {
			overflow: hidden;
			text-overflow: ellipsis;

			&.alt {
				overflow: visible;
			}

			&.icon-wrap {
				padding-left: 0.6rem;
			}

			.weeknr-container {
				position: relative;
			}
		}

		&-longdescr {
			&.ref {
				padding-left: $grid-gutter-b5;
			}
		}

		&-icon {
			line-height: 0.5;
		}

		&-actions {
			margin-left: -4px;
			margin-top: -0.6rem;
		}
	}
}
