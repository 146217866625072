/*========== DETAIL ZOOM ============= */

body.imageZoom {
	overflow: hidden;
}

#imageZoom {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 999999;
	width: 100%;
	height: 100%;
	background-color: $color01;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 0.4s ease-out;
	transition: opacity 0.4s ease-out;

	&.show {
		visibility: visible;
		opacity: 1;
	}

	img {
		display: block;
	}

	.imageZoom_image {
		position: absolute;
		top: 50%;
		left: 50%;
		display: none;
		-webkit-transform: translate3d(0, 0, 0);
	}

	.imageZoom_yt {
		height: 80px;
		width: 80px;
		background-color: $theme01;
		color: white;
		text-align: center;
		line-height: 80px;
		font-size: large;
		font-weight: bold;
		text-decoration: none;
	}
}

#imageZoom_controls {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 80px;
	overflow: hidden;
	z-index: 2;
	background-color: #f6f6f6;
	box-shadow: 2.5px 4.33px 7px 0px rgba(0, 0, 0, 0.3);
}

.imageZoom_btn {
	display: block;
	float: left;
	width: 80px;
	height: 80px;
	text-align: center;
	font-size: 3rem;
	line-height: 80px;

	&.disabled {
		opacity: 0.3;
	}

	&.imageZoom_close {
		float: right;
		color: $color01;
	}
}

.imageZoom_zoomIn,
.imageZoom_zoomOut {
	display: none;
	height: 80px;
	color: $theme01;
}

#imageZoom_controls .imageZoom_btn.imageZoom_close {
	background-color: $theme03;
}

#imageZoom_thumbs {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	background: $color01;
	-webkit-overflow-scrolling: touch;
	box-shadow: 2.5px -4.33px 7px 0px rgba(0, 0, 0, 0.3);

	a {
		border: 0;
	}

	ul {
		width: 20000px;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	li {
		width: 100px;
		float: left;
		margin-left: 20px;

		a {
			display: block;
		}

		&:first-child {
			margin-left: 0;
		}
	}

	img {
		width: 80px;
		margin: 0 10px;
		padding: 10px 0;
	}
}

#imageZoom_container {
	position: relative;
	height: 100%;
	width: 100%;
	padding: 80px 0;
	cursor: move;
	overflow: hidden;

	> div {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.imageZoom_image {
		-webkit-backface-visibility: hidden;
		width: 100%;
		-webkit-transform: translate3d(0, 0, 0);
	}
}

#imageZoom_controls a:hover,
#imageZoom_thumbs a:hover {
	opacity: 0.75;
}
.preload {
	position: fixed;
	z-index: 999;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: $color01 url(../images/loading.gif) no-repeat center center;
	text-indent: -9999rem;
	filter: alpha(opacity=80);
	-ms-filter: 'progid:dximagetransform.microsoft.alpha(Opacity=80)';
	opacity: 0.8;
}
.preload {
	position: absolute;
}
.preload {
	background-position: center 50%;
}
.preload {
	background-image: url(../images/loading.gif);
	background-repeat: no-repeat;
}

@media only screen and (min-width: $b2) {
	#imageZoom_controls {
		width: 80px;
		height: 100%;
		box-shadow: none;
	}

	.imageZoom_btn,
	.imageZoom_btn.imageZoom_close {
		float: none;
	}

	.imageZoom_zoomIn,
	.imageZoom_zoomOut {
		height: 80px;
	}

	#imageZoom_thumbs {
		top: 0;
		left: 0;
		width: 100px;
		height: 100%;
		padding: 0;
		box-shadow: 2.5px 4.33px 7px 0px rgba(0, 0, 0, 0.3);
		overflow-y: auto;
		overflow-x: hidden;

		li {
			float: none;
			margin-left: 0;
		}
	}

	#imageZoom_container {
		padding: 0 80px;
	}
}

@media only screen and (min-width: $b3) {
	#imageZoom_controls {
		width: 80px;
		height: 240px;
		background-color: transparent;
		box-shadow: none;
	}

	.imageZoom_btn,
	.imageZoom_btn.imageZoom_close {
		float: none;
	}

	.imageZoom_zoomIn,
	.imageZoom_zoomOut {
		height: 80px;
	}
	#imageZoom_controls {
		width: 100px;
		height: 100px;
		background-color: transparent;
		box-shadow: none;
	}
	.imageZoom_btn {
		width: 100px;
		height: 100px;
		line-height: 100px;
	}
	#imageZoom_thumbs {
		top: 0;
		right: 0;
		left: auto;
		padding-top: 100px;
		width: 100px;
		height: 100%;
		box-shadow: 2.5px 4.33px 7px 0px rgba(0, 0, 0, 0.3);
		overflow: visible;

		ul {
			width: auto;
			height: 100%;
			overflow: auto;
			overflow-x: hidden;
		}
	}

	#imageZoom_container {
		padding: 0 100px 0 0;
	}
}
