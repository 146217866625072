/*========== BASKET ==========*/

.basket {
	&-config {
		.modal-content {
			.advice-link {
				position: relative;
				padding-top: 0.9rem;

				.label-star {
					top: -7px;
					left: -17px;
				}

				.link-more {
					margin-left: 4.2rem;
				}
			}
		}
	}

	> div {
		.list-head {
			border-top: 1px solid $color04;
		}

		~ div .list-head {
			border-top: none;
		}
	}

	&-item {
		transition: all 0.2s ease-out;

		&-config,
		&-del {
			display: inline-block;
			width: 3.5rem;
			height: 3.5rem;
			vertical-align: top;

			> * {
				position: relative;
				display: block;
				color: $color08;
				border: 0;
				background: transparent;
				font-size: 2.5rem;
				text-align: center;
				text-decoration: none;
				outline: none;

				[class^='icon-']:before {
					display: block;
					margin: 0;
					width: 3.5rem;
					height: 3.5rem;
					line-height: 35px;
				}

				&:hover {
					color: $theme02;
				}
			}
		}

		&-del {
			> * {
				font-size: 2rem;
			}
		}

		&-config {
			> * {
				padding: 0;

				[class^='icon-'] {
					position: relative;
					top: 0;
				}
			}

			&.multi {
				> * {
					height: 100%;

					[class^='icon-'] {
						left: -0.1rem;
					}
				}

				[class^='icon-plus'] {
					position: relative;
					top: 10px;
					display: block;
					margin-left: 13px;
					width: 13px;
					height: 15px;
					border-radius: 2px;
					background: $color08;

					&:hover {
						background: $theme02;
					}

					&:before {
						font-size: 7.5px;
						width: 13px;
						height: 16px;
						line-height: 16px;
						color: $color01;
					}
				}
			}

			&.has-config {
				&.multi {
					[class^='icon-plus'] {
						background: $theme02;
					}
				}

				> * {
					display: block;
					color: $theme02;

					[class^='icon-'] {
						position: relative;
						z-index: 2;
					}

					.number {
						position: absolute;
						left: 18px;
						top: 3px;
						z-index: 1;
						margin-left: 3px;
						font-size: 10px;
						font-weight: $font-weight-bold;
						line-height: 16px;
						letter-spacing: 0px;
						//do not display for now, but maybe later?
						display: none;
					}
				}

				&.disabled {
					> * {
						[class^='icon-'] {
							color: $color08;
						}
					}
				}
			}
		}

		&-partial {
			position: absolute;
			left: 0.4rem;
			top: 0.4rem;

			.input-control input {
				z-index: 1;
			}
		}

		&-pict {
			font-size: 0;
		}

		&-alternative {
			background: $color04;

			&:hover {
				background: $color04;
			}
		}
		&-menutoggle {
			position: absolute;
			right: 0;
			top: 0;

			.icon-menu {
				&:before {
					font-style: normal;
					content: '\2807';
				}
			}
		}

		&-bundles {
			.product-bundle {
				display: block;
				color: $theme01;
				line-height: 1.2;
				font-weight: bold;
			}
		}
	}
	&-upload {
		position: absolute;
		right: $padding-default;
		width: auto;

		.has-app & {
			display: none;
		}

		&-link {
			display: inline-block;
			text-decoration: none;
			cursor: pointer;
			font-size: 12px;

			[class^='icon-'] {
				font-size: 2rem;
				vertical-align: middle;
			}
		}
	}
}

.express-delivery {
	position: relative;

	[class^='icon-'] {
		font-size: 2.8rem;
		color: $warning-text-color;
		vertical-align: middle;
		margin-left: -1rem;
	}
}

.reference-item {
	display: flex;
	flex-direction: column;

	.express-delivery {
		padding-left: 3.7rem;

		[class^='icon-'] {
			position: absolute;
			top: -7px;
			left: $padding-default;
		}
	}

	@media all and (max-width: $b4 - 1) {
		.basket-item-config {
			position: relative;
			left: 3rem;
		}
	}

	@media all and (max-width: $b3 - 1) {
		.basket-item:not(.basket-item-pdp) > *:first-child {
			width: 7rem;
		}

		.basket-item:not(.basket-item-pdp) > *:last-child {
			width: calc(100% - 7rem);
		}
	}
}

@media only screen and (min-width: $b2) {
	.basket-upload-link {
		font-size: 15px;
	}
}

@media only screen and (min-width: $b4) {
	.basket {
		&-item {
			position: relative;
			align-items: center;

			&-config,
			&-del {
				position: absolute;
				top: 1.3rem;
				right: 8rem;
				width: 3.2rem;
			}

			&-config {
				right: 3.8rem;

				.reference-item & {
					top: 50%;
					transform: translateY(-50%);
				}
			}

			&-partial {
				left: 1.5rem;
				top: 2.4rem;
			}

			&-alternative {
				border-right: 1px solid $color01;
			}

			&-bundles {
				.basket-item-title {
					@include ellipsis();
				}
			}

			input[type='number'] {
				padding: 0;

				+ .btn-submit {
					max-width: 3rem;

					> [class^='icon-cart'] {
						margin-left: -0.5rem;
					}
				}
			}

			.img-wrap {
				padding-top: 0;

				img {
					margin-left: 0.8rem;
				}
			}

			.list-item-message:not(.subtext) {
				width: 47.3%;
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.basket {
		&-item {
			.list-item-message:not(.subtext) {
				width: 54.6%;
			}
		}
	}
}

@media only screen and (min-width: $b6) {
	.basket {
		&-item {
			.img-wrap {
				text-align: center;

				img {
					margin-left: 0rem;
				}
			}

			.list-item-message:not(.subtext) {
				width: 47.4%;
			}
		}
	}
}
