//see \componentset\_sources\default\javascript\scripts.js isIE() method
.browser {
	&-info {
		display: none;
		position: fixed;
		width: 100%;
		min-height: $header-height;
		padding: $padding-default/2 40px;
		z-index: 100;
		top: 0;
		color: $warning-text-color;
		background: $warning;

		a {
			text-decoration: none;
		}

		&-warning.icon-horn {
			font-size: 3rem;
			position: absolute;
			top: -4px;
			left: 0;
		}

		&-close.icon-cancel {
			cursor: pointer;
			font-size: 1.5rem;
			position: absolute;
			top: 5px;
			right: $padding-default;
		}

		.browser-warning & {
			display: block;
		}
		.browser-warning-hide & {
			display: none;
		}
	}
}
