.product-lister {
	.detail-open.detail-tileview & {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			background: rgba($color02, 0.6);
		}
	}
}

.tiles-view .list {
	align-items: flex-start;
	padding-left: 12px;

	.lister-loader {
		margin: 0 auto;
	}

	&-item {
		position: relative;
		z-index: 1;
		padding: 0;
		border: 1px solid $color01;
		border-right-color: $color04;
		border-bottom-color: $color04;
		transition: 0.2s ease-in border;

		&:hover,
		&.selected {
			background: $color01;
			border: 1px solid $color03;

			small {
				color: $color06;
			}
		}

		&.parent {
			[class^='icon-'] {
				position: static;
			}

			&.selected {
				background: $color01;
				border: 1px solid $color03;
				color: $color03;

				small {
					color: $color06;
				}
			}

			.tiles-form-wrap {
				width: 100%;
				text-align: center;
			}

			&.open {
				position: relative;
				z-index: 2;
				background: $color01;
				border: 1px solid $color03;
				border-bottom: none;

				.container-children {
					display: block;

					.child {
						.tiles-form-wrap {
							width: 60%;
						}
					}
				}

				.list-item-availability {
					position: relative;

					&:before {
						content: '';
						position: absolute;
						left: 50%;
						bottom: -92px;
						z-index: 1;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 13px 10px 13px;
						border-color: transparent transparent $theme02 transparent;
						transform: translateX(-50%);
					}

					&:after {
						display: none;
					}
				}
			}

			.container-children {
				display: none;
				position: relative;
				left: 0;
				z-index: 2;
				padding: $padding-default $padding-default * 2 16px $padding-default * 2;
				width: 100vw;
				background: $theme02;
			}
		}

		&.child {
			display: block;
			margin-bottom: 0.8rem;
			background: $color01;

			.list-item-availability {
				&:before {
					display: none;
				}
			}
		}

		.tiles-img-wrap {
			padding-bottom: $padding-default;

			> * {
				display: block;
			}

			> .salelabels {
				position: absolute;
				display: flex;
				padding-right: 53px;
				margin-bottom: 0;
				max-height: 4.2rem;
				overflow: hidden;
			}
		}

		img {
			max-height: none;
			width: 100%;
		}

		.list-item-titlewrap {
			padding: 0 0 $padding-default 0;

			> .title {
				@include multiLineEllipsis(4.8rem, 2);
			}

			> .sku {
				@include ellipsis();
			}
		}

		.tiles-form-wrap {
			display: inline-block;
			width: 60%;
			min-height: 3rem;

			+ div {
				display: inline-block;
			}
		}

		.tiles-stock-wrap {
			min-height: 4.8rem;

			div {
				max-width: 100%;
				@include ellipsis();

				+ [class^='alert-'] {
					margin-top: 0.2rem;
				}
			}
		}

		.list-item-availability {
			> .check:after {
				display: none;
			}

			[class^='alert-'] {
				display: inline-block;
				padding: 0 0.6rem;
				width: auto;
				text-align: left;
				margin-bottom: 0;
			}
		}

		&-variant-head {
			padding: 0 0 $padding-default;
			color: $color01;
			background: transparent;
			border-bottom: none;

			&:before,
			&:after {
				display: none;
			}
		}
	}
}

@media only screen and (min-width: $b3) {
	.tiles-view .list {
		padding-left: 0.8rem;
	}
}

@media only screen and (min-width: $b4) {
	.tiles-view .list {
		padding-left: 8px;

		&-item {
			.list-item-titlewrap {
				> .title {
					@include multiLineEllipsis(3.8rem, 2);
				}
			}

			.no-img > * {
				height: auto;
			}

			&.child {
				.title {
					padding-right: 4.5rem;
				}
			}

			&.parent {
				&.open {
					.list-item-availability {
						&:before {
							bottom: -84px;
						}
					}
				}

				.tiles-form-wrap {
					width: 100%;
				}

				.container-children {
					width: 75vw;
				}
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.tiles-view .list {
		padding-left: 18px;

		&-item {
			&.parent {
				.container-children {
					width: 79vw;
				}

				.container-children {
					padding: $padding-default $padding-default * 2 $padding-default $padding-default * 2;
				}
			}

			&.child {
				margin-bottom: $margin-default;
			}
		}
	}
}

@media only screen and (min-width: $b6) {
	.tiles-view .list {
		&-item {
			&.parent {
				.container-children {
					width: 83vw;
				}
			}
		}
	}
}
