/*========== MIXINS ==========*/

@mixin kruitbosch-font() {
	font-family: 'icons-kruitbosch';
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: 0.2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: 0.2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin centered-block($padding: $padding-default, $width: 70rem) {
	position: relative;
	left: 50%;
	padding: $padding;
	transform: translateX(-50%);
	width: $width;
}

@mixin opacity($params) {
	filter: alpha(opacity=$params);
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$params)';
	opacity: $params * 0.01;
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

// text-overflow: ellipsis
@mixin ellipsis() {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin multiLineEllipsis($height: 7, $clamp: 4, $white-space: normal, $orient: vertical) {
	display: -webkit-box;
	height: $height;
	-webkit-line-clamp: $clamp;
	overflow: hidden;
	white-space: $white-space;
	text-overflow: ellipsis;
	/*! autoprefixer: off */
	-webkit-box-orient: $orient;
}

// ul reset
@mixin ulreset() {
	margin: 0;
	padding: 0;
	list-style: none;
}

// primary font: Roboto
// @mixin prim-font($weight:regular) {
// 	font-family: Roboto, Helvetica, Arial, sans-serif;

// 	@if $weight == regular {
// 		$weight: 400;
// 	}
// 	@else if $weight == medium {
// 		$weight: 500;
// 	}
// 	@else if $weight == bold {
// 		$weight: 700;
// 	} @else {
// 		$weight: 400;
// 	}

// 	font-weight: $weight;
// }
// border
// @function _border-build($width, $style, $color) {

// 	@if(not(unitless($width)) and $width == 0 ) {
// 		@return 0;
// 	}
// 	@else {
// 		@return $width $style $color;
// 	}
// }

// standard body font size
@mixin bodyFontSize() {
	font-size: 1.6rem;
}

// @mixin border( $width: 0 0 0 0, $style: solid solid solid solid, $color: black black black black ){
// 	$style-top: none;
// 	$style-right: none;
// 	$style-bottom: none;
// 	$style-left: none;

// 	$color-top: none;
// 	$color-right: none;
// 	$color-bottom: none;
// 	$color-left: none;

// 	@if length($style) == 1 {
// 		$style-top: $style;
// 		$style-right: $style;
// 		$style-bottom: $style;
// 		$style-left: $style;
// 	}
// 	@else if length($style) == 2 {
// 		$style-top: nth($style, 1);
// 		$style-right: nth($style, 2);
// 		$style-bottom: nth($style, 1);
// 		$style-left: nth($style, 2);
// 	}
// 	@else if length($style) == 3 {
// 		$style-top: nth($style, 1);
// 		$style-right: nth($style, 2);
// 		$style-bottom: nth($style, 3);
// 		$style-left: nth($style, 2);
// 	}
// 	@else if length($style) == 4 {
// 		$style-top: nth($style, 1);
// 		$style-right: nth($style, 2);
// 		$style-bottom: nth($style, 3);
// 		$style-left: nth($style, 4);
// 	}

// 	@if length($color) == 1 {
// 		$color-top: $color;
// 		$color-right: $color;
// 		$color-bottom: $color;
// 		$color-left: $color;
// 	}
// 	@else if length($color) == 2 {
// 		$color-top: nth($color, 1);
// 		$color-right: nth($color, 2);
// 		$color-bottom: nth($color, 1);
// 		$color-left: nth($color, 2);
// 	}
// 	@else if length($color) == 3 {
// 		$color-top: nth($color, 1);
// 		$color-right: nth($color, 2);
// 		$color-bottom: nth($color, 3);
// 		$color-left: nth($color, 2);
// 	}
// 	@else if length($color) == 4 {
// 		$color-top: nth($color, 1);
// 		$color-right: nth($color, 2);
// 		$color-bottom: nth($color, 3);
// 		$color-left: nth($color, 4);
// 	}

// 	@if length($width) == 1 {
// 		border: $width $style $color;
// 	}
// 	@else if length($width) >= 2 {
// 		$top: nth($width, 1);
// 		$right: nth($width, 2);
// 		$bottom: 0;
// 		$left: 0;

// 		@if length($width) == 2 {
// 			$bottom: nth($width, 1);
// 			$left: nth($width, 2);
// 		}
// 		@else if length($width) == 3 {
// 			$bottom: nth($width, 3);
// 			$left: nth($width, 2);
// 		}
// 		@else {
// 			$bottom: nth($width, 3);
// 			$left: nth($width, 4);
// 		}

// 		@if not(unitless($top)) {
// 			border-top: _border-build($top, $style-top, $color-top);
// 		}
// 		@if not(unitless($right)) {
// 			border-right: _border-build($right, $style-right, $color-right);
// 		}
// 		@if not(unitless($bottom)) {
// 			border-bottom: _border-build($bottom, $style-bottom, $color-bottom);
// 		}
// 		@if not(unitless($left)) {
// 			border-left: _border-build($left, $style-left, $color-left);
// 		}
// 	}
// }

@mixin prevButton($name) {
	#{$name} {
		position: relative;
		display: inline-block;
		padding-left: 2.8rem;
		text-decoration: none;

		&:before {
			content: '\2039';
			position: absolute;
			left: 3px;
			top: -2px;
			z-index: 2;
			color: $color01;
			font-size: 2.7rem;
			line-height: 1.6rem;
		}

		&:after {
			content: '\2039';
			position: absolute;
			left: 0;
			top: -2px;
			z-index: 1;
			display: inline-block;
			vertical-align: top;
			padding-left: 1rem;
			width: 1rem;
			height: 2rem;
			color: $color01;
			font-size: 2.7rem;
			line-height: 1.6rem;
			border-radius: 4px;
			background: $theme01;
		}
	}
}
