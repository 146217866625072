/*
	Default site settings
	Castle sets font-size to 62.5% so we can easily calculate with REM
	1REM = 10px (relative pixels)
*/

/* Webkit touch color */
* {
	-webkit-tap-highlight-color: rgba(191, 84, 38, 0.5);
}

form {
	width: 100%;
}

body {
	color: $color03;
	font-family: $font-pri;
	letter-spacing: $default-letterspacing;
	background-color: $color04;
	padding-top: $header-height * 2;

	&.has-app {
		// zou eigenlijk 50px moeten zijn voor breadcrumb maar voor nu zo gelaten
		// LET OP: als dit wel moet dan in volgende code in toggle-menu.scss aanpassen!!
		// .has-app:not(.is-home) & {
		//		display: none;
		// }
		padding-top: 0;
		//padding-top:50px;
	}
}

@media only screen and (min-width: $b4) {
	body {
		padding-top: $header-height * 2;
	}
}

@media only screen and (min-width: $b5) {
	html,
	body {
		height: 100%;
	}

	body {
		overflow: hidden;
		padding-top: 0;
	}
}

hr {
	display: block;
	margin: $margin-default 0;
	padding: 0;
	height: 1px;
	border: 0;
	border-top: 1px solid $color04;
}
abbr[title] {
	border-bottom: 1px dotted;
}
blockquote {
	margin: $margin-default $margin-default * 3;
}
address {
	font-style: normal;
}
dfn {
	font-style: italic;
}
mark {
	background: $alert;
	color: $color02;
}

select.hasSelect {
	opacity: 0;
	width: 100%;
	left: 0; /* necessary for centered text */
}

strong,
b {
	font-weight: bold;
}

// content margins
dl,
ol,
ul,
p,
pre,
table {
	margin-top: 0;
	margin-bottom: $margin-default * 2;
}

/* Table */
tr > * {
	padding: 0.5rem 1rem;
	vertical-align: top;
	text-align: left;
}

/* Links */
a {
	text-decoration: underline;
	color: $theme01;

	&:hover {
		text-decoration: none;
		color: $theme01b;
	}
}

/* Reset tel, sms and fax links to normal style and non-clickable elements */
.no-touch a[href^='tel'],
.no-touch a[href^='sms'],
.no-touch a[href^='fax'] {
	pointer-events: none;
	cursor: text;
}

// Remove [input type=number] spinners from Chrome and Firefox
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}
