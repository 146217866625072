/* This file contains hacks because the customer is not able to fix the project agreements  */

.detail .extra-product .product-img img,
.product-thumbs img,
.list .img-wrap img {
	padding: 0.4rem;
	background: $color01;
}

.detail .product-img img {
	padding: 1.2rem;
	background: $color01;
}

//temporary fix for PDP overlay to avoid the .detail css class
.product-detail .product-img img {
	padding: 1.2rem;
	background: $color01;
}

.basket-config picture img {
	padding: 1.2rem;
}
