.user-info {
	position: relative;
	margin-left: auto;
	white-space: nowrap;

	+ .consumerview {
		margin-left: 0;
	}

	&-nr {
		position: relative;
		z-index: 2;
		background: $color04;
		padding-right: $padding-default;
		cursor: pointer;

		&:hover {
			+ .user-info-name {
				transform: translateX(-100%);
			}
		}
	}

	&-name {
		position: absolute;
		top: 0;
		z-index: 1;
		background: $color04;
		padding-left: $padding-default;
		transform: translateX(100%);
		transition: ease 0.3s transform;
		transition-delay: 0.3s;
	}
}

@media only screen and (max-width: 1365px) {
	.user-info {
		display: none;

		+ .consumerview {
			margin-left: auto;
		}
	}
}
