/*========== full width visual ==========*/
.header-withtext {
	width: 100%;
	position: relative;

	picture img {
		width: 100%;
		display: block;
	}

	&-inner {
		padding: $padding-default * 2 $padding-default * 2 0;

		p {
			margin-bottom: 0;
		}
	}
}

@media only screen and (min-width: $b3) {
	.header-withtext {
		&-inner {
			max-width: 100%;
			z-index: 2;
			margin-top: -$padding-default * 4;
			background: $color01;
			@include centered-block(
				$padding-default * 4 $padding-default * 3 0 $padding-default * 3,
				70rem
			);
		}
	}
}

@media only screen and (min-width: $b5) {
	.header-withtext {
		&-inner {
			margin-top: -8rem;
			@include centered-block(8rem 10rem 0 10rem, 90rem);
		}
	}
}

@media only screen and (min-width: $b6) {
	.header-withtext {
		&-inner {
			margin-top: -10rem;
			@include centered-block(10rem 18rem 0 18rem, 116.4rem);
		}
	}
}
