.header {
	position: relative;
	//top: 0;
	z-index: 99;
	width: 100%;
	background: $theme01;
	height: $header-height;
}

.blue-bar {
	position: relative;
	//top: 0;
	z-index: 99;
	width: 100%;
	background: blue;
	height: $header-height;
}

.nav-label {
	display: none;
	padding: 3px 12px 0 7px;
	height: 4rem;
	//position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	z-index: 3;
	cursor: pointer;
	font-size: 1.2rem;
	letter-spacing: 1px;
	color: $color01;
	background-color: $theme01;
	border: 0;
	border-radius: $radius;
	box-shadow: none;

	&:before {
		content: '\2807';
		display: inline-block;
		margin-top: -5px;
		width: 1.2rem;
		vertical-align: middle;
		font-family: Arial;
		font-size: 2.4rem;
	}
}

.nav-label-alt {
	@extend .nav-label;
	padding: 0 12px 0 12px;
	text-decoration: none;
	line-height: 4rem;
	color: $color01;

	&:hover {
		color: $color01;
	}

	&:before {
		display: none;
	}
}
.blur {
	display: none;
	position: absolute;
	left: 0;
	top: 50px;
	z-index: 5;
	opacity: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
	transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
	transition-delay: 0.3s;
	will-change: opacity;

	.nav-open & {
		display: block;
		opacity: 1;
	}
}

.drg-logo {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	padding-left: 7px;
	padding-top: 3px;
	height: $header-height;
	background-image: linear-gradient(to right, rgba(255, 255, 255, 1), #bf5426);

	img {
		height: 32px;
	}
	span {
		color: $theme01;
		font-weight: $font-weight-extrabold;
		font-size: 8px;
		margin-top: 2px;
		text-transform: uppercase;
	}
}
.header-logo {
	a {
		text-decoration: none;
	}
}

@media only screen and (max-width: $b4 - 1) {
	.nav-label {
		display: block;
		margin: 1rem;
		height: 3rem;
	}

	.header {
		position: fixed;
		top: 0;
		left: 0;
		background: $theme01;
	}

	.header-nav-container {
		position: absolute;
		top: $header-height * 2;
		left: 0;
		width: 100%;
		height: 100vh;
		background: $theme01b;
		z-index: 550;
		transform: translateX(-100%);
		overflow-x: hidden;
		overflow-y: auto;
		transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
		will-change: transform;
		padding-bottom: 50px; // Compensate for the top-bar height

		.nav-open & {
			transform: translateX(0);
		}
	}

	.header-logo {
		height: $header-height;

		&:not(.drg) {
			a {
				position: relative;
				top: 50%;
				display: block;
				margin: 0;
				padding-left: 0.7rem;
				width: 13.5rem;
				transform: translateY(-50%);
			}
			img {
				display: inline-block;
				width: 3.1rem;
				vertical-align: middle;
			}
			span {
				display: inline-block;
				padding: 0.3rem 0.4rem 0 0.6rem;
				width: calc(100% - 3.6rem);
				font-size: 1.2rem;
				font-weight: $font-weight-extrabold;
				line-height: 1;
				color: $color01;
				vertical-align: middle;
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.header-sub-wrap {
		> .container {
			height: $header-height;
		}
	}

	.header-nav-container {
		width: 100%;
		position: relative;
		z-index: 9;
	}

	.header-logo:not(.drg) {
		a {
			position: absolute;
			top: 50%;
			left: 19px;
			@include translateY(-50%);
			font-size: 0;

			&:hover {
				text-decoration: none;
			}
		}

		span {
			display: inline-block;
			padding: 0.3rem 0 0 1.2rem;
			vertical-align: middle;
			font-size: 1.4rem;
			font-weight: $font-weight-extrabold;
			line-height: 1;
			color: $color01;
			max-width: 15rem;
		}

		img {
			display: inline-block;
			width: 3.6rem;
			vertical-align: middle;
		}
	}
	.drg-logo {
		padding-left: 20px;
	}
	#pendlrButton {
		user-select: none;
		pointer-events: none;
		&:hover {
			background-color: $theme01;
		}
		& > a {
			background-color: $color01;
			border-radius: 8px;
			padding: 0 12px 0 12px;
			font-size: 16px;
			color: $theme01b;
			height: auto;
			width: auto;
			margin: 0.6rem;
			pointer-events: all;
		}
		a:hover {
			background-color: $theme01b;
			color: $color01;
		}
	}
}
