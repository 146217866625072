.block-products {
}

@media only screen and (min-width: $b3) {
	.block-products {
		max-width: 100%;
		@include centered-block(0, 70rem);
	}
}

@media only screen and (min-width: $b5) {
	.block-products {
		@include centered-block(0, 90rem);

		&-text {
			padding: $padding-default * 4;
		}
	}
}

@media only screen and (min-width: $b6) {
	.block-products {
		@include centered-block(0, 116.4rem);
	}
}
