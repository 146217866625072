/*========== LISTER PRODUCTS ==========*/

/*
* Use .list-view .list .list-item for the OLD-school list with items / products
* Use .list-view2 .list .list-item for the NEW-school list with items / products
*/

.list {
	&-item {
		&.selected {
			.list-view & {
				background-color: $theme02;
				color: $color01;

				.prices {
					&.piece del {
						color: $color01;

						+ .price {
							color: $color01;
						}
					}
				}

				small {
					color: $color01;
				}
			}
		}

		&.selected {
			.list-view2 & {
				background: $theme02;
				color: $color01;

				small {
					color: $color01;
				}
			}
		}
	}
}

//Colors for new .list-view2 view
$color-list-view-border: #b6b6b6;

//default
$color-list-view-background-default: #c4c4c4;
$color-list-view-text-default: #fff;
//hover
$color-list-view-background-hover: #f5f5f5;
$color-list-view-text-hover: #000;
//selected
$color-list-view-background-selected: #8c8c8c;
$color-list-view-text-selected: #fff;

.list {
	&-item {
		&.parent {
			.list-view2 & {
				&.open {
					border-bottom: 1px solid $color-list-view-border;
				}
			}
		}
		&.child {
			.list-view2 & {
				border-left: 1px solid $color-list-view-border;
				border-right: 1px solid $color-list-view-border;
				border-bottom: 1px solid $color-list-view-border;
				border-top: 0px;

				&.last-child {
					border-bottom: 1px solid $color-list-view-border;
				}

				background-color: $color-list-view-background-default;
				color: $color-list-view-text-default;

				&:hover:not(.no-hover, .selected) {
					background-color: $color-list-view-background-hover;
					color: $color-list-view-text-hover;

					small {
						color: $color-list-view-text-hover;
					}

					.prices {
						&.piece del {
							color: $color-list-view-text-hover;

							+ .price {
								color: $theme01;
							}
						}
					}
				}

				.prices {
					&.piece del {
						color: $color-list-view-text-default;

						+ .price {
							color: $theme01;
						}
					}
				}

				small {
					color: $color-list-view-text-selected;
				}

				&.no-hover {
					cursor: default;
					background-color: $color01;
				}

				.list-item-variant-head {
					display: none;

					&:before,
					&:after {
						background-color: $color-list-view-background-default;
					}
				}

				min-height: 51px;

				.no-img {
					> * {
						height: 51px;
					}
				}
			}

			&.selected {
				.list-view2 & {
					background-color: $color-list-view-background-selected;
					color: $color-list-view-text-selected;

					.prices {
						&.piece del {
							color: $color-list-view-text-selected;

							+ .price {
								color: $theme01;
							}
						}
					}

					small {
						color: $color-list-view-text-selected;
					}

					.list-item-variant-head {
						display: none;

						&:before,
						&:after {
							background-color: $color-list-view-background-selected;
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $b3) {
	.list {
		&-item {
			&.child {
				.list-view2 & {
					height: 51px;
					min-height: 51px;
				}
			}

			&.child &-message {
				.list-view & {
					position: absolute;
					top: 4.4rem;
					line-height: 1.2;
					padding-left: 0;
				}
				.list-view2 & {
					position: absolute;
					top: 3.5rem;
					line-height: 1.2;
					padding-left: 0;
				}

				&:not(.subtext) {
					background: transparent;
				}
			}

			&.child.selected &-message {
				.list-view &,
				.list-view2 & {
					color: $color01;
				}
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.list {
		&-item {
			&.parent {
				.list-view2 & {
					&.open {
						.list-item-availability {
							&:before {
								border-color: transparent transparent $color-list-view-background-default
									transparent;
							}
							&:after {
								border-color: transparent transparent $color-list-view-background-default
									transparent;
							}
						}
					}
				}
			}

			&-message {
				.list-view & {
					position: absolute;
					top: 4.4rem;
					line-height: 1.2;
					padding-left: 0;

					:not(.subtext) & {
						background: transparent;
						.selected & {
							color: $color01;
						}
					}
				}
				.list-view2 & {
					position: absolute;
					top: 4.4rem;
					line-height: 1.2;
					padding-left: 0;

					:not(.subtext) & {
						background: transparent;
						.selected & {
							color: $color01;
						}
					}
				}
			}
		}
	}
}
