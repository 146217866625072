.account-overview {
	padding: $padding-default;
}

@media only screen and (min-width: $b2) {
	.account-overview {
		padding: $padding-default;

		&-grid.g {
			margin-left: -$padding-default;

			> * {
				display: flex;
				flex-direction: column; //eq height
				padding-left: $padding-default;

				> * {
					flex: 1 0 auto; //eq height
				}
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.account-overview {
		padding: 2rem;

		&-grid.g {
			margin-left: -2rem;

			> * {
				// 	display: flex; flex-direction: column; //eq height
				padding-left: 2rem;

				// 	> * {
				// 		flex: 1 0 auto; //eq height
				// 	}
			}
		}
	}
}

// @media only screen and ( min-width: $b5 ) {
// 	.account-overview {
// 		padding: $padding-default*4;

// 		&-grid.g {
// 			margin-left: -$padding-default*4;

// 			> * {
// 				padding-left: $padding-default*4;
// 			}
// 		}
// 	}
// }
