.header-notifications {
	&-submenu {
		display: none;
		position: fixed;
		z-index: 5;
		top: $header-height;
		left: 0;
		width: 100vw;
		padding-top: $padding-default;
		background: $color01;
		color: $color03;
		font-size: $font-size-default;
		box-shadow: 0px 6px 9px 0 rgba(0, 0, 0, 0.1);

		.title {
			display: flex;
			align-items: center;
			line-height: 1.3;

			[class^='icon-'] {
				margin-left: -10px;
				padding-right: 0;
				color: $color03;
				font-size: 2.7rem;
				line-height: 1.3;
			}

			.descr {
				line-height: 0.9;

				small {
					display: block;
					font-size: 1.2rem;
					color: $color06;
				}
			}
		}
	}

	&-account {
		padding: 0.4rem 1.7rem 0.6rem;

		.form-field {
			margin-bottom: 0;
		}
	}

	&-submenuitem {
		display: flex;
		flex-shrink: 0;
		padding: 4px $padding-default;
		align-items: center;
		text-decoration: none;
		color: $color03;

		&.rotate {
			&:before {
				margin: -8px 8px 0 0px;
				transform: rotate(-90deg);
			}
		}

		&:before {
			color: $theme01;
			font-size: 1.7rem;
			padding-right: 0.7rem;
			margin-top: -0.1rem;
		}

		.sticker-amount {
			position: static;
			padding: 0;
			margin-left: 0.6rem;
			min-width: 1.8rem;
			line-height: 18px;
			font-size: 1.2rem;
		}

		&[class*='mail'] {
			.sticker-amount {
				background: $theme01f;
			}
		}

		&[class*='favorites'] {
			&:before {
				font-weight: bold;
			}

			.sticker-amount {
				background: $theme01b;
			}
		}
	}
}

@media only screen and (min-width: $b2) {
	.header-notifications {
		&-submenu {
			right: 0;
			left: auto;
			width: 60vw;
		}
	}
}

@media only screen and (min-width: $b3) {
	.header-notifications {
		&-submenu {
			width: 40vw;
		}
	}
}

@media only screen and (min-width: $b4) {
	.header-notifications {
		&-submenu {
			width: 25vw;
		}
	}
}

@media only screen and (min-width: $b5) {
	.header-notifications {
		&-submenu {
			position: absolute;
			right: auto;
			left: 50%;
			width: 32rem;
			transform: translateX(-50%);
		}
	}
}
