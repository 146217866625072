.header-nav-bar {
	z-index: 100;
	position: absolute;
	right: 0;
	top: 0;
}
.header-service-nav {
	@include ulreset();
	text-align: right;

	li {
		display: block;
		margin-right: $margin-default;
		color: $color01;

		a {
			display: block;
			padding-top: 14px;
			height: 5rem; // same as header
			font-size: 1.2rem;
			font-weight: $font-weight-extrabold;
			color: $color01;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		&.alt {
			float: left;

			> a {
				color: $color02;
			}
		}
	}
}

@media only screen and (min-width: $b3) {
	.header-service-nav {
		li {
			display: inline-block;

			&.alt {
				float: none;

				> a {
					color: $color01;
				}
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.header-service-nav {
		li {
			margin-right: $margin-default * 2;

			a {
				height: $header-height; // same as header
				font-size: $font-size-default;
			}
		}
	}
}
