/*	HEADERS
*	Always try to use .head class + .head1-6 for SEO reasons
*	Only style on h1-h6 for user placable (free) content blocks.
*/

h1,
h2,
h3,
h4,
h5,
h6,
.head,
.head1,
.head2,
.head3,
.head4,
.head5,
.head6 {
	display: block;
	margin: 0 0 0.2rem;
	line-height: 1.5;
	font-family: $font-pri;
	font-size: 2rem;
	font-weight: $font-weight-extrabold;
}
.head-display {
	font-size: 3rem;
	margin-top: -12px;
	font-weight: bold;
}

h1,
.head1 {
	font-size: 2rem;
	margin-bottom: 0.2rem;
}
h2,
.head2 {
	font-size: 1.9rem;
	margin-bottom: 0.2rem;
}
h3,
.head3 {
	font-size: 1.8rem;
	margin-bottom: 0.2rem;
}
h4,
.head4 {
	font-size: 1.5rem;
	margin-bottom: 0.2rem;
}
h5,
.head5 {
	font-size: 1.5rem;
	margin-bottom: 0.2rem;
}
h6,
.head6 {
	font-size: 1.5rem;
	margin-bottom: 0.2rem;
}

.headXL1 {
	font-size: 2.5rem;
	margin-bottom: 1.2rem;
}
.headXL2 {
	font-size: 2.5rem;
	margin-bottom: 1.2rem;
}

@media only screen and (min-width: $b5) {
	.headXL1 {
		font-size: 3.5rem;
		letter-spacing: 3.5px;
		margin-bottom: 0.2rem;
	}
}

.head {
	a,
	a:hover {
		text-decoration: none;
	}

	+ .head {
		padding-top: 1.2rem;
	}
}

%block-head {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	padding: 0 $padding-default;
	height: $header-height;
	background: $color05;

	.head {
		padding-top: 0;
	}

	&-alt {
		background: $color05;
		padding: 26px $padding-default * 2;
	}
}

.block-head {
	@extend %block-head;

	> * {
		width: 100%;
		flex: 0 0 auto;
		margin-bottom: 0;
		line-height: 1.2;
	}
}

.block-head-small {
	@extend %block-head;

	> * {
		width: 80%;
		flex: 0 0 auto;
		margin-bottom: 0;
		line-height: 1.2;
	}
}

.block-head-withlink {
	@extend %block-head;
	justify-content: space-between;

	> * {
		margin-bottom: 0;
		line-height: 1.2;
	}
}

// .g > * {
// 	border: 1px solid red;
// }

@media only screen and (min-width: $b4) {
	.block-head-withlink,
	.block-head {
		padding: 0 $padding-default * 2;
	}
}

@media only screen and (min-width: $b5) {
	.block-head-withlink,
	.block-head {
		min-height: 6.1rem;
	}
}
// @media only screen and (min-width: $b3) {

// 	h1, .head1 	{ font-size: 3.0rem; margin-bottom: .2rem; font-weight: bold; }
// 	h2, .head2 	{ font-size: 2.6rem; margin-bottom: .3rem; }
// 	h3, .head3 	{ font-size: 2.4rem; margin-bottom: .2rem; }
// 	h4, .head4 	{ font-size: 2.2rem; margin-bottom: .2rem; }
// 	h5, .head5 	{ font-size: 1.8rem; margin-bottom: .4rem; }
// 	h6, .head6 	{ font-size: 1.6rem; margin-bottom: .4rem; }

// }
