//temporary fix for PDP overlay to avoid the .detail css class
.product-detail {
	[class^='alert-'] {
		margin-bottom: $margin-default;

		&.mb-0,
		&.no-margin {
			margin-bottom: 0;
		}
	}
}

.detail {
	[class^='alert-'] {
		margin-bottom: $margin-default;

		&.mb-0,
		&.no-margin {
			margin-bottom: 0;
		}
	}

	&-pri {
		padding: $padding-default * 2 $padding-default * 2 $padding-default * 3 $padding-default * 2;
		background: $color05;
		border: 1px solid $color01;
		height: 100%;

		.ean {
			margin-bottom: $margin-default;
		}

		.download {
			[class^='icon-'] {
				font-size: 2.5rem;
			}

			svg {
				height: 2.1rem;

				path {
					fill: $theme04;
				}
			}
		}

		.input-combined {
			.btn-submit {
				border-left: none;
			}
		}

		.btn-submit {
			background: $color01;
			font-size: 1.5rem;
			padding: 0 1.2rem;
			line-height: 4rem;
			height: 4rem;
			border: 1px solid $color04;
			color: $color03;

			&:hover {
				border: 1px solid $color04;
				background: $color01;
				color: $color03;
			}
		}

		.btn-config {
			position: relative;
			display: flex;
			align-items: center;
			padding: 0 0.8rem;
			outline: none;

			> [class^='icon-up'],
			> [class^='icon-down'] {
				width: 13px;
				font-size: 2rem;

				&:before {
					display: block;
					margin-left: -4px;
				}
			}

			> [class^='icon-note'] {
				font-size: 2rem;
				padding-top: 0.4rem;
				padding-bottom: 0.4rem;
			}

			&.open {
				&:before,
				&:after {
					content: '';
					position: absolute;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 11px 11px 11px;
					outline: none;
				}

				&:before {
					bottom: -19px;
					left: 10px;
					z-index: 2;
					border-color: transparent transparent $color01 transparent;
				}

				&:after {
					bottom: -18px;
					left: 10px;
					z-index: 1;
					border-color: transparent transparent $color04 transparent;
				}
			}
		}

		.list-items-combined {
			margin: 1.6rem 0;
			border: 1px solid $color04;

			&:after,
			&:before {
				display: none;
			}
		}
	}

	&-prices {
		a {
			text-decoration: none;

			&:hover {
				text-decoration: none;

				> span {
					text-decoration: none;
				}
			}
		}

		[class^='link-'] {
			display: block;

			&.active {
				> span {
					position: relative;
					text-decoration: none;

					&:before {
						content: '';
						position: absolute;
						top: 23px;
						left: 22px;
						z-index: 6;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 11px 11px 11px;
						border-color: transparent transparent $color01 transparent;
					}

					&:after {
						content: '';
						position: absolute;
						top: 22px;
						left: 22px;
						z-index: 4;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 11px 11px 11px;
						border-color: transparent transparent $color04 transparent;
					}
				}
			}
		}

		&-container {
			display: none;
			margin-top: 1rem;
			padding: $padding-default 0;
			width: 100%;
			background: $color01;
			border: 1px solid $color04;
			border-radius: $radius;

			&.discount {
				margin-top: -1.6rem;
			}

			&.active {
				display: block;
			}
		}

		&-item {
			padding: 0 $padding-default;

			&:last-child {
				border-bottom: 1px solid $color04;
			}

			.discount & {
				padding: $padding-default $padding-default/2;
			}

			&-piece {
				font-size: 1.1rem;
			}

			&:last-child {
				border-bottom: none;
			}
		}

		&-link {
			padding: $padding-default $padding-default/2;
		}

		&-descr {
			font-size: 1.1rem;
			font-style: italic;
		}
	}

	&-close {
		position: absolute;
		top: 0.5rem;
		left: 0.5rem;
		display: block;
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		line-height: 4rem;
		text-align: center;
		background: $color05;
	}

	&-config {
		position: relative;
		top: 4px;
		color: $theme03;
		font-size: 2.5rem;
		text-decoration: none;

		&:hover {
			color: $theme02b;
		}
	}

	&-btn {
		&-actions {
			display: flex;
			justify-content: flex-start;

			> * {
				margin-right: $padding-default;
			}
		}
	}

	&-backorder {
		&-quantity {
			display: flex;
			align-items: center;
			color: $theme01;

			> span {
				display: flex;
				align-items: center;
			}

			svg {
				margin: -3px $margin-default/2 0 0;
				width: 2rem;
				height: 2rem;
				color: $theme01;
			}
		}
	}
}

@media only screen and (min-width: $b2) {
	.detail {
		&-pri {
			.list-items-combined {
				&:after,
				&:before {
					left: 176px;
				}
			}
		}

		&-prices {
			[class^='link-'] {
				display: inline;
			}

			&-container {
				&.discount {
					margin-top: 1rem;
				}
			}

			&-item {
				.discount & {
					padding: $padding-default $padding-default;
				}
			}

			&-link {
				padding: $padding-default;
			}
		}
	}
}

@media only screen and (min-width: $b3) {
	.detail {
		&-pri {
			position: relative;
		}

		&-prices {
			&-container {
				left: initial;
				width: calc(100% - 48px);
			}
		}

		// no margin bottom needed if all items are on same line
		.extra-product {
			[class^='alert-'] {
				margin-bottom: 0;
			}
			.productlabels {
				[class^='alert-'] {
					margin-bottom: $margin-default;
				}
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.detail {
		&-prices {
			&-container {
				left: 12px;
				width: calc(100% - 24px);
			}

			&-item {
				.discount & {
					padding: $padding-default $padding-default/2;
				}
			}

			&-link {
				padding: $padding-default $padding-default/2;
			}

			&-links {
				display: flex;
				flex-wrap: wrap;
			}
		}

		.basket-item-config {
			right: $padding-default;
		}
	}
}

@media only screen and (min-width: $b6) {
	.detail {
		&-prices {
			&-container {
				left: initial;
				width: calc(100% - 48px);
			}

			&-item {
				.discount & {
					padding: $padding-default;
				}
			}

			&-link {
				padding: $padding-default;
			}
		}

		.basket-item-config {
			right: $padding-default * 2;
		}
	}
}

@media only screen and (max-width: $b5 - 1) {
	.detail {
		position: fixed;
		top: $header-height * 2;
		right: 0;
		z-index: 550;
		padding-left: 0;
		padding-top: $header-height;
		width: 100%;
		height: 100%;
		transform: translateX(100%);
		overflow-x: hidden;
		overflow-y: auto;
		transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
		will-change: transform;
		background: $color01;
		overflow: visible;
		overflow-x: hidden;

		.has-app & {
			top: $header-height;
		}

		&.detail-open,
		.detail-open & {
			transform: translateX(0);
		}

		> * {
			border-top: 1px solid $color04;
		}
	}
}

@media only screen and (min-width: $b4) and (max-width: $b5 - 1) {
	.detail {
		width: 75%;
	}
}

@media only screen and (max-width: $b4 - 1) {
	.detail {
		&-pri {
			.list-items-combined {
				.reference-item .basket-item-config {
					left: auto;
				}
			}
		}
	}
}
