@font-face {
	font-family: 'icons-kruitbosch';
	src: url('./fonts/icons-kruitbosch.eot?79254791');
	src: url('./fonts/icons-kruitbosch.eot?79254791#iefix') format('embedded-opentype'),
		url('./fonts/icons-kruitbosch.woff2?79254791') format('woff2'),
		url('./fonts/icons-kruitbosch.woff?79254791') format('woff'),
		url('./fonts/icons-kruitbosch.ttf?79254791') format('truetype'),
		url('./fonts/icons-kruitbosch.svg?79254791#icons-kruitbosch') format('svg');
	font-weight: normal;
	font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icons-kruitbosch';
    src: url('../font/icons-kruitbosch.svg?79254791#icons-kruitbosch') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
	font-family: 'icons-kruitbosch';
	font-style: normal;
	font-weight: normal;
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: 0.2em;
	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	margin-left: 0.2em;

	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-minus:before { content: '\e800'; } /* '' */
.icon-down-open:before { content: '\e801'; } /* '' */
.icon-right-open:before { content: '\e802'; } /* '' */
.icon-up-open:before { content: '\e803'; } /* '' */
.icon-left-open:before { content: '\e804'; } /* '' */
.icon-horn:before { content: '\e805'; } /* '' */
.icon-cancel:before { content: '\e806'; } /* '' */
.icon-cart:before { content: '\e807'; } /* '' */
.icon-person:before { content: '\e808'; } /* '' */
.icon-printer:before { content: '\e809'; } /* '' */
.icon-relevance:before { content: '\e80a'; } /* '' */
.icon-view:before { content: '\e80b'; } /* '' */
.icon-list:before { content: '\e80c'; } /* '' */
.icon-trashcan:before { content: '\e80d'; } /* '' */
.icon-cog:before { content: '\e80e'; } /* '' */
.icon-truck:before { content: '\e80f'; } /* '' */
.icon-kruitbosch-logo:before { content: '\e810'; } /* '' */
.icon-packagingunit:before { content: '\e811'; } /* '' */
.icon-edit:before { content: '\e812'; } /* '' */
.icon-doc-add:before { content: '\e813'; } /* '' */
.icon-eye:before { content: '\e814'; } /* '' */
.icon-note-filled:before { content: '\e815'; } /* '' */
.icon-plus:before { content: '\e816'; } /* '' */
.icon-note:before { content: '\e817'; } /* '' */
.icon-search:before { content: '\e818'; } /* '' */
.icon-claim:before { content: '\e819'; } /* '' */
.icon-calendar:before { content: '\e81a'; } /* '' */
.icon-back:before { content: '\e81b'; } /* '' */
.icon-check:before { content: '\e81c'; } /* '' */
.icon-replace:before { content: '\e81d'; } /* '' */
.icon-heart-empty:before { content: '\e81e'; } /* '' */
.icon-heart:before { content: '\e81f'; } /* '' */
.icon-cart-thin:before { content: '\e820'; } /* '' */
.icon-dropshipment-thin:before { content: '\e821'; } /* '' */
.icon-favorites-thin:before { content: '\e822'; } /* '' */
.icon-note-filled-thin:before { content: '\e823'; } /* '' */
.icon-note-thin:before { content: '\e824'; } /* '' */
.icon-danger:before { content: '\e825'; } /* '' */
.icon-alert:before { content: '\e826'; } /* '' */
.icon-info:before { content: '\e827'; } /* '' */
.icon-mail:before { content: '\e828'; } /* '' */
.icon-grid:before { content: '\e829'; } /* '' */
.icon-facebook:before { content: '\e82a'; } /* '' */
.icon-linkedin:before { content: '\e82b'; } /* '' */
.icon-twitter:before { content: '\e82c'; } /* '' */
.icon-youtube:before { content: '\e82d'; } /* '' */
.icon-list-thin:before { content: '\e82e'; } /* '' */
.icon-back-thin:before { content: '\e82f'; } /* '' */
.icon-box-thin:before { content: '\e830'; } /* '' */
.icon-mail-thin:before { content: '\e831'; } /* '' */
.icon-withstar:before { content: '\e832'; } /* '' */
.icon-chat-thin:before { content: '\e833'; } /* '' */
.icon-spin4:before { content: '\e834'; } /* '' */
.icon-handscanner:before { content: '\e835'; } /* '' */
.icon-alpina:before { content: '\e848'; } /* '' */
.icon-cortina:before { content: '\e849'; } /* '' */
.icon-down:before { content: '\f175'; } /* '' */
.icon-up:before { content: '\f176'; } /* '' */
.icon-bicycle:before { content: '\f206'; } /* '' */
.icon-vimeo:before { content: '\f306'; } /* '' */
.icon-gplus:before { content: '\f30f'; } /* '' */
.icon-pinterest:before { content: '\f312'; } /* '' */
.icon-paypal:before { content: '\f342'; } /* '' */

.icon-edit:before {
	padding-top: 1px;
} /* compensate for not beeing in center */
.icon-cart-thin {
	margin-left: -0.4rem;
} /* compensate for not beeing in center */
