.widget {
	&-slider {
		position: relative;
		overflow: hidden;
		font-size: 0;
		border: none;
		border-radius: $radius;
	}

	&-item {
		overflow: hidden;
		border: 1px solid $color04;
		border-radius: $radius;
	}
}
