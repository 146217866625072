/*========== AccountLogin ==========*/
.account-login {
	position: relative;
	padding: $padding-default * 4 $padding-default * 2;
	background: $color01;

	.comment {
		display: block;
		padding-top: $padding-default * 2;
	}

	.language-picker {
		position: absolute;
		top: 0.6rem;
		right: 0.6rem;
		width: 16.5rem;
	}
}

@media only screen and (min-width: $b2) {
	.account-login {
		.comment {
			display: inline-block;
			margin-left: $margin-default;
			padding-top: 0;
		}
	}
}

@media only screen and (min-width: $b3) {
	.account-login-img {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		overflow: hidden;

		img {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			min-width: 100%;
			max-width: none;
			min-height: 100%;
		}
	}

	.account-login {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 58rem;

		.language-picker {
			top: 1.2rem;
			right: $padding-default * 2;
		}
	}
}

// .passwordForm {
// 	display: none;
// }
// .registerLink {
// 	margin-top: $defaultMargin;
// }
