/*========== COLORLIST ==========*/
.color-list {
	margin: -0.8rem 0 0 -0.8rem;
	padding: 0;
	list-style: none;
	font-size: 0;

	.color {
		overflow: hidden;
		position: relative;
		display: inline-block;
		margin: 0.8rem 0 0 0.8rem;
		font-size: 1.6rem;
		border-radius: 50%;

		> * {
			display: block;
			width: 2.2rem;
			height: 2.2rem;
			text-align: left;
			text-indent: -9999px;
			border-radius: 50%;
			border: 5px solid $color01;
			box-shadow: none;
			background-clip: content-box;
			transition: all 0.1 ease-in-out;
		}

		a:hover {
			border: 2px solid transparent;
			box-shadow: inset 0 0 0 3px $color01;
			background-clip: border-box;
		}

		&.active {
			a {
				border: 2px solid transparent;
				box-shadow: inset 0 0 0 3px $color01;
				background-clip: border-box;
			}
		}

		&.disabled,
		&.disabled:hover {
			border-color: $color03;
			cursor: default;

			&:after {
				position: absolute;
				content: '';
				left: -10px;
				top: 50%;
				right: -10px;
				border-top: 1px solid $color03;
				transform: rotate(-45deg);
			}
		}
	}
}

// alt color-list module
.color-list-large {
	@extend .color-list;

	.color {
		// @extend .color;

		a {
			width: 40px;
			height: 40px;
			box-shadow: none;
		}
	}
}
/*========== /COLORLIST ==========*/
