/*========== HEADER notifications ==========*/

.header-notifications {
	display: flex;
	position: relative;
	justify-content: flex-end;

	> * {
		position: relative;
		height: $header-height;
		color: $color01;

		> a {
			padding: 0 10px;
			display: flex;
			align-items: center;
			text-decoration: none;
			font-size: 2rem;
			height: 100%;
		}

		i {
			padding-right: 5px;
			color: $color01;
			//icon font tweak
			&.icon-dropshipment-thin:before {
				min-width: 2em;
				margin-left: -0.5rem;
			}
		}

		&.person {
			> a {
				> [class^='icon-'] {
					font-size: 2.9rem;
				}

				> .sticker-amount {
					background: $theme01f;
					margin-left: -0.5rem;
				}
			}

			&.open {
				.header-notifications-submenu {
					display: block;
					//should be higher than .header-nav-container
					z-index: 551;
				}

				&:after {
					content: '';
					position: absolute;
					top: $header-height;
					left: -320px;
					width: 1000px;
					height: 500px;
				}

				> a:before {
					content: '';
					position: absolute;
					z-index: 1;
					bottom: 0px;
					left: 1.8rem;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 13px 10px 13px;
					border-color: transparent transparent $color01 transparent;
				}
			}
		}

		&.favorites {
			.sticker-amount {
				background: $theme01b;
			}
		}

		&.dropshipment {
			background: $theme04;
			.sticker-amount {
				background: $theme04a;
			}
		}

		&.cart {
			background: $theme02;
			.sticker-amount {
				background: $theme02c;
			}
		}
	}
}

@media only screen and (min-width: $b2) {
	.header-notifications {
		> * {
			&.person {
				&.open {
					&:after {
						left: -128px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $b3) {
	.header-notifications {
		> * {
			&.person {
				&.open {
					&:after {
						left: -147px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: $b5 - 1) {
	.header-notifications {
		position: absolute;
		right: 0;
		top: 0;

		> div:not(.header-basket-notifications) {
			max-width: $header-icon-width;
		}

		.sticker-amount {
			position: absolute;
			right: 0.7rem;
			top: 1rem;
			font-size: 1rem;
			padding: 0;
			min-width: 1.5rem;
		}
	}
}
