.directorders-popup {

	&-order {
		display: flex;
		align-items: flex-start;
		align-items: center;
		flex-wrap: wrap;
		background: $color01;

		[class^='icon-'] {
			flex: 0 0 25%;
			font-size: 32px;
			padding-left: 1.8rem;
		}
	}

	&-wrap {
		flex: 0 0 75%;
		padding: $padding-default 0;
	}
}

@media only screen and ( min-width: $b2) {
	.directorders-popup {

		&-order {


			[class^='icon-'] {
				flex: 0 0 9rem;
				padding-left: $padding-default*2;
			}
		}

		&-wrap {
			flex: 1 0 auto;
		}
	}
}

@media only screen and ( min-width: $b5) {

	.directorders-popup {

		&-date {
			flex: 1 0 auto;
			text-align: center;
		}

		&-wrap {
			display: flex;
			align-items: center;
		}
	}
}