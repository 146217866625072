.product {
	&-specs {
		&-alt {
			padding-bottom: $padding-default/2;
			margin-bottom: $margin-default * 2;
			border-bottom: 1px solid $color04;
		}

		&-item {
			margin-bottom: $margin-default/2;
		}
	}
}
