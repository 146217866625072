.external-content {
	display: flex;
	flex-direction: column;
	height: 100%;

	.iframe {
		.has-iframe & {
			flex: 1 0 auto;
			height: auto;
			.mac & {
				//mac desktop only fix
				height: calc(100% - 61px);
			}
		}
	}
}
