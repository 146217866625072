.user {
	.footer-actions {
		> * {
			display: block;
			margin-bottom: 0.6rem;
		}
	}
}

@media only screen and (min-width: $b3) {
	.user {
		.footer-actions {
			> * {
				display: inline-block;
				vertical-align: top;
				margin-bottom: 0;
			}
		}
	}
}
