.language-picker {
	position: relative;
	padding-right: 24px;
	margin-bottom: $margin-default * 2;
	border: 1px solid $color04;
	border-radius: $radius;
	cursor: pointer;

	img {
		display: inline;
		margin-right: 0.6rem;
	}

	&-selection {
		position: absolute;
		left: 0;
		top: 37px;
		z-index: 999;
		display: none;
		padding-left: 0;
		margin-bottom: 0;
		width: 100%;
		background-color: $color01;
		border: 1px solid $color04;
		border-top: none;
		border-bottom-left-radius: $radius;
		border-bottom-right-radius: $radius;
		text-align: left;
		list-style: none;
		vertical-align: bottom; //removes extra space on bottom
		@include ellipsis;

		li > * {
			display: block;
			padding: 6px 24px 6px 12px;
			color: $color03;
			text-decoration: none;

			&:hover {
				background: $color05;
			}
		}
	}

	&-selected {
		display: inline-block;
		padding: $padding-default/2 $padding-default;
		vertical-align: bottom; //removes extra space on bottom
		@include ellipsis;

		&:hover {
			//text-decoration: underline;
		}

		&:after {
			content: '\203A';
			display: block;
			position: absolute;
			right: 14px;
			top: 12px;
			transform: rotate(90deg);
			line-height: 1;
		}
	}

	&:hover {
		.language-picker-selection {
			display: block;
		}
		.language-picker-selected {
		}
	}
}
