.basket-alternatives {
	.list-items-header {
		cursor: default;
	}

	&.list-items {
		&-combined {
			border: none;

			&.active {
				&:after,
				&:before {
					display: none;
				}
			}
		}
	}

	&-item {
		//background: $color04;

		&:hover {
			background: $color05;
		}

		.list-items-combined & {
			cursor: pointer;
		}

		.basket-item-config {
			[class^='icon-'] {
				color: $theme02;
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.basket-alternatives {
		&.list-items {
			&-combined {
				margin: 0.6rem 1.2rem 1.1rem;
			}
		}

		&-item {
			.basket-item-config {
				left: 34px;
			}

			// .img-wrap {
			// 	margin-left: calc(8.33333% - 12px);

			// 	img {
			// 		border: 1px solid red;
			// 	}
			// }
		}
	}
}
