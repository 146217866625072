/*========== AccountLogin ==========*/
.centered {
	&-view {
		position: relative;
		padding: $padding-default * 4 $padding-default * 2;
		height: 100vh;
		background: $color01;

		.comment {
			display: block;
			padding-top: $padding-default * 2;
		}

		.language-picker {
			position: absolute;
			top: 0.6rem;
			right: 0.6rem;
			width: 16.5rem;
		}
	}
}

@media only screen and (min-width: $b2) {
	.centered {
		&-view {
			.comment {
				display: inline-block;
				margin-left: $margin-default;
				padding-top: 0;
			}
		}
	}
}

@media only screen and (min-width: $b3) {
	.centered {
		&-view {
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 58rem;
			height: auto;

			&.error {
				width: 70rem;
				padding: $padding-default * 4;
			}

			.language-picker {
				top: 1.2rem;
				right: $padding-default * 2;
			}
		}
	}
}
