.list-remarks {
	max-height: 134px;
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease-out;

	&.remarks-open {
		margin-bottom: $margin-default * 4;
		max-height: none;
		overflow: visible;

		.read-more {
			background-image: none;

			.btn {
				bottom: -2.8rem;
			}
		}
	}

	.list-item {
		border-bottom: none;
		min-height: auto;

		// > div {
		// 	&:first-child {
		// 		strong,
		// 		span {
		// 			padding-left: $padding-default;
		// 		}
		// 	}
		// }


		strong {
			display: block;
			padding: .8rem $padding-default 0 $padding-default;
		}

		span {
			display: block;
			padding: 0 $padding-default .8rem $padding-default;

		}

		&:nth-child(even) {
			background-color: $color04;
		}
	}

	.read-more {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		margin: 0;
		padding: 84px 18px 0;
		background-image: linear-gradient(to bottom, transparent, $color01);

		.btn {
			position: absolute;
			bottom: -2px;
		}
	}
}


@media only screen and ( min-width: $b4) {
	.list-remarks {

		.list-item {

			> div {
				&:first-child {
					strong,
					span {
						padding-left: $padding-default;
					}
				}
			}

			strong,
			span {
				display: inline-block;
				padding: .8rem 0 .8rem $padding-default;

			}

			.title {
				display: none;
			}
		}

		.read-more {
			padding: 84px 30px 0;
		}
	}
}