/*========== HEADER BASKET ==========*/

.header-basket,
.header-basket [data-react-id] {
	display: flex;
	position: relative;
	flex-wrap: wrap;

	> * {
		font-size: 3.8rem;
		position: relative;
		color: $color01;
		text-decoration: none;

		&.cart {
			flex: 1;
			background: $theme02;
			width: calc(100% - #{$header-icon-width});
		}

		&.favorites {
			color: $color01;
			width: $header-icon-width;
			font-size: 2.4rem;
		}
	}
}

.header-basket [data-react-id] [class^='sticker-'] {
	position: absolute;
	right: auto;
	top: 6px;
	left: 50%;
	background: $theme02;
}

@media only screen and (min-width: $b4) {
	.header-basket {
		> * {
			height: $header-height;

			[class^='icon-'] {
				position: absolute;
				top: 50%;
				left: 50%;
				line-height: 1;
				transform: translate(-50%, -50%);
				margin-top: 0.4rem;
			}
		}

		[class^='sticker-'] {
			position: absolute;
			right: 6px;
			top: 6px;
		}
	}
}

@media only screen and (min-width: $b5) {
	.header-basket {
		[class^='sticker-'] {
			position: absolute;
			top: -1px;
			right: -51px;
		}

		> * {
			[class^='icon-'] {
				position: absolute;
				top: 50%;
				left: 8px;
				transform: translateY(-50%);
				margin-top: 0;
			}

			&.favorites {
				font-size: 2.7rem;
				i {
					left: 0;
				}
			}
		}

		&-content {
			position: absolute;
			top: 50%;
			display: inline-block;
			padding-left: 6.1rem;
			font-size: 1.4rem;
			transform: translateY(-50%);
		}
	}
}

@media only screen and (min-width: $b7) {
	.header-basket {
		> * {
			font-size: 4rem;
		}
		[class^='icon-'] {
			left: 30px;
		}

		&-content {
			padding-left: 6.4rem;
		}
	}
}

@media only screen and (max-width: $b4 - 1) {
	.header-basket {
		position: absolute;
		top: 5px;
		right: 5px;
		z-index: 3;
		height: 40px;
		min-width: $header-icon-width * 2;
		border-radius: $radius;

		> * {
			font-size: 2.6rem;
		}

		[class^='icon-'] {
			display: block;
			width: auto;
			margin-top: 0.9rem;
			line-height: 1;
			color: #fff;
		}

		&-content {
			[class^='sticker-'] {
				position: absolute;
				top: -3px;
				right: -3px;
				background: $color01;
				color: $theme01;
			}
		}
	}
}
