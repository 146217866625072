/* FOOTER */
.footer {
	display: none;
	position: relative;
	z-index: 1;
	background: $theme01;
	color: $color01;
	box-shadow: 0 0 5px rgba(63, 56, 45, 0.1);
}

.footer .links-more a,
.footer .link-more span,
.footer .head a {
	color: $color01;

	&:hover {
		color: $theme03;
	}
}

.footer-container {
	padding: $padding-default * 2 $padding-default;
}

.sub-footer {
	padding: $padding-default;
	text-align: center;
	background: $theme02;
	color: $theme01;
	font-size: 1.4rem;
}

@media only screen and (min-width: $b2) {
	.footer-container {
		padding-left: $padding-default * 2;
		padding-right: $padding-default * 2;
	}
}

@media only screen and (min-width: $b3) {
	.footer-container {
		padding: 7rem 0;
	}
}
