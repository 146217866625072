.shadow-element {
	display: block;
	background: $color04;
	border-radius: $radius/2;

	&.threequarter {
		width: 75%;
	}

	&.half {
		width: 50%;
	}

	&.third {
		width: 33%;
	}

	&.quarter {
		width: 25%;
	}

	&.fifth {
		width: 20%;
	}

	&.tenth {
		width: 10%;
	}

	&.narrow {
		line-height: 1;
	}

	&.vertical {
		vertical-align: middle;
	}

	&.checkbox {
		width: 2rem;
		height: 2rem;
		border-radius: $radius;
	}

	&.input {
		line-height: 2;
		border-radius: $radius;
	}

	&.select {
		line-height: 2.5;
		border-radius: $radius;
	}

	&.prod-img {
		min-height: 25rem;
	}

	&.list-img {
		min-height: 7.7rem;
	}

	&.thumb {
		width: 7.2rem;
		height: 7.2rem;
	}

	&.filter {
		height: 13.2rem;
	}

	&.head {
		margin-left: $margin-default;
	}

	&.ml-0 {
		margin-left: 0;
	}

	&.right {
		float: right;
	}

	.list-item.selected & {
		background: $color01;
	}

	.tiles-view .list-item.selected & {
		background: $color04;
	}
}

@media only screen and (min-width: $b2) {
	.shadow-element {
		&.list-img {
			min-height: 11.7rem;
		}
	}
}

@media only screen and (min-width: $b3) {
	.shadow-element {
		&.list-img {
			min-height: 18.9rem;
		}
	}
}

@media only screen and (min-width: $b4) {
	.shadow-element {
		&.list-img {
			min-height: 5.5rem;
		}
	}
}

@media only screen and (min-width: $b6) {
	.shadow-element {
		&.list-img {
			min-height: 5.8rem;
		}
	}
}
