.side {
	&-menu {
		padding: $padding-default $padding-default 0;
		overflow: hidden;
		transition: ease 0.3s all;

		li span {
			display: block;
			margin-top: $margin-default/2;
		}

		li a span {
			margin-top: 0;
		}

		.product-lister & {
			max-height: 14.9rem;
			padding: $padding-default $padding-default * 2 0;
		}

		&-list {
			a {
				display: inline-block;
				line-height: 1.3;
				text-decoration: none;
				vertical-align: text-top;

				&.selected {
					font-weight: $font-weight-bold;

					> em {
						font-weight: $font-weight-normal;
					}
				}
			}

			li {
				line-height: 1.9;

				> ul {
					position: relative;
					padding-left: $padding-default;
					border-left: 1px solid $color04;

					&:after,
					&:before {
						content: '';
						position: absolute;
						top: -2px;
						left: -3px;
						width: 4px;
						height: 8px;
						background: $color01;
					}

					&:after {
						top: auto;
						bottom: -2px;
					}
				}
			}
		}
	}

	&-wrap {
		&-menu {
			li {
				a {
					line-height: 1.4;
				}

				&.closed {
					ul {
						display: none;
					}
				}
			}

			span {
				&.no-link:only-child {
					display: none;
				}
			}
		}

		&-item {
			&-single {

				&.sale {
					span {
						position: relative;
						font-weight: $font-weight-bold;
						text-transform: uppercase;

						[class^='fa'] {
							position: absolute;
							right: -11px;
							top: -2px;
							font-size: 10px;
						}
					}
				}

				&.counter-active {
					position: relative;

					> a {
						padding-right: 4.8rem;
    					padding-left: 10px;
						margin-left: 0;
						width: 100%;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;

						&:before {
							left: 0;
						}
					}


					&:hover {
						span {
							text-decoration: none;
						}
					}

					.sticker-amount {
					    position: absolute;
						right: 12px;
						top: 50%;
						transform: translateY(-50%);
					}

					@media only screen and ( min-width: $b4) {
						> a {
							padding-right: 1.2rem;
						}

						.sticker-amount {
							top: auto;
							right: -12px;
							transform:none;
						}
					}
				}
			}
		}

		&-toggle {
			position: relative;
			display: block;
			cursor: pointer;
			padding: $padding-default 0;
			user-select: none;

			[class^='icon-'] {
				position: absolute;
				top: 50%;
				right: $padding-default/2;
				width: 2.5rem;
				height: 2.5rem;
				line-height: 2.5rem;
				font-size: 2.3rem;
				transform: translateY(-50%);

				&:before {
					.closed & {
						content: '\e801';
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.side {
		&-menu {
			&-list {
				.side-menu-list-toggle + ul {
					padding-left: 1.9rem;
				}

				&-link {
					padding-left: 1.9rem;
				}

				&-current {
					&.selected {
						a {
							font-weight: bold;
						}
					}

					a {
						position: relative;
						padding-right: 1.9rem;
						font-size: 1.4rem;
						text-transform: lowercase;
						font-style: italic;

						&:after {
							content: '\E802';
							position: absolute;
							right: 0;
							bottom: 0;
							@include kruitbosch-font();
							line-height: 1.2;
							vertical-align: middle;
						}
					}

					.side-menu-list & {
						line-height: 1.4;
						margin-top: 0.4rem;
						margin-bottom: 0.4rem;

						a {
							display: inline;
						}
					}
				}

				&-toggle {
					position: relative;
					padding-left: 1.9rem;

					&:before {
						content: '\E803';
						position: absolute;
						left: -9px;
						top: -2px;
						@include kruitbosch-font();
						font-size: 2rem;
					}

					&.open {
						&:before {
							content: '\E801';
						}
					}
				}
			}
		}

		&-wrap {
			&-menu {
				li {
					padding: 0 $padding-default * 2;

					ul {
						padding: 0;

						> li {
							padding: 0 $padding-default 0 0;

							> ul li {
								padding: 0 $padding-default;
							}
						}
					}
				}
			}

			&-toggle {
				padding: $padding-default 0 $padding-default/2;

				.closed & {
					padding: $padding-default 0;
				}

				+ ul > li:last-child {
					padding-bottom: $padding-default;
				}

				[class^='icon-'] {
					right: -2rem;
				}
			}

			&-item {
				border-top: 1px solid $color04;
				border-bottom: 1px solid $color04;

				&:first-child {
					border-top: none;
				}

				+ .side-wrap-item {
					border-top: none;
				}

				&:last-child:not(.closed) {
					border-bottom: none;
				}

				+ .side-wrap-item-single {
					margin-top: $margin-default;
				}

				&-single {
					+ .side-wrap-item {
						margin-top: $margin-default;
					}
				}
			}
		}
	}

	@-moz-document url-prefix() {
		.side {
			&-menu {
				&-list {
					&-current {
						a {
							&:after {
								position: relative;
							}
						}
					}
				}
			}
		}
	}
}
