@media only screen and (min-width: $b4) {
	.main-nav {
		margin-right: $margin-default/2;
		> * > ul {
			display: flex;
			&:hover {
				> * {
					&:before {
						display: none;
					}
				}
			}
		}

		&-close {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			width: 4rem;
			height: 4rem;
			border-radius: 50%;
			line-height: 4rem;
			text-align: center;
			background: $color05;
			cursor: pointer;
		}

		/* not so nice css but much better for caching, so leave as is if you do'nt know what you'r dooing */
		&.bikes {
			.main-nav-item.bikes {
				&:before {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		&.parts {
			.main-nav-item.parts {
				&:before {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		/* END not so nice css  */

		&-item {
			z-index: 1;
			display: table-cell;
			display: flex;
			vertical-align: middle;
			flex: 1 auto;
			text-align: center;
			font-size: 1.5rem;
			font-weight: $font-weight-extrabold;
			text-transform: uppercase;
			cursor: pointer;

			&::before {
				content: '';
				opacity: 0;
				visibility: hidden;
				position: absolute;
				bottom: 0;
				left: 50%;
				z-index: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 13px 10px 13px;
				border-color: transparent transparent $color05 transparent;
				transform: translateX(-50%);
				transition: ease 0s all;
				transition-delay: 0s;
			}

			&:after {
				bottom: -1px;
				position: absolute;
				z-index: 9;
			}

			&.bikes {
				&:hover {
					&:before,
					&:after {
						border-color: transparent transparent $color01 transparent;
					}
				}
			}

			> a {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 0;
				width: 100%;
				border: solid transparent;
				border-width: 0 1px;
				color: $color01;
				height: $header-height;
				text-align: center;
				text-decoration: none;
				font-size: 1.5rem;
			}
			&:hover {
				background: #6a2711;
			}
			&.isopen {
				.blur {
					opacity: 1;
					display: block;
				}
				&:before,
				&:after {
					opacity: 1;
					visibility: visible;
				}

				> * {
					opacity: 1;
					visibility: visible;
					pointer-events: initial;
				}
			}
		}
		&-sub {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			left: -50px;
			z-index: 6;
			top: $header-height;
			background-color: $color04;
			text-align: left;
			transition: ease 0s all;
			transition-delay: 0s;
			pointer-events: none;
			text-transform: none;
			border: 1px solid $color05;
			width: 90%;
			min-height: 630px;
			overflow: hidden;

			&.js-hide {
				opacity: 0;
				visibility: hidden;
				position: fixed;
			}

			a {
				position: relative;
				display: block;
				padding: 0.5rem 1.2rem;
				text-decoration: none;

				[class^='icon-'] {
					position: absolute;
					right: 6px;
					top: 50%;
					transform: translateY(-50%);
					font-size: 120%;
				}

				// Transparent element to make hover easier
				&:after {
					position: absolute;
					display: none;
					left: calc(320px - 5px);
					top: 0;
					content: '';
					background: transparent;
					width: 10px;
					height: 100%;
				}
			}

			&-level1 {
				background: $color01;
				width: 320px;
				padding: 0.6rem 0;
				font-size: 1.3rem;
				font-weight: normal;
				background: $color01;
				min-height: 630px;
				border: 1px solid $color05;

				a {
					color: $color03;
					text-decoration: none;

					&:hover {
						text-decoration: none;
						color: $color09;
					}
				}

				&-item {
					width: 100%;

					&.parts {
						> a {
							display: flex;
							align-items: center;
							padding: 0.6rem 7.5rem 0.6rem 1.2rem;
							min-height: 7.3rem;
							line-height: 1.3;
							color: $color01;
							background: $color07 url('../images/nav-parts-bg.png') no-repeat right 0;
						}

						&:hover {
							> a {
								background: $color07 url('../images/nav-parts-bg.png') no-repeat right 0;
								text-decoration: underline;
							}
						}
					}

					> a {
						position: relative;
						display: block;
						padding: 0.6rem 1.2rem;
						text-decoration: none;

						[class^='icon-'] {
							position: absolute;
							right: 6px;
							top: 50%;
							transform: translateY(-50%);
						}

						// Transparent element to make hover easier
						&:after {
							position: absolute;
							display: none;
							left: calc(320px - 5px);
							top: 0;
							content: '';
							background: transparent;
							width: 10px;
							height: 100%;
						}
					}

					&:hover {
						> a {
							background: $color05;
							text-decoration: none;

							&:after {
								display: block;
							}
						}
					}
					> .main-nav-sub-level2 {
						opacity: 0;
						visibility: hidden;
						pointer-events: none;
						transition: ease 0s all;
						transition-delay: 0s;
					}

					&.hover {
						> .main-nav-sub-level2 {
							opacity: 1;
							visibility: visible;
							pointer-events: initial;
						}
					}
				}
			}

			&-level2 {
				padding: 0.6rem 0;
				background-color: $color05;
				position: absolute;
				height: 100%;
				top: 0;
				width: 320px;
				left: 0;
				left: 320px;

				&-item {
					width: 100%;

					&.all > a {
						color: $theme01;
						text-decoration: underline;
					}

					&:hover {
						> a {
							background: $color04;
							text-decoration: none;

							&:after {
								display: block;
							}
						}
					}

					&.hover {
						.main-nav-sub-level3 {
							opacity: 1;
							visibility: visible;
							pointer-events: initial;
						}
					}
				}
			}
			&-level3 {
				position: absolute;
				top: 0;
				left: 100%;
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
				transition: ease 0.1s all;
				transition-delay: 0.3s;
				padding: 0.6rem 0;
				width: 320px;
				height: 100%;

				&-items {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					height: 100%;
				}
				&-item {
					margin-right: 0rem;

					&.all > a {
						color: $theme01;
						text-decoration: underline;
					}

					> a {
						width: 100%;

						&:hover {
							background: $color08;
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.main-nav {
		&-sub {
			width: 960px;
		}
	}
}

@media only screen and (min-width: $b6) {
	.main-nav {
		&-sub {
			width: 960px;
		}
	}
}
@media only screen and (max-width: $b4 - 1) {
	.main-nav {
		border-top: 1px solid $theme01;

		li {
			&.has-sub {
				position: relative;

				> a:before {
					content: '\e801';
					@include kruitbosch-font();
					position: absolute;
					top: 1.8rem;
					right: 0;
					width: 3.2rem;
					height: 5rem;
					color: $color01;
				}

				&.open {
					> a {
						border-bottom-color: $theme01b;

						&:before {
							content: '\e803';
						}
					}
				}
			}
		}

		a {
			display: block;
			padding: 1.3rem 1.2rem;
			border-bottom: 1px solid $theme01;
			color: $color01;
			font-weight: $font-weight-extrabold;
			text-decoration: none;
		}

		[class^='icon-right-open'],
		&-close,
		&-sub-level2,
		&-sub-level3 {
			display: none;
		}

		&-sub {
			&-level1 {
				&-item.parts {
					min-height: 5.3rem;
					padding-right: 7.5rem;
					background: $color07 url('../images/nav-parts-bg.png') no-repeat right 0;

					> a {
						line-height: 1.2;
					}
				}
			}
		}

		.sub {
			display: none;
			border-bottom: 1px solid $theme01;

			.list-item-icon {
				color: $color01;
			}

			a {
				position: relative;
				display: block;
				margin-left: 1.2rem;
				border-bottom: 0;
				font-weight: $font-weight-normal;
				text-decoration: none;

				&:before {
					content: '\203A';
					position: absolute;
					top: 1.3rem;
					left: 0;
				}

				span {
					text-decoration: none;
				}

				&:hover {
					span {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
