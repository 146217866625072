/*========== Statics ==========*/

/*
	Note: here you place content that is static troughtout the site.
	Whether we call this file static ore globals we need a file
	where we can place styling that is present on all pages
*/
.wrapper {
	position: relative;
	margin: 0 auto;
	width: 100%;
	min-height: calc(100vh - 10rem); // minus preserved space header

	&.show-overflow {
		overflow: auto;
	}
}

// content and container widths
.content {
	position: relative;
	z-index: 2;
	margin: 0 auto;

	.pdp-open & {
		position: static;
	}

	.iosFocus & {
		padding-top: 0;
	}
}

.content-wrap {
	padding-top: 0;
	min-height: 1700px; //kan in de toekomst issue op leveren. Dan kijken of er een andere oplossing is!!

	.has-app & {
		min-height: auto;
	}

	.has-iframe & {
		min-height: 0;
	}

	#side-right > * {
		width: 100%;
	}
}

body.has-iframe {
	height: 100%;

	.main,
	.content-wrap {
		height: calc(100% - 50px);

		> .g {
			display: block;
			height: 100%;
		}
	}

	.iframe,
	.wrapper,
	.content,
	.content-subwrap {
		width: 100%;
		height: 100%;

		> iframe {
			width: 100%;
			height: 100%;
		}

		&.with-header {
			> iframe {
				height: calc(100% - 49px);
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.content {
		&-wrap {
			min-height: auto;

			> * {
				> * {
					display: flex;
					min-height: calc(100vh - 100px);

					&.checkout,
					&.basket {
						min-height: auto;
					}
				}
			}
		}

		&-subwrap {
			width: 100%;
		}
	}
}

@media only screen and (min-width: $b5) {
	body.has-iframe {
		.content-wrap {
			height: calc(100% - 4px);
		}

		.main {
			height: 100%;
		}
	}

	.wrapper {
		padding-top: 0;
		height: 100%;
	}

	.content {
		height: calc(100vh - 140px);

		&-wrap {
			height: 100%;

			> * {
				height: 100%;

				> * {
					min-height: initial;
					height: 100%;
					display: flex;
				}
			}

			&-form {
				display: block;
				height: 100%;
			}
		}

		&-subwrap {
			//display: flex;
			-webkit-flex-flow: column;
			flex-flow: column;
			height: 100%;
			width: 100%;
		}
	}
}
