.modal-content-container {
	&.quantity-warning {
		[class^='icon-'] {
			font-size: 1.8rem;
		}

		span.form-field {
			display: inline-block;
			margin: 0 0.6rem;
			width: 8rem;

			input {
				height: 2.7rem;
			}
		}
	}
}
