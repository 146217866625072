.history-list {
	&-item {
		padding: $padding-default * 2 $padding-default;
		background: $color01;
		transition: all 0.2s ease-out;
		overflow: visible;

		&:hover {
			background: $color05;
			color: $color03;
		}
	}

	&-label {
		color: $color06;
	}

	&-descr {
		padding-bottom: $padding-default/2;
	}

	&-longdescr {
		padding-top: 4px;
		line-height: 1.3;
	}

	a {
		font-weight: $font-weight-bold;
	}

	&-wrap {
		background: $color05;

		&-head {
			padding: $padding-default;
			border-bottom: 1px solid $color04;
			cursor: pointer;
		}

		.icon {
			font-size: 2.6rem;
			line-height: 1;
		}

		&.open {
			.icon:before {
				content: '\e803';
			}

			.history-list-item {
				display: flex;
			}
		}

		.history-list-item {
			display: none;
		}
	}
}

@media only screen and (min-width: $b3) {
	.history-list {
		&-wrap {
			&-head {
				padding: $padding-default $padding-default * 2;
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.history-list {
		&-label {
			padding-left: $padding-default * 2;
		}
	}
}

@media only screen and (min-width: $b5) {
	.history-list {
		&.scroll-b5 {
			overflow-y: scroll;
			height: calc(100% - 16rem);
		}

		&-item {
			padding: $padding-default/2 $padding-default;
			line-height: 1.2;
		}

		&-descr {
			position: relative;
			padding-bottom: 0;

			[class^='icon-'] {
				position: absolute;
				left: 17px;
				top: -20px;
				font-size: 3.6rem;

				&:hover {
					+ .history-list-person {
						display: block;
					}
				}
			}
		}

		&-longdescr {
			padding-bottom: 0;
			@include multiLineEllipsis(3.9rem, 2);
		}

		&-person {
			display: none;
			position: absolute;
			top: -20px;
			left: 66px;
			z-index: 1;
			padding: $padding-default;
			width: 200px;
			min-height: 8rem;
			text-align: center;
			background: $color05;
			border: 1px solid $color04;
			box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.49);

			> span {
				display: block;
				font-weight: $font-weight-bold;
				margin-bottom: $margin-default/2;
			}

			&:before {
				content: '';
				width: 0;
				height: 0;
				position: absolute;
				top: 12px;
				left: -10px;
				z-index: 2;
				border-style: solid;
				border-width: 10px 10px 10px 0;
				border-color: transparent $color05 transparent transparent;
			}

			&:after {
				content: '';
				width: 0;
				height: 0;
				position: absolute;
				top: 12px;
				left: -11px;
				z-index: 0;
				border-style: solid;
				border-width: 10px 10px 10px 0;
				border-color: transparent $color04 transparent transparent;
			}
		}

		&-wrap {
			&-head {
				padding: $padding-default/2 $padding-default * 2;
			}
		}
	}
}
