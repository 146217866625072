.social {
	ul,
	ol {
		display: flex;
		margin: 0;
		padding: 0;
		font-size: 0;
		list-style-type: none;
	}

	&-item {
		padding: 0.4rem;
		margin-right: 0.6rem;
		background: $color01;
		color: $color06;
		border: 1px solid $color06;
		border-radius: 50%;
		font-size: initial;

		&:hover {
			color: $color03;
			border-color: $color03;
			// > * {
			// 	border-color: $theme01;
			// 	color: $theme01;
			// 	background-color: transparent;
			// }
		}
	}
}

// @media only screen and ( min-width: $b6 ) {

// 	.social {

// 		&.share {

// 			position: absolute; left: -75px;
// 			left: -34px;
// 			top:0;
// 			@include x-rem(top, 4.4);
// 			width: 36px;

// 			&.share {
// 				width: 75px;
// 				width: 36px;

// 				ul {
// 					margin-left: -$gutterSpacing;
// 				}

// 				.socialItem {
// 					float: none;
// 				}
// 			}

// 		}
// 	}

// }
