$info-circle-width: 52px;
$info-circle-small-width: 42px;

.info {
	color: $color03;
	display: flex;
	text-decoration: none;
	margin: $padding-default 0;
	&-circle {
		position: relative;
		display: inline-block;
		//width: $shape-width;
		height: $info-circle-width;
		border-radius: 50%;
		background: $alert;
		color: $warning-text-color;
		margin-right: $padding-default * 2;
		flex: 0 0 $info-circle-width;
		> * {
			font-size: 2.4rem;
			font-weight: 900;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.info-circle.small {
	height: $info-circle-small-width;
	flex: 0 0 $info-circle-small-width;
}