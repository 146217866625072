.widget {
	&-thankyou {
		padding: $padding-default * 2;
		text-align: center;

		> a {
			text-decoration: none;
			color: $color07;
		}

		[class^='icon-'] {
			font-size: 7rem;
		}

		&-subtitle {
			padding: 0 $padding-default * 3;
		}
	}
}

@media only screen and (min-width: $b4) {
	.widget {
		&-thankyou {
			&-subtitle {
				@include multiLineEllipsis(4.8rem, 2);
			}
		}
	}
}
