@media only screen and (min-width: $b5) {
	.scroll-b5 {
		overflow: scroll;
		overflow-x: hidden;
		height: 100%;

		.side & {
			border-right: 1px solid $color01;
		}

		&.quickorder {
			height: calc(100% - 6.1rem);
		}

		.backorders & {
			height: calc(100% - 21.6rem);
		}

		.packingslips & {
			height: calc(100% - 15rem);
		}

		.spareparts .content-subwrap & {
			height: calc(100% - 23.5rem);
		}

		.with-header &,
		.side.with-title & {
			height: calc(100% - 6.1rem);
		}

		.wishlist & {
			height: calc(100% - 12.4rem);
		}
	}
}

.relative {
	position: relative;
}
.upper {
	text-transform: uppercase;
}
.inline {
	display: inline;
}

.inline-block {
	display: inline-block;
	vertical-align: top;
}

.mb-s {
	margin-bottom: $margin-default/2;
}

.p-s {
	padding: $padding-default/2;
}

.pt-s {
	padding-top: $padding-default/2;
}

.pb-s {
	padding-bottom: $padding-default/2;
}

.img-block {
	position: relative;

	a {
		display: block;
	}

	img {
		display: block;
		margin: 0 auto;
	}
}

.dataSummary {
	margin: 0 0 24px;
	padding: 12px 12px 0;
	background: $theme01;

	.head {
		margin: 0;
		font-size: inherit;
		font-family: $font-pri;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.ellipsis {
	@include ellipsis();
}

/* -----------------------------------------
	Usps (horizontal and vertical list)
----------------------------------------- */
.usps {
	ul {
		margin-left: 0;
		padding: 0;
		list-style: none;
	}

	li {
		position: relative;
	}

	&.horizontal {
		font-size: 1.4rem;
		background-color: lighten($theme01, 80%);

		ul {
			display: table;
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			padding: 0.2rem 0;
			width: 100%;
		}

		li {
			display: table-cell;
			vertical-align: bottom;
			flex: 1 auto;
			margin-left: 2.4rem;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}

@for $b from 1 through $amount-breakpoints {
	$breakpoint: map-get($breakpoint-map, $b);

	@media only screen and (min-width: $breakpoint) {
		.show-inline-b#{$b} {
			display: inline-block !important;
		}
		.show-flex-b#{$b} {
			display: flex !important;
		}
	}
}

/* -----------------------------------------
	Blocks
----------------------------------------- */

.color-color01 {
	color: $color01;
}

.color-color02 {
	color: $color02;
}

.color-color03 {
	color: $color03;
}

.color-color04 {
	color: $color04;
}

.color-color05 {
	color: $color05;
}

.color-color06 {
	color: $color06;
}

.color-color07 {
	color: $color07;
}

.color-color08 {
	color: $color08;
}

.color-theme01 {
	color: $theme01;
}

.color-theme02 {
	color: $theme02;
}

.color-theme03 {
	color: $theme03;
}

.color-positive-text {
	color: $positive-text-color;
}

.bgcolor-color01 {
	background-color: $color01;
}

.bgcolor-color02 {
	background-color: $color02;
}

.bgcolor-color03 {
	background-color: $color03;
}

.bgcolor-color04 {
	background-color: $color04;
}

.bgcolor-color05 {
	background-color: $color05;
}

.bgcolor-color06 {
	background-color: $color06;
}

.bgcolor-color07 {
	background-color: $color07;
}

.bgcolor-color08 {
	background-color: $color08;
}

.bgcolor-theme01 {
	background-color: $theme01;
}

.bgcolor-theme02 {
	background-color: $theme02;
}

.bgcolor-theme03 {
	background-color: $theme03;
}

.border {
	border: 1px solid $color04;
}

.border-light {
	border: 1px solid $color01;
}

.border-bottom {
	border-bottom: 1px solid $color04;
}

.block {
	margin-bottom: $margin-default * 2;

	&.invert {
		background-color: $color01;
	}

	&.text-dark {
		color: $color02;
	}
	&.text-light {
		color: $color01;
	}

	&.theme01 {
		color: $theme01;

		&.invert {
			color: $color01;
			background-color: $theme01;
		}
	}

	&.theme02 {
		color: $theme02;

		&.invert {
			color: $theme01;
			background-color: $theme02;
		}
	}

	// &.theme03 {
	// 	color: $theme03;

	// 	&.invert {
	// 	    color: $color01;
	// 	    background-color: $theme03;
	// 	}
	// }

	&-small-margin {
		margin-bottom: $margin-default;
	}

	&-large-margin {
		margin-bottom: $margin-default * 4;
	}
}

/* -----------------------------------------
	layer down arrow
----------------------------------------- */

.layer-down {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	border-radius: $radius;
	background-color: $color01;

	> * {
		display: block;
		width: 100%;
		font-size: 20px;
		line-height: 20px;

		&:before {
			margin-left: -0.01em;
		}
	}

	&.open,
	&:hover {
		> * {
			&:before {
				content: '\e803';
			}
		}
	}
}

.my-account {
	position: relative;
	width: 3.4rem;
	height: 3.4rem;
	background-color: $theme02;
	border-radius: 50%;
	color: $color04;
	font-size: 3.9rem;

	[class^='icon-']:before {
		position: absolute;
		top: 1px;
		left: -10px;
	}
}

/* -----------------------------------------
	Tables
----------------------------------------- */

.table {
	width: 100%;
	border-collapse: collapse;

	th {
		vertical-align: middle;
		font-weight: $font-weight-normal;
		cursor: pointer;
	}

	tbody {
		tr {
			> * {
				padding: $padding-default;
			}
		}
	}
}

/* this utility class uses important to overrule all styling specifief deeper inside module */
.removeBg {
	background: none !important;
}

/* this utility class uses important to overrule all styling specifief deeper inside module */
.noBorder {
	> * {
		border-top: 0 !important;
		border-bottom: 0 !important;
	}
}

// creates a header and inside this header all child element are inline
// this way u can easier apply a heading and a anchor tag inline
.block-header {
	margin-bottom: $margin-default;

	> * {
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 0;

		+ * {
			margin-left: $margin-default;
		}
	}

	&.pb0 {
		padding-top: 0;
		padding-bottom: 0;
	}
}

/* -----------------------------------------
	Colors for icons
----------------------------------------- */

i {
	&.valid {
		color: $positive;
	}
	&.negative {
		color: $negative;
	}
	&.disabled {
		color: $disabled;
	}
}

/* -----------------------------------------
	Border-radius
----------------------------------------- */
.radius {
	border-radius: $radius;
}

/*========== list-click ==========*/

.list-click {
	.list-item {
		cursor: pointer;
	}
}

/* -----------------------------------------
	responsive iframes
----------------------------------------- */

/* Note

height: 0;

The height is set to 0 because padding-bottom gives
the element the height it needs. We do not set the width
because it will automatically resize with the responsive
element that contains this div.

Setting overflow to hidden ensures that any content
protruding outside of this element will be hidden from view.

*/

.iframe-container {
	position: relative;
	height: 0;
	overflow: hidden;

	/* Note

	Absolute positioning is used because the containing element
	has a height of 0. If the iframe were positioned normally,
	we would have given it a height of 0 as well

	The top and left properties position the iframe
	correctly in the containing element.

	The width and height properties ensure that the video
	takes up 100% of the space used by the containing element
	(which is actually set with padding).

	*/

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// aspect ratios

// 100 / 16 * 9
.ratio_16_9 {
	padding-bottom: 56.25%;
}

// 100 / 4 * 3
.ratio_4_3 {
	padding-bottom: 75%;
}

/*
	Responsive video

	The padding-top value is set to 35 pixels to allow space
	for the chrome — this is specific to YouTube videos.
*/

.video-container {
	padding-top: 35px;
}

/*
	Responsive calendar
*/

.calendar-container {
	padding-bottom: 75%;
}

/*
	Google Maps

	there is already a google maps iframe used see if we use this ore the other
*/

.googleMaps {
	padding-bottom: 90%; // (450 ÷ 500 = 0.9 = 90%)
}

/*
	Loading Overlay

	as used on listerpage
*/

.lister-loading,
#loading,
#combinationLoading,
.page-loader {
	clear: both;
	padding: 48px 0;
	width: 100%;
	text-align: center;

	img {
		display: block;
		margin: 0 auto;
	}
}

%overlayloader {
	position: absolute;
	z-index: 999;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: $color01 no-repeat center;
	font-size: 0;
	opacity: 0.5;

	&.closebutton {
		top: 5rem;
	}

	[class^='icon-'] {
		position: absolute;
		top: 150px;
		left: 50%;
		transform: translateX(-50%);
		width: 50px;
		height: 50px;
		font-size: 0;

		&:before {
			margin: 0;
			padding: 0;
			font-size: 5rem;
		}
	}

	.saving {
		position: absolute;
		top: 134px;
		left: 50%;
		transform: translateX(-50%);
		width: 50px;
		font-size: 69px;
		color: $color03;
	}
}

.overlay-loader {
	@extend %overlayloader;
}

.overlay-loader-centered {
	@extend %overlayloader;

	.saving,
	[class^='icon-'] {
		top: 50%;
		transform: translateY(-50%);
	}

	[class^='icon-'] {
		margin-top: -1.5rem;
	}
}

.lister-loader {
	padding: $padding-default;
	position: relative;
	text-align: center;

	.has-app & {
		padding-bottom: 14.6rem; //approximate height of an lister item
	}

	[class^='icon-'] {
		width: 50px;
		height: 50px;
		font-size: 0;

		&:before {
			margin: 0;
			padding: 0;
			font-size: 5rem;
		}
	}

	.saving {
		font-size: 0;
		color: $color03;

		> * {
			display: inline-block;
			line-height: 0;
			font-size: 69px;
			margin-top: -6px;
		}
	}
}

// .overlay-loader, .page-loader {
// 	.spinner {
// 		width: 50px;
// 		height: 50px;
// 		font-size: 5rem;
// 	}
// }

// #filterLoading,
// #overlayLoading,
// .lister-loader {
// 	position: absolute;

// 	.spinner {
// 		top: 150px;

// 		&:before {
// 			position: absolute;
// 			top: 0;
// 			left: 0;
//     		margin: 0;
// 			width: 50px; height: 50px;
// 		}
// 	}

// }

// UTILITY GLYPHS

// TODO: only links-more currently works, the rest still has font-icon icons
// TODO: put this in a castlecss package?
// TODO fileType icons

.fileType {
	display: none;
}

[class^='links-'],
[class*=' links-'] {
	&,
	ul {
		@include ulreset();
		line-height: 1.8;
	}

	ul {
		padding: 0 0 $padding-default $padding-default;
	}

	a,
	.link {
		text-decoration: none;

		span {
			text-decoration: none;

			// add text after link i.e. download list
			+ small {
				padding-left: 6px;
				text-decoration: none;
				color: $color03;
			}
		}
	}
}

[class^='links-'] a,
[class*=' links-'] a,
[class^='links-'] .link,
[class*=' links-'] .link,
[class^='link-'],
[class*=' link-'] {
	display: inline-block;
	position: relative;
	text-decoration: none;

	&:before,
	&:after {
		position: absolute;
	}

	span {
		text-decoration: none;
	}

	&:hover {
		span {
			text-decoration: underline;
		}
	}
}

.toggleInline {
	> .pointer > i {
		position: relative;
		top: 2px;
		left: -2px;
		font-size: 2rem;
	}
}
.pointer {
	cursor: pointer;
}

/* Note

	Link Styling: add glyphs to an individual link or list.
	Use it directly on the <a>-tag by adding .link in combinaton with
	an 	option, or add the option-class directly to the <ul>-tag instead
	without .link. All options available are listed below.

	.more
	.prev
	.next
	.del
	.add
	.check
	.disabled

	To disable a link, just add the class .dis.

*/

.links-more a,
.links-more .link,
.link-more {
	margin-left: 10px;

	&:before {
		content: '\203A';
		top: -2px;
		left: -10px;
	}
}

.links-prev a,
.links-prev .link,
.link-prev {
	margin-left: 10px;

	&:before {
		content: '\2039';
		top: 0;
		left: -10px;
	}
}

.links-next a,
.links-next .link,
.link-next {
	margin-left: 0;
	margin-right: 10px;
	outline: none;

	&:focus {
		outline: none;
	}

	&:after {
		content: '\203A';
		top: 0;
		right: -10px;
	}

	&.dark {
		color: $color03;
	}
}

.links-del a,
.links-del .link,
.link-del {
	margin-left: 15px;

	&:before {
		content: '\00D7';
		top: 0;
		left: -15px;
		font-size: 100%;
	}
}

.links-add a,
.links-add .link,
.link-add {
	margin-left: 15px;

	&:before {
		content: '+';
		top: 0;
		left: -15px;
	}
}

.links-check a,
.links-check .link,
.link-check {
	margin-left: 15px;

	&:before {
		content: '\03BD';
		top: 0;
		left: -15px;
	}
}

.link-disabled {
	&,
	a {
		cursor: not-allowed;
		pointer-events: none;
		color: $color03;
	}
}

/*header fixes for landscape devices */
@media only screen and (max-height: 420px) AND (max-width: $b4 - 1px) {
	body {
		padding-top: 0;
	}

	.content {
		padding-top: $header-height;

		.has-app & {
			padding-top: 0;
		}
	}

	.header {
		position: relative;
		height: $header-height;

		.has-app & {
			height: 0;
		}

		.detail-open & {
			display: none;
		}
	}

	.detail-open .has-app {
		.header {
			display: block;
		}

		.detail {
			top: 0;
		}

		.crumbs {
			opacity: 0;
		}

		.search {
			display: none;
		}
	}

	.detail {
		top: 0;
	}
}

@media only screen and (max-height: 400px) AND (min-width: $b4) {
	.content {
		padding-top: 0;
	}

	.crumbs {
		position: relative;
		top: 0;
	}
}

/* ----------------------------------------------------------------------------------
	Helper classes for blocks on dealerportal static page block template
---------------------------------------------------------------------------------- */

.rte-inner {
	padding: 0 $padding-default * 2 0;

	p {
		margin-bottom: 0;
	}
}

@media only screen and (min-width: $b3) {
	.rte-inner {
		max-width: 100%;
		@include centered-block(0 $padding-default * 3, 70rem);
	}
}

@media only screen and (min-width: $b5) {
	.rte-inner {
		@include centered-block(0 10rem, 90rem);
	}
}

@media only screen and (min-width: $b6) {
	.rte-inner {
		@include centered-block(0 18rem, 116.4rem);
	}
}

/* ----------------------------------------------------------------------------------
	Helper classes for blocks on dealerportal static page block template
---------------------------------------------------------------------------------- */

// .form-fields.label-above .has-focus label[for],
// .form-fields.label-above .has-value label[for] {
// 	top: 4px;
// }

.slide-top {
	animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
