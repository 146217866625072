/*========== Buttons ==========*/

@mixin defaultButton {
    display: inline-block;
	padding: 0.9rem 1.3rem 0.9rem;
	min-height: 4rem;
	height: auto; /* same as input.text, textarea, select font-size */
	font-family: $font-pri;
	font-weight: $font-weight-extrabold;
	font-size: 1.4rem;
	line-height: 1.5;
	text-align: center;
	text-decoration: none;
	letter-spacing: 0.23rem;
	cursor: pointer;
	background: $theme01;
	color: $color01;
	border: none; /* necessary for type button */
	transition: all 0.2s ease-out;
	border-radius: $radius;
	text-transform: uppercase;
}

.btn {
	@include defaultButton;

	&:disabled {
		cursor: not-allowed;
		opacity: 0.6;
		pointer-events: none;
	}

	&:hover {
		text-decoration: none;
		background: $theme01b;
		color: $color01;
	}

	&-disabled:hover {
		background: $theme01;
	}

	&.small {
		padding: 0.4rem 1.3rem 0.4rem;
		height: 2.9rem;
		min-height: initial; //used by all other browsers
		min-height: auto; //used by IE11 on windows 7
	}

	&.full {
		width: 100%;
	}
}

.btn-theme01 {
	@extend .btn;
	background-color: $theme01;
	color: $color01;

	&:hover {
		color: $color01;
		background-color: $theme01b;
	}

	&.btn-disabled:hover {
		background: $theme01;
	}
}

.btn-theme02 {
	@extend .btn;
	background-color: $theme02;
	color: $color01;

	&:hover {
		color: $color01;
		background-color: $theme02b;
	}

	&.btn-disabled:hover {
		background: $theme02;
	}
}

.btn-theme03 {
	@extend .btn;
	background-color: $theme03;
	color: $color01;

	&:hover {
		color: $color01;
		background-color: $theme03a;
	}

	&.btn-disabled:hover {
		background: $theme03a;
	}
}

.btn-color01 {
	@extend .btn;
	background-color: $color01;
	color: $theme01;

	&:hover {
		color: $color01;
		background-color: $theme01;

		[class^='icon-']:before {
			color: $color01;
		}
	}

	&.btn-disabled:hover {
		background: $color01;
	}
}

.btn-color01-icon {
	@extend .btn-color01;
	//text-transform: uppercase;
	font-weight: $font-weight-normal;
	text-transform: initial;
	color: $color03;

	[class^='icon-']:before {
		margin-right: 0.6rem;
		font-size: 2.6rem;
		line-height: 0.2;
		color: $theme02;
		vertical-align: middle;
	}
}

.btn-color01-border {
	@extend .btn-color01;
	padding: 0.7rem 1.3rem 0.7rem;
	border: 2px solid $theme01;
}

.btn-color05 {
	@extend .btn;
	background-color: $color05;
	color: $theme01;

	&:hover {
		color: $color01;
		background-color: $theme01;

		[class^='icon-']:before {
			color: $color01;
		}
	}

	&.btn-disabled:hover {
		background: $color01;
	}
}

.btn-sec {
	@extend .btn;

	&:before {
		content: '\203A';
		display: inline-block;
		position: relative;
		top: 1px;
		margin: -2rem 0.4rem 0 0;
		padding: 0;
		font: bold 20px/1 Arial;
		color: #fff;
		transition: all 0.3s ease-out;
	}
}

.btn-disabled {
	cursor: not-allowed;
	opacity: 0.6;
	pointer-events: none;
}


//style button as link
.btn-link {
	@extend .btn;
    padding: 0;
    min-height: unset;
    font-weight: normal;
    color: $theme01;
	background: transparent;
	text-transform: none;
	outline: none;
	letter-spacing: 0.1rem;
	font-size: 1.5rem;
	line-height: 1.6;

	&:hover {
		background: transparent;
		text-decoration: underline;
		color: $theme01;
	}

	&.more {
		position: relative;
		margin-left: 10px;

		&:before {
			content: '\203A';
			position: absolute;
			top: -2px;
			left: -10px;
			font-size: 1.6rem;
		}
	}
}

.btn-icon {
	@extend .btn;
	font-size: 1.5rem;
	height: 4rem;
	color: $color03;
	background: $color01;
	border: 1px solid $color04;
	&:hover {
		color: $color03;
		background: $color01;
	}
	//tweak
	.icon-dropshipment-thin:before {
		margin-left: -0.1em;
		padding-right: 0.1rem;
	}
}
.btn-lister {
	@extend .btn-icon;
	min-height: 3rem;
	height: 3rem;
	line-height: 0.8rem;
}

.btn-only-mobile {
	@media only screen and (max-width: $b4 - 1px) {
		@include defaultButton;
	}
}

@include prevButton('.btn-prev');
