.quickorder {
	.list-item {
		background: $color01;
		cursor: default;

		&:hover {
			background: $color01;
		}
	}
}
