.widget {
	&-order {
		&-lines {
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				border-bottom: 1px solid $color04;

				a {
					display: flex;
					padding: 0.6rem $padding-default * 2;
					justify-content: space-between;
					text-decoration: none;
					color: $color03;
					&:hover {
						background: $color05;
					}
				}

				&:last-child {
					border-bottom: none;
				}
			}
		}
		&-title {
			@include ellipsis();
			padding-right: $padding-default;
		}
		&-state {
			text-align: right;
			white-space: nowrap;
			.ios & {
				min-width: 100px;
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.widget {
		&-order {
			&-lines {
				li {
					a {
						padding: 0.6rem $padding-default * 2 0.5rem;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.widget {
		&-order {
			&-lines {
				li {
					a {
						padding: 0.4rem $padding-default * 2 0.5rem;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $b6) {
	.widget {
		&-order {
			&-lines {
				li {
					a {
						padding: 1.1rem $padding-default * 2;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $b7) {
	.widget {
		&-order {
			&-lines {
				li {
					a {
						padding: 0.6rem $padding-default * 2 0.7rem;
					}
				}
			}
		}
	}
}
