/*========== LISTER PRODUCTS ==========*/

/*	Use .list for a list with items / products
*/

.list {
	&-head {
		position: relative;
		display: block;
		background: $color05;
		padding: $padding-default $padding-default $padding-default $padding-default * 3;
		border-bottom: 1px solid $color04;

		&-title {
			font-size: 1.5rem;
			line-height: 1.2;
		}

		&-data {
			color: $color06;
			font-size: 1.2rem;
			line-height: 1.2;
		}

		&-checkbox {
			position: absolute;
			top: 50%;
			left: 0.4rem;
			margin-top: -0.5rem;
			transform: translateY(-50%);
		}
	}

	&-result {
		&-head {
			padding: $padding-default;
			background: $color05;
			width: 100%;

			.spareparts & {
				display: flex;
				align-items: center;
				padding: 0 $padding-default;
				height: 6rem;
			}
		}

		&-results {
			font-size: 1.4rem;
		}
	}

	&-item {
		position: relative;
		padding: $padding-default/2;
		border-bottom: 1px solid $color04;
		text-decoration: none;
		color: $color03;
		cursor: pointer;

		&.column {
			padding: $padding-default * 2;
			border: 1px solid $color04;
			background: $color01;
			border-radius: $radius;
			text-align: center;
			transition: all 0.3s ease-out;

			&:hover {
				background: $color01;
				border: 1px solid $theme03a;
			}

			.head {
				@include multiLineEllipsis(4.5rem, 2);
			}

			img {
				margin-bottom: $margin-default;
				max-width: 20rem;
				max-height: none;
			}

			.list-item-sale {
				top: 0;
				left: 0;
				border-radius: 0;
				border-bottom-right-radius: $radius;
				transform: none;
			}
		}

		&:last-child {
			.has-ending & {
				border-bottom: 0;
			}
		}

		&.disabled {
			color: lighten($color06, 25%);

			&:hover {
				color: lighten($color06, 25%);
			}

			.icon-wrap a {
				opacity: 0.6;
			}
		}

		img {
			max-height: 18.6rem;
			margin-bottom: $margin-default/2;
		}

		.img-wrap {
			position: relative;
			font-size: 0;
			align-self: flex-start;
		}

		.variant {
			width: 100%;
			text-align: center;
		}

		.prices {
			del {
				display: block;
				margin: 0;
				font-size: 1.2rem;
				line-height: 1.2;
			}
		}

		&:hover:not(.no-hover, .selected) {
			background-color: $color05;
			color: $color03;
		}

		&.no-hover {
			cursor: default;
			background-color: $color01;
		}

		&.no-border {
			border-bottom: none;
		}

		&-variant {
			&-head {
				position: relative;
				padding: $padding-default/2 $padding-default;
				margin: -6px -6px 6px -6px;
				background: $color01;
				border-bottom: 1px solid $color04;
				color: $color03;
				cursor: default;

				&:before {
					content: '';
					position: absolute;
					left: 20px;
					top: -10px;
					z-index: 1;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 13px 10px 13px;
					border-color: transparent transparent $color02 transparent;
					transition: ease 0.2s all;
					transition-delay: 0.3s;
				}

				&:after {
					content: '';
					position: absolute;
					left: 20px;
					top: -9px;
					z-index: 2;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 13px 10px 13px;
					border-color: transparent transparent $color01 transparent;
					transition: ease 0.2s all;
					transition-delay: 0.3s;
				}
			}
		}

		&-fakeinput {
			width: 0;
			height: 0;
			opacity: 0;
		}

		//moved to list-view2
		//&.selected {
		//	background: $theme02;
		//	color: $color01;

		//	small {
		//		color: $color01;
		//	}
		//}

		&.parent {
			//should text color change on hover?

			//&:not(.selected) {
			//	&:hover {
			//		background: $color05;
			//		color: $color02;

			//		small {
			//			color: $color02;
			//		}
			//	}
			//}

			[class^='icon-down'] {
				font-size: 3rem;
				line-height: 0.5;
			}

			&.open {
				background: $color05;
				border-bottom: 1px solid $color02;

				//does .open, .selected ned different text colors?

				//small {
				//	color: $color02;
				//}

				.icon-down-open {
					&:before {
						content: '\E803';
					}
				}

				//&.selected {
				//	background: $theme02;
				//	color: $color01;

				//	small {
				//		color: $color01;
				//	}
				//}
			}
		}

		&.child {
			display: none;
			border-left: 1px solid $color02;
			border-right: 1px solid $color02;

			&.open {
				display: flex;
			}

			&.last-child {
				border-bottom: 1px solid $color02;
			}
		}

		.input-combined {
			.btn,
			input[type='number'] {
				height: 3rem;
				min-height: initial;
				min-height: auto;

				&:hover {
					border-color: $color04;
				}
			}

			.btn {
				background: $color01;
				padding: 0 0.4rem;
				height: 3rem;
				line-height: 3rem;
				font-size: 2.2rem;
				border: 1px solid $color04;
				border-left: none;
				color: $color03;

				&-icon {
					font-size: 1.5rem;
					min-width: 3rem;
				}
			}
		}

		input[type='number'] {
			min-width: 0px; //probably an error in IE and Firefox
			outline: none;

			&.list-item-fakeinput {
				cursor: pointer;
			}
		}

		&-message {
			display: inline-block;
			padding: 0 $padding-default/2;
			height: auto;
			font-size: 1.2rem;
			line-height: $line-height-default;
			letter-spacing: $default-letterspacing;
		}

		&.not-available {
			background: $color05;
			color: $color03;

			small {
				color: $color06;
			}
		}

		[class^='alert-'] {
			border-radius: 2px;
		}

		&-icon {
			padding-left: $padding-default;

			.success {
				color: $positive-text-color;
			}

			.error {
				color: $negative-text-color;
				font-size: 1.6rem;
			}
		}

		&-stockicon {
			white-space: nowrap;
			color: $theme01;
			font-size: 1.3rem;

			[class^='icon-']:before {
				margin: 0;
				font-size: 1.5rem;
				transform: translateX(-2px);
			}
		}

		&-sale {
			position: absolute;
			right: -20px;
			top: -2px;
			padding: 2px 6px;
			font-size: 1rem;
			font-weight: bold;
			border-radius: 100px;
			color: $color01;
			background: $theme01;
			text-transform: uppercase;

			.salelabels & {
				position: static;
				padding: 3px 12px;
				margin: 0px 5px 5px 0px;
				order: -1;
			}
		}

		&-packagingunit {
			display: inline-block;
			color: $theme01;
			margin: 0 12px 0 -5px;

			&:before {
				margin-right: 0.2rem;
			}
		}

		&-combined {
			outline: none;

			[class^='icon-'] {
				position: relative;
				display: inline-block;
				margin-bottom: -0.8rem;
				width: 3.5rem;
				height: 3.5rem;
				font-size: 0;
				//color: $color03;

				&:before {
					position: absolute;
					left: 0;
					top: 0;
					margin: 0;
					width: 3.5rem;
					height: 3.5rem;
					font-size: 2.5rem;
					line-height: 35px;
				}
			}
		}

		.extra-product & {
			&:hover {
				background: $color01;
			}

			.img-wrap {
				img {
					width: auto;
					max-height: 5.9rem;
				}
			}

			[class^='icon-'] {
				font-size: 2rem;
				line-height: 16px;
				margin-left: -0.5rem;
			}
		}

		&-block {
			[class^='icon-'] {
				font-size: 2rem;
				line-height: 16px;
			}
		}
	}

	&-items {
		&-combined {
			display: none;
			background: $color01;
			transition: height 0.3s cubic-bezier(0, 0, 0.3, 1);
			will-change: transform;

			&.active {
				position: relative;
				display: block;
				border: 1px solid $color03;
				margin-top: 1.2rem;

				&:before {
					content: '';
					position: absolute;
					top: -10px;
					left: 33.3%;
					z-index: 2;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 11px 11px 11px;
					border-color: transparent transparent $color01 transparent;
				}

				&:after {
					content: '';
					position: absolute;
					top: -11px;
					left: 33.3%;
					z-index: 1;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 11px 11px 11px;
					border-color: transparent transparent $color03 transparent;
				}

				.basket-item-del {
					margin-left: -0.2rem;

					[class^='icon-'] {
						position: absolute;
						top: 10px;
						left: 6px;

						&:before {
							font-size: 1.2rem;
							border: 1px solid;
							border-radius: 50%;
							width: 16px;
							height: 16px;
							line-height: 16px;
						}
					}
				}
			}

			.list-item {
				background: $color01;
				cursor: default;
			}
		}

		&-descr {
			padding-left: 0.7rem;
		}

		&-header {
			padding: $padding-default 0;
			border-bottom: 1px solid $color04;

			.list-items-config {
				position: relative;
				display: block;
				line-height: 1.2;
				padding: 0 1.2rem 0.6rem 2.6rem;
				font-size: 1.4rem;
				text-decoration: none;

				> [class^='icon-'] {
					position: absolute;
					left: 4px;
					top: 0px;
				}
			}
		}
		&-details {
			border-bottom: 1px solid $color04;
			border-left: 1px solid $color04;
			border-right: 1px solid $color04;
		}
		&-lineheader {
			border: 1px solid $color04;
		}

		&-orderadvice {
			padding-left: 0.6rem;
			line-height: 1.2;
			margin-top: 0.4rem;
			margin-bottom: 1rem;

			&.link-more:before {
				left: -2px;
			}

			a {
				padding-left: 0;
			}
		}
	}
}

@media only screen and (min-width: $b2) {
	.list {
		&-item {
			.extra-product & {
				.img-wrap {
					img {
						margin-left: $margin-default * 2;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: $b4 - 1) {
	.list {
		&-item {
			&.parent {
				[class^='icon-'] {
					position: absolute;
					right: 0.6rem;
					bottom: 0.6rem;
				}
			}

			&-sale {
				right: auto;
				left: -3px;
				top: -3px;
			}
		}
	}
}
@media only screen and (min-width: $b4) {
	.list {
		&-head {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0 $padding-default * 2 0 $padding-default * 4;
			height: 4rem;

			&-title {
				flex: 0 1 auto;
			}

			&-data {
				padding-left: 1.2rem;
			}

			&-checkbox {
				left: 1.5rem;
			}
		}

		&-cat-head {
			padding-left: $padding-default * 2;
		}

		&-result {
			&-head {
				line-height: 1.5;

				.spareparts & {
					padding: 0 $padding-default * 2;
					background: $color07 url('../images/nav-parts-bg.png') no-repeat bottom right;
					color: $color01;
					text-transform: uppercase;
				}

				.head {
					line-height: 1;
				}
			}
		}

		&-item {
			padding: 0.1rem;
			line-height: 1.2;
			min-height: 5.9rem;

			&.column {
				margin-bottom: $margin-default;
			}

			&.packingslip {
				padding: 1.1rem;
			}

			&-variant {
				&-head {
					margin: -1px -1px 1px -1px;

					&:after,
					&:before {
						display: none;
					}
				}
			}

			&.parent {
				&.open {
					.list-item-availability {
						position: relative;

						[class^='alert-'] {
							height: 2.1rem;
						}

						&:before {
							content: '';
							position: absolute;
							left: 0;
							bottom: -20px;
							z-index: 1;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 13px 10px 13px;
							border-color: transparent transparent $color02 transparent;
							transition: ease 0.2s all;
							transition-delay: 0.3s;
						}

						&:after {
							content: '';
							position: absolute;
							left: 0;
							bottom: -21px;
							z-index: 2;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 13px 10px 13px;
							border-color: transparent transparent $color01 transparent;
							transition: ease 0.2s all;
							transition-delay: 0.3s;
						}

						&.replaced {
							&:before {
								left: -66.6%;
								bottom: -30px;
							}
							&:after {
								left: -66.6%;
								bottom: -31px;
							}
						}
					}
				}
			}

			.extra-product & {
				.img-wrap {
					img {
						margin-left: 3rem;
						max-height: 5.9rem;
					}
				}
			}

			&-sale {
				right: auto;
				left: -9px;
				top: 2px;
			}

			.img-wrap {
				align-self: auto;
				height: 5.9rem;

				img {
					height: 100%;
				}
			}

			.no-img {
				> * {
					height: 5.9rem;
				}
			}

			&-titlewrap {
				padding-left: 0.8rem;
				padding-right: 0.6rem;
			}

			&-availability {
				[class^='alert-'] {
					padding: 0;
					width: 2.8rem;
					text-align: center;
				}
			}

			&-icon {
				padding-left: 0.4rem;
				text-align: center;
			}

			&-combined {
				text-align: center;

				[class^='icon-'] {
					position: absolute;
					top: 1.3rem;
					right: 0;

					&:before {
						margin: 0;
					}
				}
			}
		}

		&-items {
			&-combined {
				&.active {
					article > div:first-child {
						width: 6px;
					}

					&:after,
					&:before {
						left: auto;
						right: 42px;
					}

					.basket-item-del {
						[class^='icon-'] {
							top: 8px;
							left: 8px;
						}
					}
				}
			}

			&-descr {
				padding-top: 0.6rem;
				padding-left: 2.1rem;
			}

			&-header {
				padding: $padding-default 0;

				.list-items-config {
					padding-left: 4.6rem;

					> [class^='icon-plus'] {
						left: 18px;
						font-size: $font-size-default;
					}
				}
			}

			&-orderadvice {
				padding-left: $padding-default * 2;

				&.link-more:before {
					left: 12px;
				}
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.list {
		margin-left: -$margin-default * 1.5;
		padding-left: $padding-default * 1.5;

		&.scroll-b5 {
			height: 100%;
		}

		&-item {
			.extra-product & {
				.img-wrap {
					img {
						margin-left: $margin-default;
					}
				}
			}

			&.column {
				margin-bottom: 0;
				padding: $padding-default;
			}

			&-sale {
				left: -18px;
				padding: 3px 8px;
			}
		}
	}

	.list-result-head + .list {
		&.scroll-b5 {
			height: calc(100% - 6.1rem);

			.spareparts & {
				height: calc(100% - 6.1rem);
			}
		}
	}
}

@media only screen and (min-width: $b6) {
	.list {
		&-item {
			min-height: 6.2rem;

			.extra-product & {
				.img-wrap {
					img {
						margin-left: 3rem;
					}
				}
			}

			&.column {
				padding: $padding-default * 2;
			}

			.img-wrap {
				height: 5.9rem;
			}

			&-titlewrap {
				padding-left: 0.6rem;
			}

			// &-message {
			// 	//width: 42%;
			// }

			&-icon {
				padding-left: 0;
			}
		}
	}
}
