.quickorder {
	&-icon {
		position: relative;
		display: block;
		color: $color08;
		font-size: 2rem;
		text-align: center;
		text-decoration: none;
		&:hover {
			color: $theme02;
		}
	}
}
