.productzoom {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 999999;
	width: 100%;
	height: 100%;
	background-color: $color01;
	visibility: visible;
	opacity: 1;
	-webkit-transition: opacity 0.4s ease-out;
	transition: opacity 0.4s ease-out;

	&-container {
		position: relative;
		height: 100%;
		width: 100%;
		padding: 80px 0;
		cursor: move;
		overflow: hidden;

		&-image {
			width: 100%;
			-webkit-backface-visibility: hidden;
			transform: translate3d(0, 0, 0);
		}
	}

	&-image {
		position: relative;
		width: 100%;
		height: 100%;

		img {
			position: absolute;
			left: 50%;
			transition: transform 0.1s ease-out;
		}
	}

	&-controls {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 80px;
		overflow: hidden;
		z-index: 2;
		background-color: $color01;
		box-shadow: 2.5px 4.33px 7px 0px rgba(0, 0, 0, 0.3);
	}

	&-btn {
		display: block;
		float: left;
		width: 80px;
		height: 80px;
		text-align: center;
		font-size: 3rem;
		line-height: 80px;
		border: none;

		&.productzoom-close {
			float: right;
			color: $color01;
			background-color: $theme03;
		}
	}

	&-thumbs {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		background: $color01;
		-webkit-overflow-scrolling: touch;
		box-shadow: 2.5px -4.33px 7px 0px rgba(0, 0, 0, 0.3);

		.video {
			> a {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					z-index: 2;
					width: 100%;
					height: 100%;
					background: url(../images/play-button.svg) no-repeat center center;
					background-size: 34%;
				}
			}
		}

		a {
			border: 0;

			&:hover {
				opacity: 0.75;
			}
		}

		ul {
			width: 20000px;
			margin: 0;
			padding: 0;
			list-style: none;
		}

		li {
			width: 100px;
			float: left;
			margin-left: 20px;

			a {
				display: block;
			}

			&:first-child {
				margin-left: 0;
			}
		}

		img {
			display: block;
			width: 80px;
			margin: 0 10px;
			padding: 10px 0;
		}

		&-zoom {
			position: fixed;
			top: 1.4rem;
			z-index: 3;
			width: 80px;
			height: 60px;
			font-size: 3.2rem;
			text-align: center;
			color: $color03;
			outline: none;
			transition: transform 200ms;

			&.out {
				left: 80px;
			}

			&:hover:not(.disabled) {
				transform: scale(1.2);
				color: $color03;
			}

			&.disabled {
				opacity: 0.3;
				color: $color03;

				&:hover {
					opacity: 0.3;
				}
			}
		}
	}
}

@media only screen and (min-width: $b2) {
	.productzoom {
		&-controls {
			height: 100%;
			width: 80px;
		}

		&-container {
			padding: 0;
		}

		&-thumbs {
			top: 0;
			left: 0;
			width: 100px;
			height: 100%;
			padding: 0;
			box-shadow: 2.5px 4.33px 7px 0px rgba(0, 0, 0, 0.3);
			overflow-y: auto;
			overflow-x: hidden;
			z-index: 2;

			ul {
				width: auto;
				height: calc(100% * 2);
				margin: 0;
				padding: 0;
				list-style: none;
			}

			li {
				float: none;
				margin-left: 0;
			}
		}

		&-image {
			padding-left: (100px + $padding-default);

			img {
				padding-left: (100px + $padding-default);
			}
		}
	}
}

@media only screen and (min-width: $b2) and (max-width: $b3 - 1) {
	.productzoom {
		&-thumbs {
			&-zoom {
				position: fixed;
				right: 0;
				left: auto;
				top: (80px + $margin-default);

				&.out {
					left: auto;
					top: (160px + $margin-default);
				}
			}
		}

		&-image {
			padding-right: (100px + $padding-default);

			img {
				padding-right: (100px + $padding-default);
			}
		}
	}
}

@media only screen and (min-width: $b3) {
	.productzoom {
		img {
			max-width: none;
			max-height: calc(100% - #{$padding-default * 4});
			margin: 0 auto;
		}

		&-controls {
			top: 0;
			right: 0;
			left: auto;
			width: 80px;
			height: 80px;
			background-color: transparent;
			overflow: visible;
			box-shadow: none;
		}
		&-btn {
			float: none;
		}

		&-thumbs {
			top: 0;
			right: 0;
			left: auto;
			padding-top: 100px;
			width: 80px;
			height: 100%;
			overflow: visible;
			z-index: 1;

			li {
				width: 80px;
			}

			ul {
				width: auto;
				height: 100%;
				overflow: auto;
				overflow-x: hidden;
			}

			img {
				width: 60px;
			}

			&-zoom {
				position: relative;
				width: 100%;
				left: auto;
				top: auto;

				&.out {
					left: auto;
				}
			}
		}

		&-container {
			padding: 0 80px 0 0;
		}

		&-image {
			padding: 0;

			img {
				padding: $padding-default;
			}
		}
	}
}

@media only screen and (min-width: $b4) {
	.productzoom {
		&-image {
			img {
				padding: 0;
			}
		}
	}
}
