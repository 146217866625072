.tweakwise-list {
	display: flex;

	> * {
		flex: 1 0 auto;
	}

	.list-item {
		.list-item-titlewrap {
			padding-bottom: 0px;
		}
	}
}

@media only screen and (min-width: $b7) {
	.tweakwise-list {
		> * {
			max-width: 12.5%;
		}
		> *:nth-child(n + 9) {
			display: none;
		}
	}
}

// @media only screen and (max-width: $b7 - 1) {
// 	.tweakwise-list {
// 		> * {

// 		}
// 		> *:nth-child(n + 8) {
// 			display: none;
// 		}
// 	}
// }

@media only screen and (max-width: $b7 - 1) {
	.tweakwise-list {
		> * {
			max-width: 14.2857142857%;
		}
		> *:nth-child(n + 8) {
			display: none;
		}
	}
}

@media only screen and (max-width: $b6 - 1) {
	.tweakwise-list {
		> * {
			max-width: 20%;
		}
		> *:nth-child(n + 6) {
			display: none;
		}
	}
}

@media only screen and (max-width: $b5 - 1) {
	.tweakwise-list {
		> * {
			max-width: 25%;
		}
		> *:nth-child(n + 5) {
			display: none;
		}
	}
}
@media only screen and (max-width: $b3 - 1) {
	.tweakwise-list {
		flex-wrap: nowrap;
		//override .tiles-view .list { padding-left: 18px; }
		padding-left: 0px !important;

		> * {
			max-width: none;
		}
		> *:nth-child(n + 5) {
			display: inline-flex;
		}
	}
}
@media only screen and (max-width: $b3 - 1) {
	.tweakwise-list {
		.list-item {
			.list-item-titlewrap {
				> .title {
					//override @include multiLineEllipsis(4.8rem, 2);
					@include multiLineEllipsis(2.4rem, 1);
				}
			}
		}
	}
}