.list.directorders {

	.do-item {
		[class^='icon-'] {
			font-size: 32px;
		}

		small {
			display: block;
		}

		&.open {
			background-color: $color01;

			> div {
				min-height: 5.9rem;
			}

			+ .do-item-wrap {
				cursor: default;
				border-bottom: 1px solid $color04;
			}

		}

		&-customerinfo {
			span {
				display: block;
			}
		}

		&-detail {

			.head {
				margin-bottom: $margin-default/2;
			}

			.btn {
				@include ellipsis();
				max-width: 100%;
				margin-bottom: 4px;
			}
		}

		&-actions {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
    		justify-content: flex-start;
			margin-bottom: $margin-default*2;

			.btn {
				margin-right: $margin-default;
			}
		}

		&-customer {
			padding-top: $padding-default*2;
		}

		&-list-detail {
			border-bottom: 0;

			img {
				max-height: 6.2rem;
				margin-bottom: 0;
			}
		}

		&-list-total {
			min-height: 5.9rem;
			border: 1px solid $color04;
		}
	}
}

@media only screen and ( min-width: $b4) {

	.list.directorders {

		.do-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;

			> * {
				align-items: center;
			}

			.btn {
				margin-bottom: 0;
			}

		}
	}
}

@media only screen and ( min-width: $b5) {
	.list.directorders {

		.do-item {
			&-actions {
				flex-wrap: nowrap;

				.btn {

					&.last {
						margin-left: auto;
						margin-right: 0;
					}
				}
			}
		}
	}
}


@media only screen and ( min-width: $b6) {

	.list.directorders {

		.do-item {
			&.open {
				> div {
					min-height: 6.2rem;
				}
			}
		}
	}
}