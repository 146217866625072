.fullpage {
	&-bg {
		&-img {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			overflow: hidden;
			z-index: -1;

			@media only screen and (min-width: $b3) {
				position: relative;
			}

			img {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				min-width: 100%;
				max-width: none;
				min-height: 100%;
			}
		}
	}
}
