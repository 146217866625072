.widget {
	margin-bottom: $margin-default * 2;
	border: 1px solid $color04;
	border-radius: $radius;

	&-head {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 6rem;
		padding: 0 1.2rem;
		background: $color05;
		border-bottom: 1px solid $color04;
		line-height: 0.7;

		> * {
			flex: 0 0 auto;
		}

		.head {
			@include ellipsis();
			flex: 1;
			padding-right: $padding-default;
			> * {
				color: $color03;
			}
		}

		&-all {
			margin-left: auto;
			min-height: auto;
			height: 3rem;
			font-size: 1.2rem;
			letter-spacing: 1px;
			line-height: 1;
		}
	}

	&-item {
		cursor: pointer;
	}
}
