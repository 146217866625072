/*========== HEAUTOCOMPLETE ==========*/
.autocomplete {
	display: none;

	&.open {
		display: block;
	}

	&-layer {
		content: "";
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.3);
		height: 100vw;
		width: 100vw;
		z-index: 0;

		.nav-open & {
			background: transparent;
		}
	}

	&-results {
		position: absolute;
		z-index: 5;
		width: 100%;
		padding: 0 0 0.6rem 0;
		background: $color01;
		border: 1px solid $color04;
		border-top: none;
		border-bottom-left-radius: $radius;
		border-bottom-right-radius: $radius;
		box-shadow: 8px 9px 15px -7px rgba(0, 0, 0, 0.4);

		.autocomplete-header {
			padding: $padding-default;
			font-size: $font-size-default;

			.head {
				margin-bottom: 0;
				font-size: $font-size-default;
				text-transform: uppercase;
			}
		}
		.autocomplete-separator {
			padding: $padding-default;
			padding-bottom: 0;
			font-size: $font-size-default;

			small {
				text-transform: uppercase;
			}
		}
		.autocomplete-footer {
			padding: $padding-default;
			font-size: $font-size-default;
		}
	}

	&-suggestions {
		@include ulreset();

		&-item {
			a {
				display: block;
				padding: $padding-default;
				color: $color03;
				text-decoration: none;
				line-height: 1.2;
				cursor: pointer;
			}

			&.active,
			&:hover {
				a,
				span {
					background-color: $color04;
				}
			}

			&-product {

				&-name,
				&-sku {
					margin-left: 1.2rem;
				}

				&-details {
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					> * {
						flex: 1 0 100%;
					}
				}

				.list-item {
					border: none;
					padding: 0.1rem;
					line-height: 1.2;
				}

				.img-wrap {
					padding-left: $padding-default;

					img {
						height: auto;
					}
				}

				&.active {
					background-color: $color04;
				}
			}
		}
	}
}

@media only screen and (max-width: $b4) {
	.autocomplete-suggestions-item-product {
		img {
			height: 50px;
			margin-bottom: 0px;
		}
	}
}