.parent-item {
	padding: $padding-default/2;

	&-img {
		.product-img {
			&.nozoom {
				cursor: default;
			}

			.zoom {
				bottom: -10px;
				right: -10px;
			}
		}
	}
}

@media only screen and (min-width: $b5) {
	.parent-item {
		height: 17.5rem;
		border: 1px solid $color04;

		img {
			max-width: 16.3rem;
			width: 100%;
		}

		&-img {
			.product-img {
				.zoom {
					bottom: 5px;
					right: 5px;
				}
			}
		}
	}
}
