.bundle-item {
	position: relative;

	.input-checkbox,
	.input-radio {
		position: absolute;
		top: 0;
		left: 0;
	}

	&:hover {
		background-color: $color01;
	}
}

@media only screen and (min-width: $b4) {
	.bundle-item {
		.img-wrap {
			img {
				float: right;
			}
		}

		.input-checkbox,
		.input-radio {
			position: absolute;
			top: 16px;
			left: 6px;
		}
	}
}

@media only screen and (min-width: $b5) {
	.bundle-item {
		.input-checkbox,
		.input-radio {
			left: 18px;
		}
	}
}
