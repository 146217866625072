.list-empty {
	&-item {
		> * {
			margin-bottom: $margin-default;
		}
	}

	&-header {
		border-bottom: 1px solid $color04;
		padding: $padding-default * 2;
	}

	.form {
		position: relative;
	}
}

@media only screen and (min-width: $b4) {
	.list-empty {
		padding: 0 $padding-default * 2;

		&-item {
			display: flex;
			flex-direction: column;

			> * {
				margin-bottom: $margin-default * 2;
				flex: 1 0 auto;
			}
		}
	}
}
