.header-basket-notifications {
	position: absolute;
	top: $header-height;
	right: 0;
	display: none;
	flex-direction: column;
	justify-content: center;
	margin-top: 0;
	padding: $padding-default;
	width: 31rem;
	font-weight: $font-weight-extrabold;
	line-height: 1.2;
	color: $color01;
	background: $positive-text-color;
	text-transform: none;
	z-index: 100;

	[class^='icon-'] {
		margin: -0 0 0 6px;
		font-size: 3.7rem;
	}

	&:before {
		content: '';
		position: absolute;
		top: -10px;
		right: 12px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 10px 8px;
		border-color: transparent transparent $positive-text-color transparent;
	}

	&.positive {
		background: $positive-text-color;

		&:before {
			border-color: transparent transparent $positive-text-color transparent;
		}
	}

	&.warning {
		background: $warning-text-color;

		&:before {
			border-color: transparent transparent $warning-text-color transparent;
		}
	}

	&.fade-in-out {
		opacity: 0;
		animation-name: fadeInOutOpacity;
		animation-iteration-count: 1;
		animation-timing-function: ease-in-out;
		animation-duration: 5s;
		pointer-events: none;
	}

	@keyframes fadeInOutOpacity {
		0% {
			opacity: 0;
		}
		10%,
		90% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}

@media only screen and (min-width: $b4) {
	.header-basket-notifications {
		margin-top: 0;
		width: 37rem;
		line-height: 1;

		&:before {
			right: 37px;
		}
	}
}

@media only screen and (min-width: $b5) {
	.header-basket-notifications {
		width: calc(100% - #{$header-icon-width});
		background: transparent;
	}
}
