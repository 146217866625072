.related-title {
	padding-top: 1.4rem;
	padding-right: 1.4rem;
	padding-bottom: 1.4rem;
}

.related-list {
	//override .tiles-view .list { padding-left: 18px; margin-left: -18px; }
	padding-left: 0px !important;
	margin-left: 0px !important;

	> * {
		width: 180px;
	}

	.list-item {
		.list-item-titlewrap {
			padding-bottom: 0px;
		}
	}

	.add-to-basket-block {
		height: 3.5rem;
	}

	.btn-icon {
		height: 3rem;
		min-height: 3rem;
		padding: 0.4rem 0rem;
	}
}

@media only screen and (min-width: $b7) {
	.related-list {
		> * {
			//width: 20%;
		}
		> *:nth-child(n + 9) {
			display: none;
		}
	}
}

@media only screen and (max-width: $b7 - 1) {
	.related-list {
		> * {
			//width: 20%;
		}
		> *:nth-child(n + 8) {
			display: none;
		}
	}
}

@media only screen and (max-width: $b6 - 1) {
	.related-list {
		> * {
			//width: 225px;
		}
		> *:nth-child(n + 6) {
			display: none;
		}
	}
}

@media only screen and (max-width: $b5 - 1) {
	.related-list {
		> * {
			//width: 20%;
		}
		> *:nth-child(n + 5) {
			display: none;
		}
	}
}
@media only screen and (max-width: $b3 - 1) {
	.related-list {
		flex-wrap: nowrap;

		> *:nth-child(n + 5) {
			display: inline-flex;
		}
	}
}
@media only screen and (max-width: $b3 - 1) {
	.related-list {
		.list-item {
			.list-item-titlewrap {
				> .title {
					//override @include multiLineEllipsis(4.8rem, 2);
					@include multiLineEllipsis(2.4rem, 1);
				}
			}
		}
	}
}
