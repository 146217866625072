/*========== paging ==========*/
.paging {
	padding-top: $padding-default;
	margin-bottom: $margin-default;

	ul {
		text-align: center;
	}

	li {
		display: inline-block;
		margin: 0 0 0.3rem;

		&.current,
		span {
			cursor: default;
			pointer-events: none;
		}

		span {
			padding: 0.8rem 1.6rem;
			text-decoration: none;
			transition: all 0.2s ease-out;
		}

		&.current {
			span {
				color: $color01;
				background: $theme01;
			}
		}

		&.link-disabled {
			span {
				color: $color06;
			}
		}

		a,
		span {
			display: block;
			text-decoration: none;
			background: $color01;
			font-weight: bold;
			color: $color02;
			border-radius: $radius;
		}

		a {
			&:hover {
				span {
					background-color: $theme03;
					color: $color01;
				}
			}
		}
	}

	// reset default link styling for paging
	.prev,
	.next {
		margin: 0;

		a {
			&:before,
			&:after {
				display: none;
			}
		}
	}
}
