.basket-totals {
	padding-top: $padding-default/2;
	&-border {
		border-top: 1px solid $color04;
	}
	&-row {
		padding: $padding-default $padding-default * 2;

		.price {
			font-weight: $font-weight-normal;
		}

		&.explain-row {
			padding-top: 0;
			margin-top: -8px;
			font-style: italic;
			font-size: 1.3rem;
		}
	}

	&-total {
		margin-top: 0.6rem;
		padding: $padding-default * 2;
		border-top: 1px solid $color04;
		font-size: 2rem;

		+ .basket-totals-row {
			padding-top: 0;
		}

		.price {
			font-weight: $font-weight-extrabold;
		}

		.explain-row {
			font-size: 1.3rem;
			text-align: right;
		}
	}

	&-actions {
		font-size: 0;
		padding: $padding-default * 2 $padding-default * 2 0 $padding-default * 2;
		background: $color05;

		.head {
			margin-bottom: $margin-default;
		}

		.input-control {
			margin-bottom: $margin-default/2;
		}

		+ .btn-wrap {
			padding: $padding-default $padding-default * 2 $padding-default * 2;
			background: $color05;
		}
	}

	&-reference {
		padding-top: $padding-default;

		a {
			position: relative;
			padding-left: 3.2rem;
			text-decoration: none;
		}

		[class^='icon-'] {
			position: absolute;
			top: 1px;
			left: 0;
			font-size: 1.5rem;
			line-height: 1;
			color: $color08;
		}

		&.has-reference {
			[class^='icon-'] {
				color: $theme02;
			}
		}
	}
}
